import * as FileSaver from 'file-saver';
import { useState } from 'react';
import XLSX from 'sheetjs-style';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-use-before-define
const ExportExcel = ({
	excelData,
	created,
	qty,
	final,
	company,
	exportType,
	contract,
	orderArea,
	phone,
	address,
	currency,
	owner
}) => {
	const { t } = useTranslation();
	const fileType = 'application/vnd.openxmIformats-officedocument .spreadsheetml. sheet; charset-UTF-8';
	const fileExtension = '.xlsx';
	const borderStyle = { border: { bottom: { style: 'thick', color: '000000' } } };
	const fileName = contract + '-' + created.toLocaleString().split('T')[0];
	const saveType = exportType === 'Excel' ? 'array' : 'buffer';
	excelData = excelData.map((item) => delete item.orderid && item); // eslint-disable-line
	const [selectVal, setSelectVal] = useState('');
	const exportList = owner ? ['Order'] : ['Order', 'Warehouse', 'Packing list', 'Proforma invoice', 'Invoice'];
	const boldTitle = { name: 'Times New Roman', sz: 12, bold: true };
	const boldTile14 = { name: 'Times New Roman', sz: 14, bold: true };
	const fullSideBorder = {
		right: {
			style: 'thin',
			color: '000000'
		},
		left: {
			style: 'thin',
			color: '000000'
		},
		top: {
			style: 'thin',
			color: '000000'
		},
		bottom: {
			style: 'thin',
			color: '000000'
		}
	};
	const dottedBorder = {
		right: {
			style: 'dotted',
			color: '000000'
		},
		left: {
			style: 'dotted',
			color: '000000'
		},
		top: {
			style: 'dotted',
			color: '000000'
		},
		bottom: {
			style: 'dotted',
			color: '000000'
		}
	};
	const decimalCell = '#,##0.00_);\\(#,##0.00\\)';
	const alignCenter = { vertical: 'center', horizontal: 'center' };
	const alignCenterWrap = { vertical: 'center', horizontal: 'center', wrapText: '1' };
	const MaterialCol = company === 'ROSIN INTERNATIONAL ENTERPRISES LTD.';
	const formHeader = (ws, colFrom, colEnd, firstRow) => {
		for (let i = colFrom; i <= colEnd; i++) {
			const cell = String.fromCharCode(i) + firstRow;
			if (!ws[cell]) {
				ws[cell] = { v: '' };
			}
			ws[cell].s = {
				border: fullSideBorder,
				font: boldTitle,
				alignment: alignCenterWrap
			};
		}
	};

	const formBorder = (ws, dataLength, colFrom, colEnd, rowFrom) => {
		for (let i = colFrom; i <= colEnd; i++) {
			for (let row = rowFrom; row < rowFrom + dataLength; row++) {
				const cell = String.fromCharCode(i) + row.toString();
				if (!ws[cell] || ws[cell].v === null || ws[cell].v === '') {
					ws[cell] = { v: '' };
				}
				ws[cell].s = {
					border: fullSideBorder,
					align: alignCenter
				};
			}
		}
	};

	const companyInfo = (ws, sheet) => {
		const titleLength = sheet === 'proformaInvoice' ? 8 : 10;
		XLSX.utils.sheet_add_aoa(ws, [['XINRONG TRADING Co., LTD']], { origin: 'A1' });
		ws['!merges'] = [{ s: { c: 0, r: 0 }, e: { c: titleLength, r: 0 } }];
		ws['A1'].s = {
			alignment: alignCenter,
			font: { name: 'Times New Roman', sz: 22, bold: true }
		};
		XLSX.utils.sheet_add_aoa(
			ws,
			[['2F., No. 9, Ln. 97, Dachun St., Renwu Dist., Kaohsiung City 814027 , Taiwan (R.O.C.) \n FAX：+886+7+3451213']],
			{ origin: 'A2' }
		);
		ws['!merges'].push({ s: { c: 0, r: 1 }, e: { c: titleLength, r: 2 } });
		ws['A2'].s = {
			alignment: alignCenterWrap,
			font: boldTitle
		};
	};

	const content = (ws, sheet) => {
		XLSX.utils.sheet_add_aoa(ws, [[sheet === 'packingList' ? 'PACKING LIST' : 'INVOICE']], { origin: 'A4' });
		ws['!merges'].push({ s: { c: 0, r: 3 }, e: { c: 10, r: 3 } });
		ws['A4'].s = {
			alignment: alignCenter,
			font: { name: 'Times New Roman', sz: 26, bold: true, underline: true }
		};
		XLSX.utils.sheet_add_aoa(ws, [['No.']], { origin: 'B6' });
		ws['B6'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [['To MESSRS']], { origin: 'B7' });
		ws['B7'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [['Consignee & Notify party']], { origin: 'B10' });
		ws['!merges'].push({ s: { c: 1, r: 9 }, e: { c: 2, r: 11 } });
		ws['B10'].s = {
			alignment: { horizontal: 'left', wrapText: '1', vertical: 'top' },
			font: boldTitle
		};
		XLSX.utils.sheet_add_aoa(ws, [['Per S. S.']], { origin: 'B13' });
		ws['!merges'].push({ s: { c: 1, r: 12 }, e: { c: 2, r: 12 } });
		ws['B13'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [['Sailing on or about']], { origin: 'B14' });
		ws['!merges'].push({ s: { c: 1, r: 13 }, e: { c: 2, r: 13 } });
		ws['B14'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [['From']], { origin: 'B15' });
		ws['B15'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [['Term']], { origin: 'B16' });
		ws['B16'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [[company]], { origin: 'D7' });
		ws['D7'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [[address]], { origin: 'D8' });
		XLSX.utils.sheet_add_aoa(ws, [[`TEL: ${phone}`]], { origin: 'D9' });
		XLSX.utils.sheet_add_aoa(ws, [[company]], { origin: 'D10' });
		ws['D10'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [[address]], { origin: 'D11' });
		XLSX.utils.sheet_add_aoa(ws, [[`TEL: ${phone}`]], { origin: 'D12' });
		XLSX.utils.sheet_add_aoa(ws, [['KAOHSIUNG, TAIWAN']], { origin: 'D15' });
		XLSX.utils.sheet_add_aoa(ws, [['To']], { origin: 'E15' });
		ws['E15'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [['Date']], { origin: 'H6' });
		ws['H6'].s = { font: boldTitle };
		XLSX.utils.sheet_add_aoa(ws, [['Marks & Nos.']], { origin: 'H8' });
		if (sheet === 'packingList') {
			ws['!merges'].push({ s: { c: 7, r: 7 }, e: { c: 10, r: 7 } });
		} else {
			ws['!merges'].push({ s: { c: 7, r: 7 }, e: { c: 8, r: 7 } });
		}
		XLSX.utils.sheet_add_aoa(ws, [[`${company} \n PALLET NO. \n MADE IN TAIWAN`]], {
			origin: 'H9'
		});
		if (sheet === 'packingList') {
			ws['!merges'].push({ s: { c: 7, r: 8 }, e: { c: 10, r: 15 } });
		} else {
			ws['!merges'].push({ s: { c: 7, r: 8 }, e: { c: 8, r: 15 } });
		}
		const notesLength = sheet === 'packingList' ? 75 : 73;
		for (let i = 72; i <= notesLength; i++) {
			for (let row = 8; row <= 16; row++) {
				const cell = String.fromCharCode(i) + row.toString();
				if (!ws[cell]) {
					ws[cell] = { v: '' };
				}
				ws[cell].s = {
					border: dottedBorder
				};
			}
		}
		ws['H8'].s = {
			alignment: alignCenter,
			font: boldTitle,
			border: dottedBorder
		};
		ws['H9'].s = {
			alignment: alignCenterWrap,
			font: boldTitle,
			border: dottedBorder
		};
	};

	const workBook = (ws, sheet) => {
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
		const data = new Blob([excelBuffer], { type: fileType });
		if (saveType === 'array') {
			FileSaver.saveAs(data, sheet + fileName + fileExtension);
		}
		return excelBuffer;
	};

	const getSelect = (e) => {
		const type = e.target.innerHTML;
		setSelectVal(type);
		if (type === 'Order') {
			const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A4' });
			XLSX.utils.sheet_add_aoa(ws, [['MACU TEA Purchase Order List']], { origin: 'A1' });
			XLSX.utils.sheet_add_aoa(ws, [['Purchase Date', created.toLocaleString().split('T')[0]]], { origin: 'A2' });
			XLSX.utils.sheet_add_aoa(ws, [['Total', '', qty, Math.round(final * 100) / 100]], {
				origin: 'C' + parseInt(excelData.length + 5)
			});
			XLSX.utils.sheet_add_aoa(ws, [['70% Deposit', Math.round(final * 0.7 * 100) / 100]], {
				origin: 'E' + parseInt(excelData.length + 6)
			});
			XLSX.utils.sheet_add_aoa(ws, [['30% Final Payment', Math.round(final * 0.3 * 100) / 100]], {
				origin: 'E' + parseInt(excelData.length + 7)
			});
			XLSX.utils.sheet_add_aoa(ws, [['XINRONG TRADING Co., LTD', '', '', company]], {
				origin: 'B' + parseInt(excelData.length + 9)
			});
			XLSX.utils.sheet_add_aoa(ws, [['', '', '', '', '', '']], { origin: 'B' + parseInt(excelData.length + 10) });
			ws['B2'].s = {
				border: {
					right: { style: 'thick', color: '000000' },
					left: { style: 'thick', color: '000000' },
					top: { style: 'thick', color: '000000' },
					bottom: { style: 'thick', color: '000000' }
				}
			};
			for (let i = 5; i < 8; i++) {
				ws['F' + parseInt(excelData.length + i)].s = borderStyle;
				ws['F' + parseInt(excelData.length + i)].z = decimalCell;
			}
			let rows = ['B', 'E', 'F', 'G'];
			rows.map((r) => (ws[r + parseInt(excelData.length + 10)].s = borderStyle));
			ws['B' + parseInt(excelData.length + 10)].hpx = 1000;
			ws['B' + parseInt(excelData.length + 10)].hpt = 1000;
			ws['!cols'] = [
				{ width: 27 },
				{ width: 50 },
				{ width: 20 },
				{ width: 10 },
				{ width: 15 },
				{ width: 10 },
				{ width: 30 },
				{ width: 20 },
				{ width: 18 },
				{ width: 16 },
				{ width: 10 },
				{ width: 10 },
				{ width: 10 },
				{ width: 10 },
				{ width: 10 }
			];
			ws['P'] = { alignment: { wrapText: true } };
			rows = ['D', 'F', 'K', 'L', 'M', 'N', 'O'];
			rows.map((r) => {
				for (let i = 5; i <= excelData.length + 4; i++) {
					ws[r + i].z = decimalCell;
				}
			});

			workBook(ws, '');
		} else if (type === 'Warehouse') {
			let newData = excelData.map((i) =>
				Object.fromEntries(
					['ProductName', 'Code', 'OrderingQty', 'Unit', 'ProductZH', 'PackagingSize'].map((f) => [f, i[f]])
				)
			);
			const dataLength = newData.length;
			const lastRow = dataLength + 7;
			const ws = XLSX.utils.json_to_sheet(newData, { origin: 'B6' });
			XLSX.utils.sheet_add_aoa(ws, [['廠房訂單']], { origin: 'B1' });
			XLSX.utils.sheet_add_aoa(ws, [['下單日期', created.toLocaleString().split('T')[0].replaceAll('-', '/')]], {
				origin: 'B2'
			});
			XLSX.utils.sheet_add_aoa(ws, [['海外門市', contract + '-' + orderArea]], { origin: 'B3' });
			XLSX.utils.sheet_add_aoa(ws, [['結關日']], { origin: 'B4' });
			XLSX.utils.sheet_add_aoa(ws, [['NO.']], { origin: 'A6' });
			XLSX.utils.sheet_add_aoa(ws, [['Code']], { origin: 'C6' });
			XLSX.utils.sheet_add_aoa(ws, [['Qty']], { origin: 'D6' });
			XLSX.utils.sheet_add_aoa(ws, [['CBM']], { origin: 'H6' });
			XLSX.utils.sheet_add_aoa(ws, [['貨物到廠時間']], { origin: 'I6' });
			XLSX.utils.sheet_add_aoa(ws, [['製造日期']], { origin: 'J6' });
			XLSX.utils.sheet_add_aoa(ws, [['有效日期']], { origin: 'K6' });
			XLSX.utils.sheet_add_aoa(ws, [['板號']], { origin: 'L6' });
			XLSX.utils.sheet_add_aoa(ws, [['箱號']], { origin: 'M6' });
			ws['!cols'] = [
				{ width: 7 },
				{ width: 50 },
				{ width: 10 },
				{ width: 10 },
				{ width: 10 },
				{ width: 50 },
				{ width: 30 },
				{ width: 15 },
				{ width: 15 },
				{ width: 30 },
				{ width: 30 },
				{ width: 20 },
				{ width: 30 }
			];
			if (!ws['!rows']) ws['!rows'] = [];
			ws['B1'].s = { font: { bold: true, sz: 15 } };
			formHeader(ws, 65, 77, '6');
			let index = 0;
			for (let i = 7; i < lastRow; i++) {
				index += 1;
				if (!ws[`A${i}`]) {
					ws[`A${i}`] = {};
				}
				ws[`A${i}`].v = index;
			} // no. column

			for (let i = 0; i < 4; i++) {
				ws['!rows'][i] = { hpx: 20 };
			} // height for title

			formBorder(ws, dataLength, 65, 77, 7);

			for (let i = 6; i < lastRow; i++) {
				if (i < lastRow - 1) {
					ws['!rows'][i] = { hpx: 40 }; // height for each row
				}
				if (i > 6) {
					for (let r = 65; r < 73; r++) {
						const cell = String.fromCharCode(r) + i.toString();
						if (!ws[cell]) {
							ws[cell] = {};
						}
						ws[cell].s = {
							border: fullSideBorder,
							alignment: { vertical: 'center', horizontal: r === 65 || r === 68 ? 'center' : null, wrapText: '1' } //horizontally center for no. and qty
						};
					} //vertically center for all column
				}
			}
			workBook(ws, '廠房訂單');
		} else if (type === 'Packing list') {
			let newData = excelData.map((i) =>
				Object.fromEntries(
					[
						'ProductName',
						'PackagingSize',
						'OrderingQty',
						'Unit',
						'Nw',
						'TotalNetWeight',
						'Gw',
						'TotalGrossWeight',
						company === 'ROSIN INTERNATIONAL ENTERPRISES LTD.' ? 'Material' : ''
					].map((f) => [f, i[f]])
				)
			);
			const dataLength = newData.length;
			let totalQty = 0;
			let totalNw = 0;
			let totalGw = 0;
			newData.map((i) => {
				totalQty += i.OrderingQty;
				if (i.TotalNetWeight) {
					totalNw += parseFloat(i.TotalNetWeight.toFixed(2));
				}
				if (i.TotalGrossWeight) {
					totalGw += i.TotalGrossWeight;
				}
			});
			totalNw = totalNw.toFixed(2);
			totalGw = totalGw.toFixed(2);
			newData.splice(0, 0, {}); // inset blank row to row 19
			const ws = XLSX.utils.json_to_sheet(newData, { origin: 'D18' });
			ws['!cols'] = [
				{ width: 1 },
				{ width: 8 },
				{ width: 8 },
				{ width: 30 },
				{ width: 20 },
				{ width: 10 },
				{ width: 10 },
				{ width: 10 },
				{ width: 10 },
				{ width: 10 },
				{ width: 10 },
				{ width: 20 }
			];
			if (!ws['!rows']) ws['!rows'] = [];
			ws['!rows'][17] = { hpx: 40 }; //for table header
			companyInfo(ws);
			content(ws, 'packingList');
			XLSX.utils.sheet_add_aoa(ws, [['Packing No.']], { origin: 'B18' });
			ws['!merges'].push({ s: { c: 1, r: 17 }, e: { c: 2, r: 17 } });
			ws['B18'].s = {
				alignment: alignCenter,
				font: boldTitle,
				border: fullSideBorder
			};
			XLSX.utils.sheet_add_aoa(ws, [['P/ NO']], { origin: 'B19' });
			XLSX.utils.sheet_add_aoa(ws, [['C/ NO']], { origin: 'C19' });
			for (let i = 3; i < 7; i++) {
				ws['!merges'].push({ s: { c: i, r: 17 }, e: { c: i, r: 18 } });
			}
			ws['!merges'].push({ s: { c: 11, r: 17 }, e: { c: 11, r: 18 } }); //Material column
			XLSX.utils.sheet_add_aoa(ws, [['Qty']], { origin: 'F18' });
			let columnLength = MaterialCol ? 76 : 75;
			formBorder(ws, dataLength + 2, 66, columnLength, 18);
			for (let i = 72; i <= 75; i++) {
				for (let row = 20; row <= 18 + dataLength; row++) {
					const cell = String.fromCharCode(i) + row.toString();
					ws[cell].z = decimalCell;
				}
			} // change format for weight
			XLSX.utils.sheet_add_aoa(ws, [['Unit']], { origin: 'H19' });
			XLSX.utils.sheet_add_aoa(ws, [['TOTAL']], { origin: 'I19' });
			XLSX.utils.sheet_add_aoa(ws, [['Unit']], { origin: 'J19' });
			XLSX.utils.sheet_add_aoa(ws, [['TOTAL']], { origin: 'K19' });
			let cols = ['H19', 'I19', 'J19', 'K19'];
			cols.map((c) => {
				ws[c].s = {
					border: fullSideBorder,
					alignment: alignCenter
				};
			});
			ws['!merges'].push({ s: { c: 7, r: 17 }, e: { c: 8, r: 17 } });
			ws['!merges'].push({ s: { c: 9, r: 17 }, e: { c: 10, r: 17 } });
			XLSX.utils.sheet_add_aoa(ws, [['New Weight(KG)']], { origin: 'H18' });
			XLSX.utils.sheet_add_aoa(ws, [['Gross Weight(KG)']], { origin: 'J18' });
			cols = ['H18', 'J18', 'B19', 'C19'];
			if (MaterialCol) {
				cols.push('L18');
				for (let i = 20; i < 20 + dataLength; i++) {
					ws['L' + i].s = {
						alignment: { wrapText: '1' },
						border: fullSideBorder
					};
				}
			}
			cols.map((c) => {
				ws[c].s = {
					font: boldTitle,
					alignment: alignCenter,
					border: fullSideBorder
				};
			});
			formHeader(ws, 66, 71, '18');
			const lastRow = 20 + dataLength;
			XLSX.utils.sheet_add_aoa(ws, [['TOTAL']], { origin: `B${lastRow}` });
			XLSX.utils.sheet_add_aoa(ws, [[totalQty]], { origin: `F${lastRow}` });
			XLSX.utils.sheet_add_aoa(ws, [[totalNw]], { origin: `I${lastRow}` });
			XLSX.utils.sheet_add_aoa(ws, [[totalGw]], { origin: `K${lastRow}` });
			cols = ['I', 'K'];
			cols.map((c) => {
				ws[`${c}${lastRow}`].z = decimalCell;
				ws[`${c}${lastRow}`].t = 'n';
			});
			cols = ['B', 'F', 'I', 'K'];
			cols.map((c) => {
				ws[`${c}${lastRow}`].s = {
					font: boldTitle,
					alignment: { vertical: 'bottom' }
				};
			});
			XLSX.utils.sheet_add_aoa(ws, [['TOTAL']], { origin: `D${lastRow + 3}` });
			XLSX.utils.sheet_add_aoa(ws, [['Carton(Pallets)']], { origin: `F${lastRow + 3}` });
			XLSX.utils.sheet_add_aoa(ws, [['KGS']], { origin: `K${lastRow + 3}` });
			ws['!merges'].push({ s: { c: 5, r: lastRow + 2 }, e: { c: 6, r: lastRow + 2 } }); //merge carton(pallets)
			XLSX.utils.sheet_add_aoa(ws, [['XINRONG TRADING Co., LTD']], { origin: `H${lastRow + 7}` });
			ws[`H${lastRow + 7}`].s = {
				font: { name: 'Times New Roman', sz: 14, bold: true, italic: true }
			};
			ws['!rows'][lastRow + 7] = {
				hpx: 80
			};
			const cell_center = ['E15', `F${lastRow}`];
			cell_center.map(
				(i) =>
					(ws[i].s = {
						font: boldTitle,
						alignment: alignCenter
					})
			);
			for (let i = 19; i < lastRow; i++) {
				if (i < lastRow - 1) {
					ws['!rows'][i] = { hpx: 40 }; // height for each row
				}
				if (i > 6) {
					for (let r = 68; r < 76; r++) {
						const cell = String.fromCharCode(r) + i.toString();
						if (!ws[cell]) {
							ws[cell] = {};
						}
						ws[cell].s = {
							border: fullSideBorder,
							alignment: { vertical: 'center', horizontal: r === 70 ? 'center' : null, wrapText: '1' } //horizontally center for no. and qty
						};
					} //vertically center for all column
				}
			}

			ws[`D${lastRow + 3}`].s = {
				font: boldTitle
			};
			for (let i = 69; i <= 75; i++) {
				const cell = String.fromCharCode(i) + `${lastRow + 3}`;
				if (!ws[cell]) {
					ws[cell] = { v: '' };
				}
				ws[cell].s = {
					border: {
						bottom: {
							style: 'double',
							color: '000000'
						}
					},
					font: boldTitle,
					alignment: { vertical: 'bottom' }
				};
			} // for total carton(pallets) kgs
			cols = ['H', 'I', 'J', 'K'];
			cols.map((c) => {
				XLSX.utils.sheet_add_aoa(ws, [['']], { origin: `${c}${lastRow + 8}` });
				const cell = c + (lastRow + 8);
				ws[cell].s = {
					border: {
						bottom: {
							style: 'thin',
							color: '000000'
						}
					}
				};
			});
			const empty_cell = ['D6', 'I6', 'J6', 'K6', 'D13', 'D14', 'F15', 'G15', 'D16'];
			empty_cell.map((i) => XLSX.utils.sheet_add_aoa(ws, [['']], { origin: i }));
			const dotted_cell = ['D6', 'I6', 'J6', 'K6', 'D9', 'D12', 'D13', 'D14', 'D15', 'F15', 'G15', 'D16'];
			dotted_cell.map(
				(i) =>
					(ws[i].s = {
						border: {
							bottom: {
								style: 'dotted',
								color: '000000'
							}
						}
					})
			);
			workBook(ws, 'PL for ');
		} else if (type === 'Invoice') {
			let newData = excelData.map((i) =>
				Object.fromEntries(
					[
						'ProductName',
						'',
						'PackagingSize',
						'OrderingQty',
						'Unit',
						'UnitPrice',
						'Amount',
						company === 'ROSIN INTERNATIONAL ENTERPRISES LTD.' ? 'HS_Code' : ''
					].map((f) => [f, i[f]])
				)
			);
			const dataLength = newData.length;
			let totalQty = 0;
			let totalAmount = 0;
			newData.map((i) => {
				totalQty += i.OrderingQty;
				if (i.Amount) {
					totalAmount += parseFloat(i.Amount.toFixed(2));
				}
			});
			totalAmount = totalAmount.toFixed(2);
			const ws = XLSX.utils.json_to_sheet(newData, { origin: 'C18' });
			if (!ws['!rows']) ws['!rows'] = [];
			ws['!rows'][17] = { hpx: 40 };
			ws['!cols'] = [
				{ width: 1 },
				{ width: 7 },
				{ width: 15 },
				{ width: 30 },
				{ width: 20 },
				{ width: 8 },
				{ width: 10 },
				{ width: 12 },
				{ width: 15 },
				{ width: 20 }
			];
			companyInfo(ws);
			content(ws, 'invoice');
			let index = 0;
			for (let i = 17; i <= 17 + dataLength; i++) {
				const colB = 'B' + (i + 2);
				const colC = 'C' + (i + 2);
				const colD = 'D' + (i + 2);
				index += 1;
				ws['!merges'].push({ s: { c: 2, r: i }, e: { c: 3, r: i } });
				if (index <= dataLength) {
					XLSX.utils.sheet_add_aoa(ws, [[`${index.toString()}`]], { origin: colB });
					XLSX.utils.sheet_add_aoa(ws, [['']], { origin: colD });
					ws[colC].s = {
						border: fullSideBorder
					};
					ws[colD].s = {
						border: fullSideBorder
					};
				} //count items and merge product name
			}
			XLSX.utils.sheet_add_aoa(ws, [['No.']], { origin: 'B18' });
			XLSX.utils.sheet_add_aoa(ws, [['Product Name']], { origin: 'C18' });
			XLSX.utils.sheet_add_aoa(ws, [['Size']], { origin: 'E18' });
			XLSX.utils.sheet_add_aoa(ws, [['QTY']], { origin: 'F18' });
			XLSX.utils.sheet_add_aoa(ws, [[`Unit Price(${currency})`]], { origin: 'H18' });
			XLSX.utils.sheet_add_aoa(ws, [[`Amount(${currency})`]], { origin: 'I18' });
			formHeader(ws, 66, 74, '18');
			for (let i = 72; i <= 73; i++) {
				for (let row = 19; row <= 18 + dataLength; row++) {
					const cell = String.fromCharCode(i) + row.toString();
					ws[cell].z = decimalCell;
				}
			} // change format for amount
			let columnLength = MaterialCol ? 74 : 73;
			formBorder(ws, dataLength, 66, columnLength, 19);
			const lastRow = 19 + dataLength;
			XLSX.utils.sheet_add_aoa(ws, [['TOTAL']], { origin: `E${lastRow}` });
			XLSX.utils.sheet_add_aoa(ws, [[totalQty]], { origin: `F${lastRow}` });
			XLSX.utils.sheet_add_aoa(ws, [[`${currency}$`]], { origin: `H${lastRow}` });
			XLSX.utils.sheet_add_aoa(ws, [[totalAmount]], { origin: `I${lastRow}` });
			ws[`I${lastRow}`].z = decimalCell;
			ws[`I${lastRow}`].t = 'n';
			let cols = ['E', 'F', 'H', 'I'];
			cols.map((c) => {
				if (c === 'H' || c === 'I') {
					ws[`${c}${lastRow}`].s = {
						font: boldTile14,
						border: {
							bottom: { style: 'double', color: '000000' }
						}
					};
				} else {
					ws[`${c}${lastRow}`].s = {
						font: boldTile14
					};
				}
			});
			ws[`F${lastRow}`].s = {
				font: boldTile14,
				alignment: alignCenter
			};
			XLSX.utils.sheet_add_aoa(ws, [['XINRONG TRADING Co., LTD']], { origin: `G${lastRow + 2}` });
			ws[`G${lastRow + 2}`].s = {
				font: { name: 'Times New Roman', sz: 14, bold: true, italic: true }
			};
			ws['!rows'][lastRow + 2] = {
				hpx: 80
			};
			for (let i = 18; i < lastRow; i++) {
				if (i < lastRow - 1) {
					ws['!rows'][i] = { hpx: 40 }; // height for each row
				}
				if (i > 18) {
					for (let r = 66; r < 74; r++) {
						const cell = String.fromCharCode(r) + i.toString();
						if (!ws[cell]) {
							ws[cell] = {};
						}
						ws[cell].s = {
							border: fullSideBorder,
							alignment: { vertical: 'center', horizontal: r === 70 || r === 66 ? 'center' : null, wrapText: '1' } //horizontally center for no. and qty
						};
					} //vertically center for all column
				}
			}
			cols = ['G', 'H', 'I'];
			cols.map((c) => {
				XLSX.utils.sheet_add_aoa(ws, [['']], { origin: `${c}${lastRow + 3}` });
				const cell = c + (lastRow + 3);
				ws[cell].s = {
					border: {
						bottom: {
							style: 'thin',
							color: '000000'
						}
					}
				};
			});
			const empty_cell = ['D6', 'I6', 'E9', 'E12', 'D13', 'D14', 'F15', 'G15', 'D16'];
			empty_cell.map((i) => XLSX.utils.sheet_add_aoa(ws, [['']], { origin: i }));
			const dotted_cell = ['D6', 'I6', 'D9', 'E9', 'D12', 'E12', 'D13', 'D14', 'D15', 'F15', 'G15', 'D16'];
			dotted_cell.map(
				(i) =>
					(ws[i].s = {
						border: {
							bottom: {
								style: 'dotted',
								color: '000000'
							}
						}
					})
			);
			workBook(ws, 'INV for ');
		} else if (type === 'Proforma invoice') {
			let newData = excelData.map((i) =>
				Object.fromEntries(
					['Code', 'ProductName', 'PackagingSize', 'OrderingQty', 'Unit', 'UnitPrice', 'Amount'].map((f) => [f, i[f]])
				)
			);
			let totalAmount = 0;
			newData.map((i) => {
				totalAmount += parseFloat(i.Amount.toFixed(2));
			});
			totalAmount = totalAmount.toFixed(2);
			const ws = XLSX.utils.json_to_sheet(newData, { origin: 'C13' });
			companyInfo(ws, 'proformaInvoice');
			const dataLength = newData.length;
			ws['!cols'] = [
				{ width: 1 },
				{ width: 7 },
				{ width: 15 },
				{ width: 30 },
				{ width: 20 },
				{ width: 8 },
				{ width: 10 },
				{ width: 12 },
				{ width: 15 }
			];
			if (!ws['!rows']) ws['!rows'] = [];
			ws['!rows'][3] = { hpx: 40 };
			ws['!rows'][12] = { hpx: 40 };
			XLSX.utils.sheet_add_aoa(ws, [['PROFORMA INVOICE']], { origin: 'A4' });
			ws['!merges'].push({ s: { c: 0, r: 3 }, e: { c: 8, r: 3 } });
			ws['A4'].s = {
				alignment: alignCenter,
				font: { name: 'Times New Roman', sz: 22, bold: true, underline: true }
			};
			XLSX.utils.sheet_add_aoa(ws, [['No.']], { origin: 'B6' });
			XLSX.utils.sheet_add_aoa(ws, [[company]], { origin: 'D7' });
			XLSX.utils.sheet_add_aoa(ws, [['Company Name']], { origin: 'B7' });
			XLSX.utils.sheet_add_aoa(ws, [[address]], { origin: 'D8' });
			XLSX.utils.sheet_add_aoa(ws, [[`TEL: ${phone}`]], { origin: 'D9' });
			XLSX.utils.sheet_add_aoa(ws, [['Form']], { origin: 'B10' });
			XLSX.utils.sheet_add_aoa(ws, [['KAOHSIUNG, TAIWAN']], { origin: 'D10' });
			XLSX.utils.sheet_add_aoa(ws, [['Term']], { origin: 'B11' });
			XLSX.utils.sheet_add_aoa(ws, [['FOB KAOHSIUNG']], { origin: 'D11' });
			XLSX.utils.sheet_add_aoa(ws, [['To']], { origin: 'E10' });
			let cell = ['B6', 'D7', 'B7', 'B10', 'B11'];
			cell.map(
				(c) =>
					(ws[c].s = {
						font: boldTitle
					})
			);
			ws['E10'].s = {
				font: boldTitle,
				alignment: { horizontal: 'right' }
			};
			XLSX.utils.sheet_add_aoa(ws, [['Date']], { origin: 'H6' });
			ws['H6'].s = {
				font: boldTitle,
				alignment: { horizontal: 'right' }
			};
			XLSX.utils.sheet_add_aoa(ws, [['No.']], { origin: 'B13' });
			XLSX.utils.sheet_add_aoa(ws, [['Code No']], { origin: 'C13' });
			XLSX.utils.sheet_add_aoa(ws, [['Size']], { origin: 'E13' });
			XLSX.utils.sheet_add_aoa(ws, [['QTY']], { origin: 'F13' });
			XLSX.utils.sheet_add_aoa(ws, [[`Unit Price(${currency})`]], { origin: 'H13' });
			XLSX.utils.sheet_add_aoa(ws, [[`Amount(${currency})`]], { origin: 'I13' });
			formHeader(ws, 66, 73, '13');
			let index = 0;
			for (let i = 14; i <= 14 + dataLength; i++) {
				const colB = 'B' + i;
				index += 1;
				if (index <= dataLength) {
					XLSX.utils.sheet_add_aoa(ws, [[`${index.toString()}`]], { origin: colB });
				} //count items
			}
			formBorder(ws, dataLength, 66, 73, 14);
			for (let i = 72; i <= 73; i++) {
				for (let row = 14; row <= 13 + dataLength; row++) {
					const cell = String.fromCharCode(i) + row.toString();
					ws[cell].z = decimalCell;
				}
			} // change format for amount
			const lastRow = 14 + dataLength;
			ws['!rows'][lastRow] = { hpx: 40 };
			ws['!rows'][lastRow + 1] = { hpx: 40 };
			ws['!rows'][lastRow + 2] = { hpx: 40 };
			XLSX.utils.sheet_add_aoa(ws, [['TOTAL']], { origin: `G${lastRow}` });
			XLSX.utils.sheet_add_aoa(ws, [['70% Deposit']], { origin: `G${lastRow + 1}` });
			XLSX.utils.sheet_add_aoa(ws, [['30% Payment']], { origin: `G${lastRow + 2}` });
			XLSX.utils.sheet_add_aoa(ws, [[totalAmount]], { origin: `I${lastRow}` });
			XLSX.utils.sheet_add_aoa(ws, [[(totalAmount * 0.7).toFixed(2)]], { origin: `I${lastRow + 1}` });
			XLSX.utils.sheet_add_aoa(ws, [[(totalAmount * 0.3).toFixed(2)]], { origin: `I${lastRow + 2}` });
			for (let i = 0; i < 3; i++) {
				ws[`G${lastRow + i}`].s = {
					font: { name: 'Times New Roman', sz: 16, bold: true }
				};
				ws[`I${lastRow + i}`].s = {
					font: { name: 'Times New Roman', sz: 16, bold: true }
				};
				ws[`I${lastRow + i}`].t = 'n';
				ws[`I${lastRow + i}`].z = decimalCell;
			}
			XLSX.utils.sheet_add_aoa(ws, [['XINRONG TRADING Co., LTD']], { origin: `B${lastRow + 5}` });
			XLSX.utils.sheet_add_aoa(ws, [[company]], { origin: `F${lastRow + 5}` });
			ws[`B${lastRow + 5}`].s = {
				font: { name: 'Times New Roman', sz: 14, bold: true, italic: true }
			};
			ws[`F${lastRow + 5}`].s = {
				font: { name: 'Times New Roman', sz: 14, bold: true, italic: true }
			};
			ws['!rows'][lastRow + 5] = {
				hpx: 80
			};
			for (let i = 13; i < lastRow; i++) {
				if (i < lastRow - 1) {
					ws['!rows'][i] = { hpx: 40 }; // height for each row
				}
				if (i > 13) {
					for (let r = 66; r < 74; r++) {
						const cell = String.fromCharCode(r) + i.toString();
						if (!ws[cell]) {
							ws[cell] = {};
						}
						ws[cell].s = {
							border: fullSideBorder,
							alignment: { vertical: 'center', horizontal: r === 70 || r === 66 ? 'center' : null, wrapText: '1' } //horizontally center for no. and qty
						};
					} //vertically center for all column
				}
			}
			let cols = ['B', 'C', 'D', 'F', 'G', 'H', 'I'];
			cols.map((c) => {
				XLSX.utils.sheet_add_aoa(ws, [['']], { origin: `${c}${lastRow + 6}` });
				const cell = c + (lastRow + 6);
				ws[cell].s = {
					border: {
						bottom: {
							style: 'thin',
							color: '000000'
						}
					}
				};
			});
			const empty_cell = ['D6', 'I6', 'F10', 'G10'];
			empty_cell.map((i) => XLSX.utils.sheet_add_aoa(ws, [['']], { origin: i }));
			const dotted_cell = ['D6', 'I6', 'D9', 'D10', 'D11', 'F10', 'G10'];
			dotted_cell.map(
				(i) =>
					(ws[i].s = {
						border: {
							bottom: {
								style: 'dotted',
								color: '000000'
							}
						}
					})
			);
			workBook(ws, 'Proforma Invoice for ');
		}
	};

	return (
		<>
			<FormControl variant="outlined" sx={{ pl: 1 }}>
				<Select
					labelId="demo-simple-select-helper-label"
					id="demo-simple-select-helper"
					value={selectVal}
					displayEmpty
					MenuProps={{
						slotProps: {
							paper: {
								elevation: 0,
								sx: {
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1
									},
									'&::before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 14,
										width: 10,
										height: 10,
										bgcolor: 'background.paper',
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0
									}
								}
							}
						},
						transformOrigin: { horizontal: 'right', vertical: 'top' },
						anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
					}}
					renderValue={(selected) => {
						if (selected.length === 0) {
							return t('dashboard.Export');
						}

						return selected;
					}}>
					{exportList.map((list) => (
						<MenuItem
							value={list}
							onClick={(e) => {
								getSelect(e);
							}}
							key={list}>
							{list}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
};

const exportToExcel = async (
	//for email attachment
	excelData,
	created,
	qty,
	final,
	company,
	fileType,
	fileExtension,
	borderStyle,
	fileName,
	saveType
) => {
	const decimalCell = '#,##0.00_);\\(#,##0.00\\)';
	const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A4' });
	XLSX.utils.sheet_add_aoa(ws, [['MACU TEA Purchase Order List']], { origin: 'A1' });
	XLSX.utils.sheet_add_aoa(ws, [['Purchase Date', created.toLocaleString().split('T')[0]]], { origin: 'A2' });
	XLSX.utils.sheet_add_aoa(ws, [['Total', '', qty, Math.round(final * 100) / 100]], {
		origin: 'C' + parseInt(excelData.length + 5)
	});
	XLSX.utils.sheet_add_aoa(ws, [['70% Deposit', Math.round(final * 0.7 * 100) / 100]], {
		origin: 'E' + parseInt(excelData.length + 6)
	});
	XLSX.utils.sheet_add_aoa(ws, [['30% Final Payment', Math.round(final * 0.3 * 100) / 100]], {
		origin: 'E' + parseInt(excelData.length + 7)
	});
	XLSX.utils.sheet_add_aoa(ws, [['XINRONG TRADING Co., LTD', '', '', company]], {
		origin: 'B' + parseInt(excelData.length + 9)
	});
	XLSX.utils.sheet_add_aoa(ws, [['', '', '', '', '', '']], { origin: 'B' + parseInt(excelData.length + 10) });
	ws['B2'].s = {
		border: {
			right: { style: 'thick', color: '000000' },
			left: { style: 'thick', color: '000000' },
			top: { style: 'thick', color: '000000' },
			bottom: { style: 'thick', color: '000000' }
		}
	};
	for (let i = 5; i < 8; i++) {
		ws['F' + parseInt(excelData.length + i)].s = borderStyle;
		ws['F' + parseInt(excelData.length + i)].z = decimalCell;
	}
	let rows = ['B', 'E', 'F', 'G'];
	rows.map((r) => (ws[r + parseInt(excelData.length + 10)].s = borderStyle));
	ws['B' + parseInt(excelData.length + 10)].hpx = 1000;
	ws['B' + parseInt(excelData.length + 10)].hpt = 1000;
	ws['!cols'] = [
		{ width: 27 },
		{ width: 50 },
		{ width: 20 },
		{ width: 10 },
		{ width: 15 },
		{ width: 10 },
		{ width: 30 },
		{ width: 20 },
		{ width: 18 },
		{ width: 16 },
		{ width: 10 },
		{ width: 10 },
		{ width: 10 },
		{ width: 10 },
		{ width: 10 }
	];
	ws['P'] = { alignment: { wrapText: true } };
	rows = ['D', 'F', 'K', 'L', 'M', 'N', 'O'];
	rows.map((r) => {
		for (let i = 5; i <= excelData.length + 4; i++) {
			ws[r + i].z = decimalCell;
		}
	});
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const excelBuffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
	const data = new Blob([excelBuffer], { type: fileType });
	if (saveType === 'array') {
		FileSaver.saveAs(data, fileName + fileExtension);
	}
	return excelBuffer;
};

const exportInventory = (inventoryData, area) => {
	['id', 'Img', 'Area', 'Availability', '_version', '_lastChangedAt', '_deleted', '__typename', 'updatedAt'].forEach(
		(key) => {
			inventoryData.map((i) => delete i[key]);
		}
	);
	inventoryData.sort(function (a, b) {
		if (a.Code > b.Code) {
			return 1;
		}
		if (a.Code < b.Code) {
			return -1;
		}
		return 0;
	});
	const ws = XLSX.utils.json_to_sheet(inventoryData, { origin: 'A1' });
	const fileType = 'application/vnd.openxmIformats-officedocument .spreadsheetml. sheet; charset-UTF-8';
	const fileExtension = '.xlsx';
	const fileName = area + '物料表';
	const saveType = 'array';
	const boldTitle = { bold: true };
	ws['!cols'] = [
		{ width: 50 },
		{ width: 20 },
		{ width: 15 },
		{ width: 30 },
		{ width: 15 },
		{ width: 10 },
		{ width: 10 },
		{ width: 10 },
		{ width: 20 },
		{ width: 15 },
		{ width: 20 },
		{ width: 10 },
		{ width: 10 },
		{ width: 10 },
		{ width: 10 },
		{ width: 10 },
		{ width: 15 },
		{ width: 25 }
	];
	for (let i = 65; i <= 82; i++) {
		const cell = String.fromCharCode(i) + '1';
		if (!ws[cell]) {
			ws[cell] = { v: '' };
		}
		ws[cell].s = { font: boldTitle };
	} // bold title

	for (let i = 2; i <= inventoryData.length + 1; i++) {
		let col = ['M', 'N', 'O', 'P', 'Q'];
		col.map((c) => {
			const cell = c + i;
			if (!ws[cell]) {
				ws[cell] = { v: '' };
			} // create value for empty cell after price column
			if (cell.startsWith('N')) {
				ws[cell].v = ws[cell].toString();
			} //convert array to string
			if (cell.startsWith('M')) {
				ws[cell].v = JSON.parse(ws[cell].v)[area];
			} // for price column
		});
	}

	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const excelBuffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
	const data = new Blob([excelBuffer], { type: fileType });
	if (saveType === 'array') {
		FileSaver.saveAs(data, fileName + fileExtension);
	}
	return excelBuffer;
};

export { ExportExcel, exportToExcel, exportInventory };
