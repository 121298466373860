import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getShipping } from '../../scripts';
import { updateOverseasShipping } from '../../scripts/mutations';
import SoftBox from '../../components/SoftBox';
import SoftTypography from '../../components/SoftTypography';
import SoftButton from '../../components/SoftButton/index';
import SoftInput from '../../components/SoftInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import ErrorIcon from '@mui/icons-material/Error';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function Area() {
	const location = useLocation();
	const { t } = useTranslation();
	const area = useSelector((state) => state.user.userArea);
	const [checked, setChecked] = useState([]);
	const [left, setLeft] = useState([]);
	const [right, setRight] = useState([]);
	const [itemsArea, setItemsArea] = useState({});
	const [activeStep, setActiveStep] = useState(0);
	const [currentArea, setCurrentArea] = useState('');
	const [removedItems, setRemovedItems] = useState([]);
	const [originalItems, setOriginalItems] = useState([]);
	const navigate = useNavigate();
	const [searchItem, setsearchItem] = useState('');
	const [newCount, setNewCount] = useState('');
	const [initialPirce, setInitialPrice] = useState([]);

	const handleSearchChange = (event) => setsearchItem(event.target.value);
	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const steps = [t('area.Select Area'), t('area.Enter Price')];

	const handleNext = () => {
		let count = 0;
		right.map((i) => (i.Price === 0 ? (count += 1) : 0));
		setNewCount(count);
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setInitialPrice(JSON.parse(JSON.stringify(right)));
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		setRight(initialPirce);
	};

	const handleInventory = () => navigate('/inventory');

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};

	const numberOfChecked = (items) => intersection(checked, items).length;

	const handleToggleAll = (items) => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items));
		} else {
			setChecked(union(checked, items));
		}
	};

	const handleCheckedRight = () => {
		setsearchItem('');
		setRight(leftChecked.concat(right));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
		setRemovedItems(not(removedItems, leftChecked));
	};

	const handleCheckedLeft = () => {
		setsearchItem('');
		setLeft(rightChecked.concat(left));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
		rightChecked.map((item) => (originalItems.includes(item) ? removedItems.push(item) : ''));
		setRemovedItems(removedItems);
	};

	function not(a, b) {
		return a.filter((value) => b.indexOf(value) === -1);
	}

	function intersection(a, b) {
		return a.filter((value) => b.indexOf(value) !== -1);
	}

	function union(a, b) {
		return [...a, ...not(b, a)];
	}

	useEffect(() => {
		let areaGroup = [];
		location.state.item.map((item) => {
			if (item.Area.length > 0) {
				let price = JSON.parse(item.Price);
				item.Area.map((j) => console.log(price[j]));
				areaGroup[item.ZH] = price;
			}
		});
		setCurrentArea(currentArea);
		setItemsArea(areaGroup);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function getAreaItem(copy, areaVal) {
		let chosen = [];
		let available = [];
		if (copy) setCurrentArea(areaVal);
		setRemovedItems([]);
		let i = -1;
		for (let a in itemsArea) {
			if (itemsArea[a] !== null) {
				if (Object.keys(itemsArea[a]).includes(areaVal)) {
					chosen.push({
						Product: a,
						Price: itemsArea[a][areaVal],
						index: (i += 1)
					});
				} else {
					available.push({
						Product: a,
						Price: 0,
						index: (i += 1)
					});
				}
			} else {
				available.push({
					Product: a,
					Price: 0,
					index: (i += 1)
				});
			}
		}
		chosen.sort(function (a, b) {
			if (a.Product < b.Product) {
				return -1;
			}
			if (a.Product > b.Product) {
				return 1;
			}
			return 0;
		});
		available.sort(function (a, b) {
			if (a.Product < b.Product) {
				return -1;
			}
			if (a.Product > b.Product) {
				return 1;
			}
			return 0;
		});
		setRight(chosen);
		setLeft(available);
		setOriginalItems(chosen);
	}

	function filteredItems(searchItem) {
		const filteredItems = left.filter((element) => {
			if (searchItem.length > 0) {
				if (element.Product.includes(searchItem)) {
					return element;
				}
			} else {
				return left;
			}
			return '';
		});
		return filteredItems;
	}

	function filteredItems_2(searchItem) {
		const filteredItems_2 = right.filter((element) => {
			if (searchItem.length > 0) {
				if (element.Product.includes(searchItem)) {
					return element;
				}
			} else {
				return right;
			}
			return '';
		});
		return filteredItems_2;
	}

	function getPrice(e, product, currentArea) {
		currentArea;
		right.map((i) => (i.Product === product ? (i.Price = parseFloat(e.target.value)) : ''));
	}

	async function updateItem() {
		right.map((i) => {
			location.state.item.map(async (item) => {
				if (item.ZH === i.Product) {
					try {
						const original = await getShipping(item.id);
						const variables = {
							id: original.id,
							_version: original._version
						};
						let price = JSON.parse(original.Price);
						if (item.Area.includes(currentArea) && i.Price !== JSON.parse(item.Price)[currentArea]) {
							price[currentArea] = i.Price;
							variables['Price'] = price;
						}
						if (!item.Area.includes(currentArea)) {
							variables['Area'] = [...original.Area, currentArea];
							price[currentArea] = i.Price;
							variables['Price'] = price;
						}
						variables['Price'] = JSON.stringify(variables['Price']);
						await updateOverseasShipping(variables);
					} catch (error) {
						console.log('error', error);
					}
				}
			});
			return '';
		});
		removedItems.map((i) => {
			location.state.item.map(async (item) => {
				if (i.Product === item.ZH) {
					try {
						const original = await getShipping(item.id);
						const variables = {
							id: original.id,
							_version: original._version
						};
						let price = JSON.parse(original.Price);
						delete price[currentArea];
						original.Area.splice(original.Area.indexOf(currentArea), 1);
						variables['Price'] = price;
						variables['Area'] = original.Area;
						variables['Price'] = JSON.stringify(variables['Price']);
						await updateOverseasShipping(variables);
					} catch (error) {
						console.log('error', error);
					}
				}
			});
			return '';
		});
		navigate('/inventory');
	}

	const customList = (title, items) => (
		<Card>
			<CardHeader
				sx={{ px: 2, py: 1 }}
				avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={numberOfChecked(items) === items.length && items.length !== 0}
						indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
						disabled={items.length === 0}
						inputProps={{
							'aria-label': 'all items selected'
						}}
					/>
				}
				title={title}
				subheader={`${numberOfChecked(items)}/${items.length} ` + t('area.selected')}
			/>
			<Divider />
			<List
				sx={{
					width: 350,
					height: 300,
					bgcolor: 'background.paper',
					overflow: 'auto',
					px: 2
				}}
				dense
				component="div"
				role="list">
				{items.map((value, index) => {
					const labelId = `transfer-list-all-item-${value}-label`;
					return (
						<ListItem
							key={Object.keys(value) + index}
							role="listitem"
							onClick={handleToggle(value)}
							sx={{ marginTop: 2 }}>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} sx={{ marginLeft: -2 }} primary={`${value.Product}`} />
						</ListItem>
					);
				})}
			</List>
		</Card>
	);

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Stepper activeStep={activeStep}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</Box>
			{(() => {
				if (activeStep === 0) {
					return (
						<>
							<Grid container spacing={3}>
								<Grid item xs={12} md={9}>
									<SoftTypography variant="h6" fontWeight="bold">
										{t('area.Please select the area')}:
									</SoftTypography>
									<FormControl sx={{ paddingLeft: '10px' }}>
										<RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
											{area.map((i) => (
												<FormControlLabel
													key={i}
													value={i}
													control={<Radio />}
													checked={currentArea === i}
													onClick={() => getAreaItem(true, i)}
													label={i}
												/>
											))}
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={12}
									md={3}
									display="flex"
									justifyContent={{ xs: 'space-between', md: 'flex-end' }}
									alignItems="center">
									<SoftButton
										sx={{ marginRight: 2 }}
										size="medium"
										variant="contained"
										color="secondary"
										onClick={handleInventory}>
										{t('area.Back')}
									</SoftButton>
									<SoftButton
										size="medium"
										variant="contained"
										color="success"
										onClick={handleNext}
										disabled={right.length === 0 && left.length === 0}>
										{t('area.Next')}
									</SoftButton>
									<SoftTypography
										fontSize="small"
										color="error"
										hidden={originalItems.length === 0 || right.length !== 0}>
										<ErrorIcon />
										{t('area.You are going to remove everything')}
									</SoftTypography>
								</Grid>
							</Grid>
							<SoftBox mb={2} pt={2}>
								<SoftTypography fontSize="small">{t('area.Copy')}</SoftTypography>
								<FormControl sx={{ width: { xs: '100%', md: 300 } }}>
									<Select
										onChange={(e) => getAreaItem(false, e.target.value)}
										MenuProps={{ PaperProps: { style: { maxHeight: 200, width: 100 } } }}>
										{area
											.filter((a) => a !== currentArea)
											.map((i) => (
												<MenuItem value={i} key={i}>
													{i}
												</MenuItem>
											))}
									</Select>
								</FormControl>
								<TextField
									variant="outlined"
									value={searchItem}
									onChange={handleSearchChange}
									sx={{ width: { xs: '100%', md: 250 }, paddingLeft: { md: 2, xs: 0 }, paddingTop: { md: 0, xs: 1 } }}
									placeholder={t('area.Search product')}
								/>
							</SoftBox>
							<Grid container spacing={2} justifyContent="center" alignItems="center">
								<Grid item>{customList(t('area.Available'), filteredItems(searchItem))}</Grid>{' '}
								{/*.map(i=> Object.keys(i)) */}
								<Grid item>
									<Grid container direction="column" alignItems="center">
										<SoftButton
											sx={{ my: 0.5, width: '100%' }}
											variant="outlined"
											size="small"
											color="primary"
											onClick={handleCheckedRight}
											disabled={leftChecked.length === 0}
											aria-label="move selected right">
											&gt; {t('area.addItem')}
										</SoftButton>
										<SoftButton
											sx={{ my: 0.5, width: '100%' }}
											variant="outlined"
											color="dark"
											size="small"
											onClick={handleCheckedLeft}
											disabled={rightChecked.length === 0}
											aria-label="move selected left">
											&lt; {t('area.delItem')}
										</SoftButton>
									</Grid>
								</Grid>
								<Grid item>{customList(t('area.Chosen'), filteredItems_2(searchItem))}</Grid>
							</Grid>
						</>
					);
				}
			})()}

			{(() => {
				if (activeStep === 1) {
					return (
						<>
							<Grid container spacing={3}>
								<Grid item xs={12} md={9}>
									<SoftTypography paddingLeft={{ sx: 0, md: 2 }} fontSize="large" variant="h6" fontWeight="bold">
										<MyLocationIcon color="error" />
										{t('area.Current selected area')}: {currentArea}
									</SoftTypography>
								</Grid>
								<Grid item xs={12} md={3} display="flex" justifyContent={{ xs: 'space-between', md: 'flex-end' }}>
									<SoftButton sx={{ marginRight: 2 }} size="medium" color="secondary" onClick={handleBack}>
										{t('area.Back')}
									</SoftButton>
									<SoftButton size="medium" color="success" onClick={() => updateItem()}>
										{t('area.Save')}
									</SoftButton>
								</Grid>
							</Grid>

							<SoftBox paddingTop={2} justifyContent="left">
								<SoftTypography
									sx={{ paddingLeft: { sx: 0, md: '14px' } }}
									hidden={removedItems.length === 0}
									fontSize="medium"
									variant="h6"
									fontWeight="bold">
									<DeleteForeverIcon fontSize="medium" color="dark" viewBox="0 0 20 18"></DeleteForeverIcon>
									{t('area.Removed Items')}: {removedItems.length}
								</SoftTypography>
								<Grid container>
									<Grid item xs={6} paddingLeft={{ sx: 0, md: 2 }} pt={2}>
										{removedItems.map((i, index) => {
											return (
												<SoftTypography paddingBottom={2} fontSize="medium" key={i + i + index}>
													{i.Product}
												</SoftTypography>
											);
										})}
									</Grid>
								</Grid>
							</SoftBox>
							<SoftBox paddingTop={4} justifyContent="left">
								<SoftTypography
									sx={{ marginTop: -3 }}
									paddingLeft={{ sx: 0, md: 2 }}
									hidden={right.length === 0}
									fontSize="medium"
									variant="h6"
									fontWeight="bold">
									<CheckBoxIcon fontSize="medium" color="dark" viewBox="0 0 24 18"></CheckBoxIcon>
									{t('area.Selected Items')}: ({t('area.Total')}: {right.length} | {t('area.New added')}: {newCount})
								</SoftTypography>
								<Grid container paddingTop={2}>
									{right
										.sort((a, b) => a.Price - b.Price)
										.map((i, index) => {
											return (
												<Grid container item xs={12} md={6} key={i + index + i.Product} paddingLeft={{ sx: 0, md: 2 }}>
													<Grid item xs={12} md={6}>
														<SoftTypography fontSize="medium">{i.Product}</SoftTypography>
													</Grid>
													<Grid item xs={12} md={6} paddingBottom={2}>
														<SoftInput
															p={2}
															mx={3}
															display="flex"
															key={i + index + i + i}
															placeholder="$"
															error={i.Price === 0}
															size="small"
															defaultValue={i.Price}
															onChange={(e) => getPrice(e, i.Product, currentArea)}></SoftInput>
													</Grid>
												</Grid>
											);
										})}
								</Grid>
							</SoftBox>
						</>
					);
				}
			})()}
		</>
	);
}

export default Area;
