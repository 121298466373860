import { View, Text, useTheme } from '@aws-amplify/ui-react';

export default function Footer() {
	const { tokens } = useTheme();

	return (
		<View textAlign="center" padding={tokens.space.large}>
			<Text color="white">MACU TEA &copy; All Rights Reserved</Text>
		</View>
	);
}
