import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function Dialog({
	dialog,
	title,
	content,
	cancel,
	submit,
	handleClose,
	handleSubmit,
	showBtn,
	disable,
	fullWidth,
	showSub
}) {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(dialog);
	}, [dialog]);

	return (
		<MuiDialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth={fullWidth}>
			<DialogTitle id="alert-dialog-title" align="center" fontWeight="bold" fontSize={16}>
				{title}
			</DialogTitle>
			<DialogContent sx={{ textAlign: 'center', fontSize: 16 }}>{content}</DialogContent>
			<DialogActions sx={{ display: showBtn ? undefined : 'none' }}>
				<Button
					variant={'outlined'}
					color="inherit"
					onClick={handleClose}
					autoFocus
					style={{ color: '#e6280f', borderColor: '#e6280f' }}>
					{cancel}
				</Button>
				<Button
					variant={'outlined'}
					color="error"
					onClick={handleSubmit}
					style={{ color: '#019923de', borderColor: '#019923de' }}
					disabled={disable}
					sx={{ display: showSub ? undefined : 'none' }}>
					{submit}
				</Button>
			</DialogActions>
		</MuiDialog>
	);
}
