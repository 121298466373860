import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Storage } from '@aws-amplify/storage';
import Dialog from '../../components/Dialog';
import { updateOverseasShipping, createOverseasShipping } from '../../scripts/mutations';
import { getShipping, listShippings } from '../../scripts';
import SoftBox from '../../components/SoftBox';
import SoftTypography from '../../components/SoftTypography';
import SoftButton from '../../components/SoftButton';
import SoftInput from '../../components/SoftInput';
import { styled } from '@mui/material/styles';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormHelperText from '@mui/material/FormHelperText';
import Icon from '@mui/material/Icon';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
});

function Edit() {
	const location = useLocation();
	const { t } = useTranslation();
	const inputRef = useRef();
	const inputMin = useRef();
	const navigate = useNavigate();
	const zoneinfo = useSelector((state) => state.user.userZone);
	const area = useSelector((state) => state.user.userArea);
	const [inputVal, setInputVal] = useState({});
	const [initialPrice, setInitialPrice] = useState({});
	const [checked, setChecked] = useState(true);
	const [qtyError, setQtyError] = useState(false);
	const [open, setOpen] = useState(false);
	const [priceSet, setPriceSet] = useState([]);
	const [shipFrom, setShipFrom] = useState([]);
	const [selectedFile, setSelectedFile] = useState('');
	const role = zoneinfo.includes('Y_TW') ? 'TW' : 'US';

	const handleChange = (event) => {
		setChecked(event.target.checked);
		let newVal = { ...inputVal };
		if (event.target.checked) {
			if (location.state.val !== undefined) {
				newVal.Moq = [location.state.val['Moq'][0], location.state.val['Moq'][location.state.val['Moq'].length - 1]]; //allow user to switch from multiplier to minmax
				inputRef.current.value = newVal.Moq[1];
				inputMin.current.value = newVal.Moq[0];
			} else {
				newVal.Moq = [0, 200];
				inputRef.current.value = 200;
				inputMin.current.value = 0;
			}
		} else {
			inputRef.current.value = 1;
			inputMin.current.value = 0;
			newVal.Moq = [newVal.Moq[0]];
		}
		setInputVal(newVal);
	};

	const handleClickOpen = () => setOpen(true);

	const handleClose = () => setOpen(false);

	useEffect(() => {
		getShipFrom();
		inputRef.current.value = location.state.val !== undefined ? location.state.val['Moq'][1] : 200;
		inputMin.current.value = location.state.val !== undefined ? location.state.val['Moq'][0] : 0;

		let input = {};
		location.state.item.slice(1, 20).map((item) => {
			switch (item.id) {
				case 'Moq':
					input[item.id] = location.state.val !== undefined ? location.state.val.Moq : [0, 200];
					break;
				case 'Area':
					input[item.id] = location.state.val !== undefined ? location.state.val.Area : [];
					break;
				case 'Price':
					input[item.id] = location.state.val !== undefined ? JSON.parse(location.state.val.Price) : {};
					if (location.state.val !== undefined) {
						setInitialPrice(JSON.parse(location.state.val.Price));
					}
					break;
				case 'Category':
					input[item.id] =
						location.state.val !== undefined ? location.state.val.Category : role === 'US' ? 'Fruit' : 'Uniform';
					break;
				case 'ShipFrom':
					input[item.id] = location.state.val !== undefined ? location.state.val.ShipFrom : role === 'US' ? 'US' : null;
					break;
				default:
					input[item.id] = location.state.val !== undefined ? location.state.val[item.id] : null;
					break;
			}
			return '';
		});

		if (location.state.val !== undefined) {
			setPriceSet(location.state.val['Moq']);
			if (location.state.val['Moq'].length > 2) {
				setChecked(false);
				inputRef.current.value = location.state.val['Moq'].length;
			} else if (
				location.state.val['Moq'].length === 2 &&
				location.state.val['Moq'][1] / location.state.val['Moq'][0] === 2
			) {
				setChecked(false);
			}
		}
		setInputVal(input);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getShipFrom() {
		try {
			const posts = await listShippings();
			const unique = [...new Set(posts.filter((item) => item.ShipFrom !== null).map((item) => item.ShipFrom))];
			setShipFrom(unique);
		} catch (error) {
			console.log('Error retrieving inv', error);
		}
	}

	const uploadFile = async () => {
		const ms = moment().valueOf();
		try {
			const res = await Storage.put(`products/img/${ms}_${selectedFile.name}`, selectedFile);
			setSelectedFile('');
			return res.key;
		} catch (error) {
			console.log('Error uploading file: ', error);
		}
	};

	const createItem = async () => {
		let imgUrl = '';
		if (selectedFile.name) {
			imgUrl = await uploadFile();
		}
		if (location.state.val !== undefined) {
			await updateItem(imgUrl);
		} else {
			const variables = {
				Product: inputVal.Product,
				Category: inputVal.Category,
				Code: inputVal.Code,
				ZH: inputVal.ZH,
				Size: inputVal.Size,
				Expiry: inputVal.Expiry,
				Unit: inputVal.Unit,
				Note: inputVal.Note,
				HSCode: inputVal.HSCode,
				Brand: inputVal.Brand,
				Material: inputVal.Material,
				Area: inputVal.Area,
				Price: JSON.stringify(inputVal.Price),
				Moq: inputVal.Moq,
				NW: inputVal.NW,
				GW: inputVal.GW,
				CartonSize: inputVal.CartonSize,
				ShipFrom: inputVal.ShipFrom,
				Img: imgUrl
			};
			await createOverseasShipping(variables);
		}
		navigate('/inventory');
	};

	async function updateItem(imgUrl) {
		try {
			const original = await getShipping(location.state.val.id);
			const variables = {
				id: original.id,
				_version: original._version,
				Product: inputVal.Product,
				Category: inputVal.Category,
				Code: inputVal.Code,
				ZH: inputVal.ZH,
				Size: inputVal.Size,
				Expiry: inputVal.Expiry,
				Unit: inputVal.Unit,
				Note: inputVal.Note,
				HSCode: inputVal.HSCode,
				Brand: inputVal.Brand,
				Material: inputVal.Material,
				Area: inputVal.Area,
				Price: JSON.stringify(inputVal.Price),
				Moq: inputVal.Moq,
				NW: inputVal.NW,
				GW: inputVal.GW,
				CartonSize: inputVal.CartonSize,
				ShipFrom: inputVal.ShipFrom,
				Img: imgUrl
			};
			await updateOverseasShipping(variables);
		} catch (error) {
			console.log('error', error);
		}
	}

	function getInput(e, id, areaName) {
		let newVal = { ...inputVal };
		let val;
		let min = 0;
		switch (id) {
			case 'Min':
				let multiple = newVal.Moq.length; // eslint-disable-line
				val = parseInt(e.target.value);
				newVal.Moq[0] = val;
				if (!checked && val > 0) {
					for (let i = 0; i < multiple; i++) {
						priceSet[i] = min += newVal.Moq[0];
					}
					setPriceSet(newVal.Moq);
				} else if (val < 0) {
					inputMin.current.value = '';
					newVal.Moq[0] = null;
				}
				break;
			case 'Max':
				val = parseInt(e.target.value);
				if (val > 0) {
					newVal.Moq[1] = val;
				} else {
					inputRef.current.value = '';
				}
				break;
			case 'Multiplier':
				val = parseInt(e.target.value);
				setPriceSet([]);
				if (val >= 0) {
					for (let i = 0; i < val; i++) {
						priceSet[i] = min += newVal.Moq[0];
						newVal.Moq = priceSet;
						setPriceSet(newVal.Moq);
					}
				} else {
					newVal.Moq = [newVal.Moq[0]];
					inputRef.current.value = '';
				}
				break;
			case 'Expiry':
				val = parseInt(e.target.value);
				newVal[id] = val || null;
				break;
			case 'NW':
				val = parseFloat(e.target.value);
				newVal[id] = val;
				break;
			case 'GW':
				val = parseFloat(e.target.value);
				newVal[id] = val;
				break;
			case 'ShipFrom':
				val = e.target.value;
				newVal[id] = val || null;
				newVal.Price = {};
				if (val && val !== 'TW') {
					newVal['Area'] = [val];
				} else {
					newVal['Area'].splice(0, newVal['Area'].length); //clear area when !val
				}
				break;
			case 'Area':
				if (e.target.checked !== undefined) {
					if (areaName === 'all') {
						if (e.target.checked) {
							area.map((i) => {
								if (newVal.Price[i] === undefined) newVal.Price[i] = 0;
								return i;
							});
							newVal[id] = [...area];
						} else {
							area.map((i) => {
								if (!initialPrice[i]) {
									delete newVal.Price[i]; //remove price when no initial price
								} else {
									newVal.Price[i] = initialPrice[i]; //set to initial price
								}
							});
							newVal[id] = [];
						}
					} else {
						if (e.target.checked) {
							newVal[id].push(areaName);
						} else {
							let index = newVal[id].indexOf(areaName);
							if (!initialPrice[areaName]) {
								delete newVal.Price[areaName];
							} else {
								newVal.Price[areaName] = initialPrice[areaName];
							}
							if (index !== -1) {
								// delete newVal.Price[areaName]; // delete selected area price
								newVal[id].splice(index, 1);
							}
						}
					}
				}
				break;
			case 'Price':
				val = e.target.value;
				const parts = val.split('.'); // eslint-disable-line
				if (val < 0 || val === '') {
					val = 0;
				}
				if (parts.length > 1 && parts[1].length > 2) {
					parts[1] = parts[1].substring(0, 2);
					val = parts.join('.');
					newVal[id][areaName] = parseFloat(val);
				} else {
					newVal[id][areaName] = parseFloat(val);
				}
				break;
			case 'Img':
				newVal[id] = '';
				break;
			default:
				val = e.target.value;
				newVal[id] = val || null;
				break;
		}
		setInputVal(newVal);
	}

	function onBlur(event, id) {
		event.preventDefault();
		id;
		let newVal = { ...inputVal };
		if (newVal.Moq[1] < newVal.Moq[0]) {
			setQtyError(true);
		} else {
			setQtyError(false);
		}
		setInputVal(newVal);
	}

	const chooseFile = (event) => setSelectedFile(event.target.files[0]);

	const RenderImg = ({ imgSrc }) => (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<img src={imgSrc} width="30%" height="30%" />
		</div>
	);

	function inputFormat(id) {
		inputVal.Area = inputVal.Area || [];
		let inVal = location.state.val !== undefined ? location.state.val[id] : null;
		const imgShort = inputVal.Img && inputVal.Img.replace('products/img/', '');
		switch (id) {
			case 'Area':
				const priceLen = inputVal.Area.length;
				const areaLen = area.length;
				const itemStatus = priceLen === areaLen ? 'success' : priceLen === 0 ? 'light' : 'warning';
				return (
					<Card sx={{ width: '100%', overflow: 'auto', maxHeight: 200 }}>
						<CardContent>
							<Box justifyContent="space-between" display="flex">
								<FormControl component="fieldset" style={{ paddingTop: 2 }}>
									<FormControlLabel
										control={
											<Checkbox
												name={t('edit.Selectall')}
												checked={inputVal.Area.length === area.length}
												onChange={(e) => getInput(e, 'Area', 'all')}
											/>
										}
										label={t('edit.Selectall')}
										sx={{ paddingLeft: '10px' }}
										labelPlacement="end"
									/>
								</FormControl>
								<SoftTypography fontSize="small">
									<FiberManualRecordIcon color={itemStatus} sx={{ marginRight: 0.5 }} />
									{t(`inventory.status_${itemStatus}`)}
								</SoftTypography>
							</Box>
							<FormGroup aria-label="position" row sx={{ paddingTop: 1 }}>
								<Grid container>
									{area.map((i, index) => {
										return (
											<>
												<Grid item md={10} xs={12} pb={1.5}>
													<FormControlLabel
														control={
															<Checkbox
																name={i}
																checked={inputVal.Area && inputVal.Area.length > 0 ? inputVal.Area.includes(i) : false}
																onChange={(e) => getInput(e, 'Area', i)}
															/>
														}
														label={i}
														key={i + index}
														sx={{ paddingLeft: '10px' }}
														labelPlacement="end"
													/>
												</Grid>
												<Grid item md={2} xs={12}>
													<SoftInput
														type="number"
														step="0.01"
														size="medium"
														onChange={(e) => getInput(e, 'Price', i)}
														onFocus={(e) => console.log('e', e.target)}
														disabled={!inputVal.Area.includes(i)}
														value={
															inputVal.Price === undefined || !inputVal.Price[i] ? 0 : inputVal.Price[i]
														}></SoftInput>
												</Grid>
											</>
										);
									})}
								</Grid>
							</FormGroup>
						</CardContent>
					</Card>
				);
			case 'Moq':
				return (
					<>
						<Grid paddingTop={2}>
							<Stack direction="row" spacing={1} alignItems="center">
								<Typography fontSize="small">{t('edit.Multiple')}</Typography>
								<Switch checked={checked} onChange={handleChange} />
								<Typography fontSize="small">{t('edit.Moq')}</Typography>
							</Stack>
						</Grid>
						<Grid container paddingTop={2}>
							<Grid item xs={3}>
								<SoftTypography fontSize="small">{t('edit.Min')}</SoftTypography>
								<SoftInput
									type="number"
									p={2}
									display="flex"
									size="small"
									inputRef={inputMin}
									onChange={(e) => getInput(e, 'Min')}
									onBlur={(e) => onBlur(e, 'Min')}></SoftInput>
							</Grid>
							<Grid item xs={3}>
								<SoftTypography fontSize="small">{checked ? t('edit.Max') : t('edit.Multiplier')}</SoftTypography>
								<SoftInput
									type="number"
									p={2}
									display="flex"
									size="small"
									inputRef={inputRef}
									onChange={(e) => getInput(e, checked ? 'Max' : 'Multiplier')}
									onBlur={(e) => onBlur(e, checked ? 'Max' : 'Multiplier')}></SoftInput>{' '}
								{/*value={checked?parseInt(inputVal.Moq[1]):''} value={inputVal.Moq[0]>inputVal.Moq[1]? '':inputVal.Moq[1]} */}{' '}
								{/*onBlur={(e)=>onBlur(e, checked? ('Max'):('Multiplier'))} */}
							</Grid>
							<Grid item xs={6} paddingLeft={1} paddingTop={4}>
								<SoftTypography fontSize="small">{checked ? '' : inputVal.Moq.join(', ')}</SoftTypography>
								<FormHelperText
									sx={{ fontSize: 'xs' }}
									error={qtyError}
									id="outlined-weight-helper-msg"
									hidden={!(qtyError && checked)}>
									{t('edit.Max should > Min')}
								</FormHelperText>
							</Grid>
						</Grid>
					</>
				);
			case 'Note':
				return (
					<Grid>
						<TextareaAutosize
							maxRows={3}
							style={{ width: '100%' }}
							onChange={(e) => getInput(e, id)}
							defaultValue={inVal}
						/>
					</Grid>
				);
			case 'Category':
				return (
					<Box sx={{ minWidth: 120 }}>
						<FormControl fullWidth>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								defaultValue={inVal || location.state.category[0]}
								onChange={(e) => getInput(e, id)}>
								{location.state.category.map((i, index) => (
									<MenuItem value={i} key={i + index}>
										{i}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				);
			case 'Product':
				return (
					<Grid>
						<SoftInput
							p={2}
							key={id}
							defaultValue={inVal}
							display="flex"
							size="medium"
							placeholder={t('inventory.' + id)}
							onChange={(e) => getInput(e, id)}></SoftInput>
						<SoftTypography fontSize="small" color="error" hidden={inputVal.Product}>
							{t('edit.*required')}
						</SoftTypography>
					</Grid>
				);
			case 'Code':
				return (
					<Grid>
						<SoftInput
							p={2}
							key={id}
							defaultValue={inVal}
							display="flex"
							size="medium"
							placeholder={t('inventory.' + id)}
							onChange={(e) => getInput(e, id)}></SoftInput>
						<SoftTypography fontSize="small" color="error" hidden={inputVal.Code}>
							{t('edit.*required')}
						</SoftTypography>
					</Grid>
				);
			case 'ZH':
				return (
					<Grid>
						<SoftInput
							p={2}
							key={id}
							defaultValue={inVal}
							display="flex"
							size="medium"
							placeholder={t('inventory.' + id)}
							onChange={(e) => getInput(e, id)}></SoftInput>
						<SoftTypography fontSize="small" color="error" hidden={inputVal.ZH}>
							{t('edit.*required')}
						</SoftTypography>
					</Grid>
				);
			case 'NW':
			case 'GW':
				return (
					<Grid>
						<SoftInput
							type="number"
							p={2}
							key={id}
							defaultValue={inVal}
							display="flex"
							size="medium"
							placeholder={t('inventory.' + id)}
							onChange={(e) => getInput(e, id)}></SoftInput>
					</Grid>
				);
			case 'Expiry':
				return (
					<Grid>
						<SoftInput
							type="number"
							p={2}
							key={id}
							defaultValue={inVal}
							display="flex"
							size="medium"
							placeholder={t('inventory.In months')}
							onChange={(e) => getInput(e, id)}></SoftInput>
					</Grid>
				);
			case 'ShipFrom':
				return (
					<>
						{role === 'TW' ? (
							<Grid>
								<Stack spacing={2}>
									<Autocomplete
										id="ship-from"
										freeSolo
										defaultValue={inVal}
										options={shipFrom.map((option) => option)}
										onBlur={(e, option) => getInput(e, id, option)}
										renderInput={(params) => <TextField placeholder={t('inventory.' + id)} {...params} />}
									/>
								</Stack>
								<SoftTypography fontSize="small" color="error" hidden={inputVal.ShipFrom}>
									{t('edit.*required')}
								</SoftTypography>
							</Grid>
						) : (
							<Grid>
								<SoftInput p={2} key={id} value="US" display="flex" size="medium" disabled={true}></SoftInput>
							</Grid>
						)}
					</>
				);
			case 'Img':
				return (
					<>
						<Stack direction={'row'}>
							<Typography fontSize={12} color={'error'} pr={2} pt={1}>
								{t('inventory.Max 1 image')}
							</Typography>
							<SoftButton
								component="label"
								role={undefined}
								variant="contained"
								color="light"
								size="small"
								tabIndex={-1}
								startIcon={<CloudUploadIcon />}>
								{t('inventory.Upload')}
								<VisuallyHiddenInput type="file" onChange={chooseFile} />
							</SoftButton>
						</Stack>
						{selectedFile.name && (
							<>
								<Stack direction={'row'}>
									<SoftTypography fontSize="medium" paddingTop={1}>
										{t('inventory.Selected')}
										{selectedFile.name}
									</SoftTypography>
									<IconButton onClick={() => setSelectedFile('')}>
										<RemoveCircleIcon size="small" color="error" />
									</IconButton>
								</Stack>
								<RenderImg imgSrc={URL.createObjectURL(selectedFile)} />
							</>
						)}
						{inputVal.Img && (
							<>
								<Stack direction={'row'}>
									<SoftTypography fontSize="medium" paddingTop={1}>
										{t('inventory.Current')}
										{imgShort.includes('_') ? imgShort.substring(14) : imgShort}
									</SoftTypography>
									<IconButton onClick={(e) => getInput(e, id)}>
										<RemoveCircleIcon size="small" color="error" />
									</IconButton>
								</Stack>
								<RenderImg
									imgSrc={`https://macuglobal82751-staging.s3.ap-southeast-1.amazonaws.com/public/${inputVal.Img}`}
								/>
							</>
						)}
					</>
				);
			default:
				return (
					<Grid>
						<SoftInput
							p={2}
							key={id}
							defaultValue={inVal}
							display="flex"
							size="medium"
							placeholder={t('inventory.' + id)}
							onChange={(e) => getInput(e, id)}></SoftInput>
					</Grid>
				);
		}
	}
	function discardItem() {
		navigate('/inventory');
	}

	function renderRow() {
		return location.state.item.slice(1, 20).map((i, index) => {
			switch (i.id) {
				case 'Area':
					return (
						<>
							<Grid item xs={12} md={12}>
								<SoftTypography fontSize="medium" fontWeight="bold">
									{t('inventory.Area and Price')}
								</SoftTypography>
							</Grid>
							<Grid item xs={12} md={10} key={i + index} paddingBottom={2}>
								{inputFormat(i.id)}
							</Grid>
						</>
					);
				case 'Price':
					break;
				default:
					return (
						<Grid item xs={12} md={6} key={i + index}>
							<Grid item xs={12} md={8}>
								{i.id === 'Product' ? (
									<SoftTypography fontSize="medium" fontWeight="bold">
										<Tooltip title={t('edit.info')}>
											<Icon color="dark">info</Icon>
										</Tooltip>
										{t('inventory.' + i.id)}
									</SoftTypography>
								) : (
									<SoftTypography fontSize="medium" fontWeight="bold">
										{t('inventory.' + i.id)}
									</SoftTypography>
								)}
							</Grid>
							<Grid item xs={12} md={8} paddingBottom={2}>
								{inputFormat(i.id)}
							</Grid>
						</Grid>
					);
			}
		});
	}

	return (
		<>
			<SoftBox mx={2} display="flex" justifyContent="center">
				<Grid container>{renderRow()}</Grid>
			</SoftBox>
			<SoftBox p={2} mx={3} display="flex" justifyContent="left">
				<Tooltip>
					<SoftButton sx={{ marginRight: 2 }} aria-label="delete" color="error" size="small" onClick={handleClickOpen}>
						{t('edit.Discard')}
					</SoftButton>
				</Tooltip>
				<Tooltip>
					<SoftButton
						aria-label="save"
						color="success"
						size="small"
						onClick={createItem}
						disabled={
							(qtyError && checked) ||
							inputVal.Product === null ||
							inputVal.Code === null ||
							inputVal.ZH === null ||
							inputVal.ShipFrom === null
						}>
						{t('edit.Save')}
					</SoftButton>
				</Tooltip>
				<Dialog
					dialog={open}
					title={t('edit.This is going to delete everything.')}
					content={t('Are you sure?')}
					cancel={t('No')}
					submit={t('Yes')}
					handleClose={handleClose}
					handleSubmit={discardItem}
					showBtn={true}
					fullWidth={false}
					showSub={true}
				/>
			</SoftBox>
		</>
	);
}

export default Edit;
