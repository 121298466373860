import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { store, user, userCreated, create, edit } from '../../assets/images/index';
import { listArea, fetchAllStore } from '../../scripts';
import SoftInput from '../../components/SoftInput';
import SoftButton from '../../components/SoftButton';
import { createUser, listUsers, updateUserAttr } from '../../scripts/lambda';
import { createStore, updateStore } from '../../scripts/mutations';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const accountImages = [
	{
		url: store,
		title: 'Store',
		width: '30%'
	},
	{
		url: user,
		title: 'User',
		width: '30%'
	}
];

const actionImages = [
	{
		url: create,
		title: 'create',
		width: '30%'
	},
	{
		url: edit,
		title: 'edit',
		width: '30%'
	}
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
	position: 'relative',
	height: 200,
	[theme.breakpoints.down('sm')]: {
		width: '100% !important', // Overrides inline-style
		height: 100
	},
	'&:hover, &.Mui-focusVisible': {
		zIndex: 1,
		'& .MuiImageBackdrop-root': {
			opacity: 0.15
		},
		'& .MuiImageMarked-root': {
			opacity: 0
		},
		'& .MuiTypography-root': {
			border: '4px solid currentColor'
		}
	}
}));

const ImageSrc = styled('span')({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: 'cover',
	backgroundPosition: 'center 40%'
});

const Image = styled('span')(({ theme }) => ({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: theme.palette.common.white
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundColor: theme.palette.common.black,
	opacity: 0.4,
	transition: theme.transitions.create('opacity')
}));

const ImageMarked = styled('span')(({ theme }) => ({
	height: 3,
	width: 18,
	backgroundColor: theme.palette.common.white,
	position: 'absolute',
	bottom: -2,
	left: 'calc(50% - 9px)',
	transition: theme.transitions.create('opacity')
}));

export function CreateUser({ setType }) {
	const { t } = useTranslation();
	const steps = ['Selet Your Action', 'Select Account Type', 'Access Account', 'Done'];
	const userType = ['Staff', 'Owner'];
	const role = ['Viewer', 'Observer', 'Supervisor', 'Editor', 'Manager'];
	const currency = ['TWD', 'USD'];
	const [activeStep, setActiveStep] = useState(0);
	const [accType, setAccType] = useState();
	const [action, setAction] = useState();
	const [storeList, setStoreList] = useState([]);
	const [account, setAccount] = useState([]);
	const [allStore, setAllStore] = useState([]);
	const [allUser, setAllUser] = useState([]);
	const [userList, setUserList] = useState([]);
	const [areaList, setAreaList] = useState([]);
	const [disable, setDisable] = useState();
	const [userVal, setUserVal] = useState({ Name: '', Email: '', ViewArea: [], Type: '', Role: '', TWStaff: false });
	const [storeVal, setStoreVal] = useState({
		Email: '',
		Company: '',
		Owner: '',
		ContractNo: '',
		CountryCode: '',
		Address: '',
		City: '',
		Currency: '',
		Phone: '',
		Store: ['']
	});

	async function secondStep(label) {
		setAccType(label);
		if (label === 'User') {
			const area = await listArea();
			setAreaList(area);
		}
		setActiveStep((prev) => prev + 1);
	}

	useEffect(() => {
		if (action === 'edit' && accType === 'Store') {
			fetchAllStore().then((res) => {
				let list = [];
				res.map((item) => list.push(item.Company));
				setAllStore(res);
				setStoreList(list);
			});
		}
		listUsers().then((res) => {
			const name = res.map((r) => r.name);
			setAllUser(res);
			setUserList(name);
		});
	}, [action, accType]);

	function resetState() {
		setStoreVal({
			Email: '',
			Company: '',
			Owner: '',
			ContractNo: '',
			CountryCode: '',
			City: '',
			Address: '',
			Currency: '',
			Phone: '',
			Store: ['']
		});
		setUserVal({ Name: '', Email: '', ViewArea: [], Type: '', Role: '' });
		setAccType();
	}

	function clearAccount() {
		resetState();
		setAccount([]);
		setActiveStep((prev) => prev - 1);
	}

	const validateEmail = (email) => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	function getInput(key, e, v) {
		let val;
		let checkDisable = false;
		let prev = { ...userVal };
		if (accType === 'User') {
			val = e.target.value;
			switch (key) {
				case 'ViewArea':
					prev[key] = v;
					break;
				case 'Type':
					val = e.target.innerText;
					if (val === 'Owner') {
						prev['Role'] = '';
						prev['TWStaff'] = false;
					}
					prev[key] = val || '';
					break;
				case 'Role':
					val = e.target.innerText;
					prev[key] = val || '';
					break;
				case 'TWStaff':
					prev[key] = !prev[key];
					break;
				default:
					prev[key] = val;
					break;
			}
			let input = false;
			if (prev.Type === '') {
				input = true;
			} else if (prev.Type === 'Owner') {
				input = prev.Name === '' || prev.Email === '';
			} else if (prev.Type === 'Staff') {
				input = Object.values(prev).includes('');
			}
			checkDisable =
				input || prev.ViewArea.map((m) => m).includes('') || prev.ViewArea.length === 0 || !validateEmail(prev.Email);
			setUserVal(prev);
		} else {
			prev = { ...storeVal };
			if (key === 'Currency') {
				val = e.target.innerText;
				prev[key] = val || '';
			} else if (key === 'Store') {
				if (e === 'remove') {
					prev[key].splice(v, 1);
				} else {
					val = e.target.value;
					prev[key][v] = val;
				}
			} else if (key === 'CountryCode') {
				val = e.target.value.toUpperCase();
				prev[key] = val;
			} else {
				val = e.target.value;
				prev[key] = val;
			}
			checkDisable =
				Object.values(prev).includes('') || prev.Store.map((s) => s).includes('') || !validateEmail(prev.Email);
			setStoreVal(prev);
		}
		setDisable(checkDisable);
	}

	function addStore() {
		let val = { ...storeVal };
		if (!val.Store.includes('')) {
			val.Store.push('');
			setStoreVal(val);
		}
	}

	function getAction(val) {
		setDisable(() => val === 'edit');
		setActiveStep((prev) => {
			setAction(val);
			return prev + 1;
		});
	}

	function clearAction() {
		setActiveStep((prev) => {
			setAction('');
			setAccount([]);
			return prev - 1;
		});
	}

	function getAccount(e) {
		if (accType === 'Store') {
			let company = e.target.innerText;
			let curAccount = allStore.filter((s) => s.Company === company)[0];
			let filtered = {
				Email: curAccount.Email,
				Company: curAccount.Company,
				Owner: curAccount.Owner,
				ContractNo: curAccount.ContractNo,
				CountryCode: curAccount.Country,
				City: curAccount.Name,
				Address: curAccount.Address,
				Currency: curAccount.Currency,
				Phone: curAccount.Phone,
				Store: curAccount.Store,
				id: curAccount.id,
				_version: curAccount._version
			}; //set order
			setStoreVal(filtered);
			setAccount([filtered]);
		} else {
			let user = e.target.innerText;
			let curAccount = allUser.filter((u) => u.name === user);
			let zoneArray = curAccount[0].zoneinfo.split(',');
			let type = zoneArray.includes('Owner') ? 'Owner' : 'Staff';
			let role = zoneArray[zoneArray.length - 1].includes('Z') ? zoneArray[zoneArray.length - 1].substring(2) : '';
			let attr = {
				Name: curAccount[0].name,
				Email: curAccount[0].email,
				ViewArea: curAccount[0].address.split(','),
				Type: type,
				Role: role,
				TWStaff: zoneArray.includes('Y_TW') ? true : false,
				Sub: curAccount[0].sub
			};
			setUserVal(attr);
			setAccount([attr]);
		}
	}

	async function handleSubmit(event) {
		event.preventDefault();
		const data = [];
		if (accType === 'User') {
			for (const [key, value] of Object.entries(userVal)) {
				if (key === 'Name' || key === 'Email') {
					data.push({ Name: key.toLowerCase(), Value: value });
				}
				if (key === 'ViewArea') {
					data.push({ Name: 'address', Value: value.toString() });
				}
				if (key === 'Type') {
					let zoneinfo = value === 'Owner' ? 'Owner' : 'Z_' + userVal.Role;
					if (userVal.TWStaff) {
						zoneinfo = zoneinfo.replace(/^/, 'Y_TW,');
					}
					data.push({ Name: 'zoneinfo', Value: zoneinfo });
				}
			}
			if (action === 'create') {
				createUser(data);
			} else {
				let update = { attributes: data, user: userVal.Sub };
				updateUserAttr([update]);
			}
		} else {
			// store
			let variables = {
				Company: storeVal.Company,
				Owner: storeVal.Owner,
				ContractNo: storeVal.ContractNo,
				Country: storeVal.CountryCode,
				Name: storeVal.City,
				Store: storeVal.Store,
				Email: storeVal.Email,
				Currency: storeVal.Currency,
				Phone: storeVal.Phone,
				Address: storeVal.Address
			};
			if (action === 'create') {
				await createStore(variables);
				//update manager's address when creating new store
				let manager = allUser.filter((u) => u.zoneinfo.split(',').includes('Z_Manager'));
				let list = [];
				const newArea = storeVal.CountryCode + '_' + storeVal.Company.replaceAll('& ', '').replaceAll(' ', '-');
				manager.map((m) => {
					let attr = [
						{ Name: 'name', Value: m.name },
						{ Name: 'email', Value: m.email },
						{ Name: 'address', Value: m.address + `,${newArea}` },
						{ Name: 'zoneinfo', Value: m.zoneinfo }
					];
					let managerData = { user: '', attributes: '' };
					managerData.user = m.sub;
					managerData.attributes = attr;
					list.push(managerData);
				});
				updateUserAttr(list);
			} else {
				variables.id = storeVal.id;
				variables._version = storeVal._version;
				await updateStore(variables);
			}
		}
		resetState();
		setTimeout(() => {
			setType();
			setActiveStep((prev) => prev + 1);
		}, 1000);
	}

	const SelectImages = (image, func) => (
		<Box sx={{ minWidth: 300, pt: 2 }} textAlign={'center'}>
			{image.map((image) => (
				<ImageButton
					focusRipple
					key={image.title}
					onClick={() => func(image.title)}
					style={{
						width: image.width,
						height: 220,
						border: '1px solid #eaeaea'
					}}>
					<ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
					<ImageBackdrop className="MuiImageBackdrop-root" />
					<Image>
						<Typography
							component="span"
							variant="subtitle1"
							color="inherit"
							sx={{
								position: 'relative',
								p: 4,
								pt: 2,
								pb: (theme) => `calc(${theme.spacing(1)} + 6px)`
							}}>
							{t('account.' + image.title)}
							<ImageMarked className="MuiImageMarked-root" />
						</Typography>
					</Image>
				</ImageButton>
			))}
		</Box>
	);

	const UserCard = () => (
		<Box textAlign={'-webkit-center'} pt={1}>
			<Box display={action === 'edit' ? undefined : 'none'} pb={1}>
				<Autocomplete
					size="small"
					sx={{ width: 500 }}
					id="combo-box-demo"
					options={accType === 'Store' ? storeList : userList}
					renderInput={(params) => <TextField {...params} />}
					onChange={(e) => getAccount(e)}
					value={accType === 'Store' ? (account[0] ? account[0].Company : '') : account[0] ? account[0].Name : ''}
				/>
			</Box>
			<Box display={action === 'edit' && !account[0] ? 'none' : undefined}>
				<Card sx={{ width: { md: 700, xs: 360 } }}>
					{accType === 'Store' ? (
						<CardContent sx={{ m: 1 }}>
							<Typography fontWeight={'bold'} display={action === 'edit' ? 'none' : undefined}>
								{t('account.Create new store')}
							</Typography>
							<Grid container p={2}>
								{Object.keys(storeVal).map((v, index) => {
									switch (v) {
										case 'Currency':
											return (
												<Grid item md={6} xs={12} pt={2} pl={2} key={index}>
													<Typography pr={1} fontSize={15} align="left">
														{t('account.' + v)}
													</Typography>
													<Autocomplete
														size="small"
														id="combo-box-demo"
														options={currency}
														renderInput={(params) => <TextField {...params} />}
														onChange={(e, val) => getInput(v, e, val)}
														value={storeVal[v]}
													/>
												</Grid>
											);
										case 'Store':
											return storeVal.Store.map((s, index) => (
												<Grid item md={6} xs={12} pt={2} pl={2} key={index}>
													<Typography pr={1} fontSize={15} align="left">
														{t('account.' + v)}
													</Typography>
													<Stack direction={'row'}>
														<SoftInput
															size="small"
															name={v}
															onChange={(e) => getInput(v, e, index)}
															sx={{ width: 350 }}
															value={s}></SoftInput>
														<Tooltip title="please make sure to fill in" placement="right">
															<IconButton aria-label="add" size="small" onClick={() => addStore()}>
																<AddCircleIcon fontSize="small" />
															</IconButton>
														</Tooltip>
														<IconButton
															aria-label="add"
															size="small"
															onClick={() => getInput(v, 'remove', index)}
															sx={{ display: index === 0 ? 'none' : undefined }}>
															<RemoveCircleIcon fontSize="small" />
														</IconButton>
													</Stack>
												</Grid>
											));
										case 'id':
										case '_version':
											break; //hide columns
										default:
											return (
												<Grid item md={6} xs={12} pt={2} pl={2} key={index}>
													<Stack direction={'row'}>
														<Typography pr={1} fontSize={15} align="left">
															{t('account.' + v)}
														</Typography>
														<Tooltip title={t('account.ex')} placement="right">
															<a
																size="small"
																target="blank"
																style={{
																	display: v === 'CountryCode' ? undefined : 'none',
																	color: 'black'
																}}
																href="https://www.iban.com/country-codes">
																<OpenInNewIcon sx={{ display: 'block' }} />
															</a>
														</Tooltip>
													</Stack>
													<SoftInput
														size="small"
														name={v}
														inputProps={{ maxLength: v === 'CountryCode' ? 2 : null }}
														placeholder={v === 'CountryCode' ? t('account.limit 2 letters') : null}
														onChange={(e) => getInput(v, e, null)}
														value={storeVal[v]}></SoftInput>
												</Grid>
											);
									}
								})}
							</Grid>
							<Typography
								fontSize={16}
								color={'red'}
								sx={{
									display: disable ? undefined : 'none'
								}}>
								{validateEmail(storeVal.Email)
									? t('account.Please fill in all columns')
									: t('account.Email is not valid')}
							</Typography>
						</CardContent>
					) : (
						<CardContent sx={{ m: 2 }}>
							<Typography fontWeight={'bold'} display={action === 'edit' ? 'none' : undefined}>
								{t('account.Create new user')}
							</Typography>
							<Grid container p={2}>
								<Grid item md={6} xs={12} pl={2}>
									<Typography pr={1} fontSize={15} align="left">
										{t('account.Name')}
									</Typography>
									<SoftInput size="small" onChange={(e) => getInput('Name', e, null)} value={userVal.Name}></SoftInput>
								</Grid>
								<Grid item md={6} xs={12} pl={2}>
									<Typography pr={1} fontSize={15} align="left">
										{t('account.Email')}
									</Typography>
									<SoftInput
										size="small"
										onChange={(e) => getInput('Email', e, null)}
										value={userVal.Email}
										disabled={action === 'edit'}></SoftInput>
								</Grid>
								<Grid item md={6} xs={12} pt={3} pl={2}>
									<Typography pr={1} fontSize={15} align="left">
										{t('account.Type')}
									</Typography>
									<Stack direction={'row'}>
										<Autocomplete
											size="small"
											id="combo-box-demo"
											options={userType}
											sx={{ width: { md: 180, xs: 120 } }}
											renderInput={(params) => <TextField {...params} />}
											onChange={(e) => getInput('Type', e)}
											value={userVal.Type}
										/>
										<Typography fontSize={12} pl={1} pr={1}>
											{t('account.TW Staff')}
										</Typography>
										<Checkbox
											checked={userVal.TWStaff}
											onChange={(e) => getInput('TWStaff', e, null)}
											disabled={userVal.Type === 'Owner'}></Checkbox>
									</Stack>
								</Grid>
								<Grid item md={6} xs={12} pt={3} pl={2}>
									<Typography pr={1} fontSize={15} align="left">
										{t('account.Role')}
									</Typography>
									<Autocomplete
										size="small"
										id="combo-box-demo"
										options={role}
										renderInput={(params) => <TextField {...params} />}
										onChange={(e) => getInput('Role', e, null)}
										value={userVal.Role}
										disabled={userVal.Type === 'Owner'}
									/>
								</Grid>
								<Grid item md={12} xs={12} pt={3} pl={2}>
									<Typography pr={1} fontSize={15} align="left">
										{t('account.View Area')}
									</Typography>
									<Stack direction={'row'}>
										<Autocomplete
											multiple
											size="small"
											id="combo-box-demo"
											sx={{ width: { xs: 155, md: 500 } }}
											options={areaList}
											ListboxProps={{ style: { maxHeight: 100, overflow: 'auto' } }}
											renderInput={(params) => <TextField {...params} />}
											onChange={(e, v) => getInput('ViewArea', e, v)}
											value={userVal.ViewArea}
										/>
										<Typography p={1} fontSize={14}>
											{t('account.multiple')}
										</Typography>
									</Stack>
								</Grid>
							</Grid>
							<Typography
								fontSize={16}
								color={'red'}
								sx={{
									display: disable ? undefined : 'none'
								}}>
								{validateEmail(userVal.Email)
									? t('account.Please fill in all columns')
									: t('account.Email is not valid')}
							</Typography>
						</CardContent>
					)}
				</Card>
			</Box>
		</Box>
	);

	function handleBack() {
		switch (activeStep) {
			case 0:
				setType();
				break;
			case 1:
				clearAction();
				break;
			case 2:
				clearAccount();
				break;
			default:
				break;
		}
	}

	return (
		<Box component={'form'} onSubmit={handleSubmit}>
			<Box justifyContent={'space-between'} display={'flex'}>
				<SoftButton onClick={() => handleBack()}>{t('Back')}</SoftButton>
				<SoftButton
					type="submit"
					variant="contained"
					color="success"
					disabled={disable || (action === 'edit' && !account[0])}
					sx={{ display: activeStep === 2 ? undefined : 'none' }}>
					{t('area.Save')}
				</SoftButton>
			</Box>
			<Box textAlign={'center'} pt={2} fontWeight={'bold'}>
				{activeStep === 0
					? t('account.Selet Your Action')
					: action === 'create'
						? t('account.Create New Account')
						: t('account.Edit Account')}
			</Box>
			<Stepper activeStep={activeStep} sx={{ margin: 1 }}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{t('account.' + label)}</StepLabel>
					</Step>
				))}
			</Stepper>
			{activeStep === 0 && SelectImages(actionImages, getAction)}
			{activeStep === 1 && SelectImages(accountImages, secondStep)}
			{activeStep === 2 && UserCard()}
			{activeStep === 3 && (
				<Box textAlign={'center'}>
					<Typography fontSize={18} py={2}>
						{action === 'create' ? t('account.New account created') : t('account.Account updated')}
					</Typography>
					<img src={userCreated} width={'35%'} />
				</Box>
			)}
		</Box>
	);
}
