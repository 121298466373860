import { useEffect, useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SoftBox from '../../components/SoftBox';
import SoftTypography from '../../components/SoftTypography';
import SoftButton from '../../components/SoftButton';
import typography from '../../assets/theme/base/typography';
import SidenavCollapse from './SidenavCollapse';
import SidenavRoot from './SidenavRoot';
import sidenavLogoLabel from './styles/sidenav';
import { useSoftUIController, setMiniSidenav } from '../../context';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function Sidenav({ color, brand, brandName, routes, userSettings, user, userType, light, ...rest }) {
	const { signOut } = useAuthenticator((context) => [context.user]);
	const [controller, dispatch] = useSoftUIController();
	const { miniSidenav, transparentSidenav } = controller;
	const location = useLocation();
	const { pathname } = location;
	const collapseName = pathname.split('/').slice(1)[0];
	const { size } = typography;
	const { t } = useTranslation();
	const closeSidenav = () => setMiniSidenav(dispatch, true);
	const [alignment, setAlignment] = useState(i18n.languages[0]);
	const handleChange = (event, newAlignment) => {
		setAlignment(newAlignment);
	};

	useEffect(() => {
		// A function that sets the mini state of the sidenav.
		function handleMiniSidenav() {
			setMiniSidenav(dispatch, window.innerWidth < 1200);
		}

		/**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
		window.addEventListener('resize', handleMiniSidenav);

		// Call the handleMiniSidenav function to set the state with the initial value.
		handleMiniSidenav();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleMiniSidenav);
	}, [dispatch, location]);

	// Render all the routes from the routes.js (All the visible items on the Sidenav)
	const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href, level }) => {
		let returnValue;
		let types = JSON.parse(JSON.stringify(userType));
		types.splice(types.indexOf('Y_TW'), 1);
		let listOnNav = route !== '/home' ? true : false;
		let show = true;
		const curLvl = userSettings.filter((item) => item.route === route);
		const userName = user.attributes.name;
		let checkKeys = userType.includes('Y_TW') && curLvl[0].TW_View === false ? types : userType;
		checkKeys.map((type) => {
			if (!curLvl[0].permission[type].includes(userName)) {
				show = false;
			}
		});
		if (route && show && listOnNav) {
			if (type === 'collapse') {
				returnValue = href ? (
					<Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
						<SidenavCollapse
							color={color}
							name={name}
							icon={icon}
							active={key === collapseName}
							noCollapse={noCollapse}
						/>
					</Link>
				) : (
					<NavLink to={route} key={key}>
						<SidenavCollapse
							color={color}
							key={key}
							name={t(key + '.' + name)}
							icon={icon}
							active={key === collapseName}
							noCollapse={noCollapse}
							level={level}
							keyName={key}
						/>
					</NavLink>
				);
			} else if (type === 'title') {
				returnValue = (
					<SoftTypography
						key={key}
						display="block"
						variant="caption"
						fontWeight="bold"
						textTransform="uppercase"
						opacity={0.6}
						pl={3}
						mt={2}
						mb={1}
						ml={1}>
						{title}
					</SoftTypography>
				);
			} else if (type === 'divider') {
				returnValue = <Divider key={key} />;
			}
		}

		return returnValue;
	});

	return (
		<SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
			<SoftBox pt={3} pb={1} px={4} textAlign="center">
				<SoftBox
					display={{ xs: 'block', xl: 'none' }}
					position="absolute"
					top={0}
					right={0}
					p={1.625}
					onClick={closeSidenav}
					sx={{ cursor: 'pointer' }}>
					<SoftTypography variant="h6" color="secondary">
						<Icon sx={{ fontWeight: 'bold' }}>close</Icon>
					</SoftTypography>
				</SoftBox>
				<SoftBox component={NavLink} to="/" display="flex" alignItems="center">
					{brand && <SoftBox component="img" src={brand} alt="MACU TEA" width="2rem" />}
					<SoftBox width={!brandName && '100%'} sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
						<SoftTypography component="h6" variant="button" fontWeight="medium">
							{brandName}
						</SoftTypography>
					</SoftBox>
				</SoftBox>
			</SoftBox>
			<Divider />
			<List>{renderRoutes}</List>
			<SoftBox pt={2} my={2} mx={2} mt="auto">
				<SoftBox my={2} mt="auto">
					<ToggleButtonGroup
						color="standard"
						value={alignment}
						exclusive
						fullWidth
						onChange={handleChange}
						aria-label="Lang">
						<ToggleButton value="en" onClick={() => i18n.changeLanguage('en')}>
							EN
						</ToggleButton>
						<ToggleButton value="zh" onClick={() => i18n.changeLanguage('zh')}>
							中文
						</ToggleButton>
					</ToggleButtonGroup>
				</SoftBox>
				<SoftButton size="large" onClick={signOut} fullWidth>
					<Icon
						sx={({ palette: { dark, white } }) => ({
							color: light ? white.main : dark.main
						})}>
						logout
					</Icon>
					{t('Signout')}
				</SoftButton>
				<SoftBox
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexWrap="wrap"
					color="text"
					fontSize={size.sm}
					px={1.5}
					mt={2}>
					&copy; {new Date().getFullYear()} MACU TEA
				</SoftBox>
			</SoftBox>
			{/* <SoftBox pt={2} my={2} mx={2} mt="auto">
        <SidenavCard />
        <SoftBox mt={2}>
          <SoftButton
            component="a"
            href="https://creative-tim.com/product/soft-ui-dashboard-pro-react"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            color={color}
            fullWidth
          >
            upgrade to pro
          </SoftButton>
        </SoftBox>
      </SoftBox> */}
		</SidenavRoot>
	);
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
	color: 'info',
	light: false,
	brand: ''
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
	color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
	brand: PropTypes.string,
	light: PropTypes.bool,
	brandName: PropTypes.string.isRequired,
	routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Sidenav;
