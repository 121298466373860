import { Storage } from '@aws-amplify/storage';

export const downloadWithUrl = async (url, filename) => {
	fetch(url)
		.then((response) => response.blob())
		.then((blob) => {
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = filename;
			link.click();
		})
		.catch(console.error);
};

export const getAllFiles = async (folder) => {
	const res = await Storage.list(folder, { pageSize: 1000 });
	const { results } = res;
	const key = results.filter((r) => r.size > 0)[0]; // there'll be only one file in this folder, get key for the file
	return key.key;
};

export const uploadFile = async (file) => {
	const name = file.name;
	try {
		const res = await Storage.put(`abnormal_quantity_report/${name}`, file);
		return res.key;
	} catch (error) {
		console.log('🚀 ~ uploadFile ~ error:', error);
	}
};

export const deleteFile = async (key) => {
	await Storage.remove(key);
};
