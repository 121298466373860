/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOverseasStore = /* GraphQL */ `
  query GetOverseasStore($id: ID!) {
    getOverseasStore(id: $id) {
      id
      Name
      Country
      Currency
      Company
      ContractNo
      Email
      Store
      Long_lat
      Address
      Phone
      Status
      Region
      Owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOverseasStores = /* GraphQL */ `
  query ListOverseasStores(
    $filter: ModelOverseasStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverseasStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Country
        Currency
        Company
        ContractNo
        Email
        Store
        Long_lat
        Address
        Phone
        Status
        Region
        Owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOverseasStores = /* GraphQL */ `
  query SyncOverseasStores(
    $filter: ModelOverseasStoreFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOverseasStores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Country
        Currency
        Company
        ContractNo
        Email
        Store
        Long_lat
        Address
        Phone
        Status
        Region
        Owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOverseasShipping = /* GraphQL */ `
  query GetOverseasShipping($id: ID!) {
    getOverseasShipping(id: $id) {
      id
      Product
      Category
      Code
      ZH
      Size
      Expiry
      Unit
      Note
      HSCode
      Brand
      Material
      ShipFrom
      Area
      Price
      Moq
      NW
      GW
      CartonSize
      Img
      Availability
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOverseasShippings = /* GraphQL */ `
  query ListOverseasShippings(
    $filter: ModelOverseasShippingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverseasShippings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Product
        Category
        Code
        ZH
        Size
        Expiry
        Unit
        Note
        HSCode
        Brand
        Material
        ShipFrom
        Area
        Price
        Moq
        NW
        GW
        CartonSize
        Img
        Availability
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOverseasShippings = /* GraphQL */ `
  query SyncOverseasShippings(
    $filter: ModelOverseasShippingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOverseasShippings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Product
        Category
        Code
        ZH
        Size
        Expiry
        Unit
        Note
        HSCode
        Brand
        Material
        ShipFrom
        Area
        Price
        Moq
        NW
        GW
        CartonSize
        Img
        Availability
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOverseasOrder = /* GraphQL */ `
  query GetOverseasOrder($id: ID!) {
    getOverseasOrder(id: $id) {
      id
      Code
      Name
      Qty
      Amount
      Status
      OrderType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOverseasOrders = /* GraphQL */ `
  query ListOverseasOrders(
    $filter: ModelOverseasOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverseasOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Code
        Name
        Qty
        Amount
        Status
        OrderType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOverseasOrders = /* GraphQL */ `
  query SyncOverseasOrders(
    $filter: ModelOverseasOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOverseasOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Code
        Name
        Qty
        Amount
        Status
        OrderType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuditForm = /* GraphQL */ `
  query GetAuditForm($id: ID!) {
    getAuditForm(id: $id) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      ContentFrom
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAuditForms = /* GraphQL */ `
  query ListAuditForms(
    $filter: ModelAuditFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Date
        year
        month
        Store
        store_id
        supervisor_id
        Model
        Type
        Content
        Final
        Suggestion
        Improvement
        Status
        Author
        signature
        staffSign
        ContentFrom
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuditForms = /* GraphQL */ `
  query SyncAuditForms(
    $filter: ModelAuditFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuditForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Date
        year
        month
        Store
        store_id
        supervisor_id
        Model
        Type
        Content
        Final
        Suggestion
        Improvement
        Status
        Author
        signature
        staffSign
        ContentFrom
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const auditByMonth = /* GraphQL */ `
  query AuditByMonth(
    $month: String!
    $year: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    auditByMonth(
      month: $month
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Date
        year
        month
        Store
        store_id
        supervisor_id
        Model
        Type
        Content
        Final
        Suggestion
        Improvement
        Status
        Author
        signature
        staffSign
        ContentFrom
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuditContentTW = /* GraphQL */ `
  query GetAuditContentTW($id: ID!) {
    getAuditContentTW(id: $id) {
      id
      Category
      Partition
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAuditContentTWS = /* GraphQL */ `
  query ListAuditContentTWS(
    $filter: ModelAuditContentTWFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditContentTWS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Category
        Partition
        Number
        Content
        ZH
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuditContentTWS = /* GraphQL */ `
  query SyncAuditContentTWS(
    $filter: ModelAuditContentTWFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuditContentTWS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Category
        Partition
        Number
        Content
        ZH
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuditContentUS = /* GraphQL */ `
  query GetAuditContentUS($id: ID!) {
    getAuditContentUS(id: $id) {
      id
      Category
      Partition
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAuditContentuses = /* GraphQL */ `
  query ListAuditContentuses(
    $filter: ModelAuditContentUSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditContentuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Category
        Partition
        Number
        Content
        ZH
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuditContentuses = /* GraphQL */ `
  query SyncAuditContentuses(
    $filter: ModelAuditContentUSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuditContentuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Category
        Partition
        Number
        Content
        ZH
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      Order_time
      CustomerID
      Order_no
      Firstname
      Lastname
      Store_id
      Final_price
      Email
      Phone
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Order_time
        CustomerID
        Order_no
        Firstname
        Lastname
        Store_id
        Final_price
        Email
        Phone
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Order_time
        CustomerID
        Order_no
        Firstname
        Lastname
        Store_id
        Final_price
        Email
        Phone
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const customerByDate = /* GraphQL */ `
  query CustomerByDate(
    $Order_date: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByDate(
      Order_date: $Order_date
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        CustomerID
        Order_no
        Firstname
        Lastname
        Store_id
        Final_price
        Email
        Phone
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      Order_time
      Order_no
      Store_id
      Quantity
      Discount_amount
      Final_price
      Tax_amount
      Tip_amount
      Status
      Order_type
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Quantity
        Discount_amount
        Final_price
        Tax_amount
        Tip_amount
        Status
        Order_type
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Quantity
        Discount_amount
        Final_price
        Tax_amount
        Tip_amount
        Status
        Order_type
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orderByYear = /* GraphQL */ `
  query OrderByYear(
    $Year: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByYear(
      Year: $Year
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Quantity
        Discount_amount
        Final_price
        Tax_amount
        Tip_amount
        Status
        Order_type
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orderByMonth = /* GraphQL */ `
  query OrderByMonth(
    $Month: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByMonth(
      Month: $Month
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Quantity
        Discount_amount
        Final_price
        Tax_amount
        Tip_amount
        Status
        Order_type
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orderByDate = /* GraphQL */ `
  query OrderByDate(
    $Order_date: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByDate(
      Order_date: $Order_date
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Quantity
        Discount_amount
        Final_price
        Tax_amount
        Tip_amount
        Status
        Order_type
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPay = /* GraphQL */ `
  query GetPay($id: ID!) {
    getPay(id: $id) {
      id
      Order_time
      Order_no
      Pay_type
      Card_type
      Store_id
      Final_price
      Tip_amount
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPays = /* GraphQL */ `
  query ListPays(
    $filter: ModelPayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Order_time
        Order_no
        Pay_type
        Card_type
        Store_id
        Final_price
        Tip_amount
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPays = /* GraphQL */ `
  query SyncPays(
    $filter: ModelPayFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPays(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Order_time
        Order_no
        Pay_type
        Card_type
        Store_id
        Final_price
        Tip_amount
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const payByYear = /* GraphQL */ `
  query PayByYear(
    $Year: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payByYear(
      Year: $Year
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        Order_no
        Pay_type
        Card_type
        Store_id
        Final_price
        Tip_amount
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const payByMonth = /* GraphQL */ `
  query PayByMonth(
    $Month: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payByMonth(
      Month: $Month
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        Order_no
        Pay_type
        Card_type
        Store_id
        Final_price
        Tip_amount
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const payByDate = /* GraphQL */ `
  query PayByDate(
    $Order_date: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payByDate(
      Order_date: $Order_date
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        Order_no
        Pay_type
        Card_type
        Store_id
        Final_price
        Tip_amount
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      Order_time
      Order_no
      Store_id
      Product
      Quantity
      Item_price
      Modifier_price
      Final_price
      Ice
      Sugar
      Topping
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Product
        Quantity
        Item_price
        Modifier_price
        Final_price
        Ice
        Sugar
        Topping
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Product
        Quantity
        Item_price
        Modifier_price
        Final_price
        Ice
        Sugar
        Topping
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productByYear = /* GraphQL */ `
  query ProductByYear(
    $Year: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByYear(
      Year: $Year
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Product
        Quantity
        Item_price
        Modifier_price
        Final_price
        Ice
        Sugar
        Topping
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productByMonth = /* GraphQL */ `
  query ProductByMonth(
    $Month: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByMonth(
      Month: $Month
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Product
        Quantity
        Item_price
        Modifier_price
        Final_price
        Ice
        Sugar
        Topping
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productByDate = /* GraphQL */ `
  query ProductByDate(
    $Order_date: String!
    $Store_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByDate(
      Order_date: $Order_date
      Store_id: $Store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Order_time
        Order_no
        Store_id
        Product
        Quantity
        Item_price
        Modifier_price
        Final_price
        Ice
        Sugar
        Topping
        Year
        Month
        Order_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPrepCheckForm = /* GraphQL */ `
  query GetPrepCheckForm($id: ID!) {
    getPrepCheckForm(id: $id) {
      id
      S_Date
      E_Date
      Year
      Month
      Store
      Supervisor
      Content
      Suggestion
      Status
      Author
      OwnerSign
      StaffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPrepCheckForms = /* GraphQL */ `
  query ListPrepCheckForms(
    $filter: ModelPrepCheckFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrepCheckForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        S_Date
        E_Date
        Year
        Month
        Store
        Supervisor
        Content
        Suggestion
        Status
        Author
        OwnerSign
        StaffSign
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPrepCheckForms = /* GraphQL */ `
  query SyncPrepCheckForms(
    $filter: ModelPrepCheckFormFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrepCheckForms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        S_Date
        E_Date
        Year
        Month
        Store
        Supervisor
        Content
        Suggestion
        Status
        Author
        OwnerSign
        StaffSign
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const prepcheckByMonth = /* GraphQL */ `
  query PrepcheckByMonth(
    $Month: String!
    $Year: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPrepCheckFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prepcheckByMonth(
      Month: $Month
      Year: $Year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        S_Date
        E_Date
        Year
        Month
        Store
        Supervisor
        Content
        Suggestion
        Status
        Author
        OwnerSign
        StaffSign
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPrepCheckContent = /* GraphQL */ `
  query GetPrepCheckContent($id: ID!) {
    getPrepCheckContent(id: $id) {
      id
      Category
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPrepCheckContents = /* GraphQL */ `
  query ListPrepCheckContents(
    $filter: ModelPrepCheckContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrepCheckContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Category
        Number
        Content
        ZH
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPrepCheckContents = /* GraphQL */ `
  query SyncPrepCheckContents(
    $filter: ModelPrepCheckContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrepCheckContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Category
        Number
        Content
        ZH
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPermission = /* GraphQL */ `
  query GetPermission($id: ID!) {
    getPermission(id: $id) {
      id
      manual
      route
      permission
      TW_View
      section
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        manual
        route
        permission
        TW_View
        section
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPermissions = /* GraphQL */ `
  query SyncPermissions(
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        manual
        route
        permission
        TW_View
        section
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
