import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TextField } from '@aws-amplify/ui-react';
import Dialog from '../../components/Dialog';
import SoftBox from '../../components/SoftBox';
import SoftButton from '../../components/SoftButton';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export default function Viewtable({ getForms, sortedRows, storeVal, columns, hasDialog, formRoute, isStaff, loading }) {
	const navigate = useNavigate();
	const formType = formRoute.split('/')[2];
	const { t } = useTranslation();
	// const from = moment().subtract(1, 'month').format('L');
	// const to = moment().format('L');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('');
	const [searchItem, setsearchItem] = useState('');
	const [dialog, setDialog] = useState(false);
	const [fromDate, setFromDate] = useState(moment().subtract(1, 'month'));
	const [toDate, setToDate] = useState(moment());
	const [endHandle, setEndHandle] = useState(false);

	const handleCancel = () => setDialog(!dialog);
	const handleChangePage = (event, newPage) => setPage(newPage);
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleSearchChange = (event) => setsearchItem(event.target.value);

	const getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => descendingComparator(a[orderBy], b[orderBy])
			: (a, b) => -descendingComparator(a[orderBy], b[orderBy]);
	};

	const descendingComparator = (a, b) => {
		if (b < a) return -1;
		if (b > a) return 1;
		return 0;
	};

	const handleSort = (columnId) => {
		const isAsc = orderBy === columnId && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(columnId);
	};

	const sortedItem = sortedRows.sort(getComparator(order, orderBy));
	const filteredItems = sortedItem.filter((item) => {
		let ignore = new RegExp(searchItem, 'i'); //ignore case-sensitive
		let translatedStatus = t('audit.' + item.Status);
		return (
			ignore.test(item.Store) ||
			(formType === 'prep' ? item.S_Date.includes(searchItem) : item.Date.includes(searchItem)) ||
			ignore.test(translatedStatus)
		);
	});

	useEffect(() => {
		getForms(fromDate, toDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const DContent = () => (
		<Grid container fontSize={16}>
			<Grid item xs={12}>
				<Divider></Divider>
			</Grid>
			<Grid item xs={4} fontWeight="bold">
				{t('audit.Store')}
			</Grid>
			<Grid item xs={4} fontWeight="bold">
				{t('audit.Forms')}
			</Grid>
			<Grid item xs={4} fontWeight="bold">
				{t('audit.Average Final')}
			</Grid>
			{storeVal.map((s, index) => (
				<React.Fragment key={index + s.store + s}>
					<Grid item xs={4}>
						{s.store}
					</Grid>
					<Grid item xs={4}>
						{s.count}
					</Grid>
					<Grid item xs={4}>
						{s.score}
					</Grid>
				</React.Fragment>
			))}
		</Grid>
	);

	const changePage = (state) => navigate(formRoute, { state: state });

	return (
		<>
			<Grid container>
				<Grid item xs={12} md={12} pt={1}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							disableFuture
							label="Start"
							value={fromDate}
							onChange={(date) => setFromDate(date)}
							onClose={() => setEndHandle(true)}
							sx={{ width: { md: 210, xs: 135 }, paddingRight: 1 }}
						/>
						<DatePicker
							disableFuture
							label="End"
							value={toDate}
							onChange={(date) => setToDate(date)}
							onClose={() => setEndHandle(false)}
							onOpen={() => setEndHandle(true)}
							open={endHandle}
							minDate={fromDate}
							sx={{ width: { md: 210, xs: 135 }, pr: 1 }}
						/>
					</LocalizationProvider>
					<SoftButton onClick={() => getForms(fromDate, toDate)} style={{ border: '1px solid #cdcdcd' }}>
						<SearchIcon />
					</SoftButton>
				</Grid>
				<Grid item xs={12} md={6} pr={2}>
					<TextField
						variant="outlined"
						value={searchItem}
						onChange={handleSearchChange}
						placeholder={t('audit.Search Store, Date, Status')}
					/>
				</Grid>
				<Grid item xs={12} md={6} pt={2} textAlign={{ md: 'right', xs: 'center' }}>
					<SoftButton
						sx={{ marginRight: 2 }}
						color="primary"
						size="small"
						variant="outlined"
						onClick={() => {
							changePage({ action: 'create' });
						}}>
						{t('audit.new form')}
					</SoftButton>
					{hasDialog && isStaff && (
						<SoftButton color="dark" size="small" variant="outlined" onClick={() => setDialog(true)}>
							{t('audit.overall')}
						</SoftButton>
					)}
				</Grid>
			</Grid>
			{hasDialog && isStaff && (
				<Dialog
					dialog={dialog}
					title={t(`audit.Store Counts in Current Period`) + ' ' + storeVal.length}
					content={DContent()}
					cancel={'退出'}
					handleClose={handleCancel}
					handleSubmit={handleCancel}
					showBtn={true}
					disable={true}
					fullWidth={true}
					showSub={false}
				/>
			)}
			<SoftBox pt={2} pb={6}>
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<TableContainer sx={{ maxHeight: 540 }}>
						<Table
							stickyHeader
							aria-label="sticky table"
							sx={{
								'& .MuiTableHead-root': {
									display: 'table-header-group'
								},
								'& .css-jr1qcz-MuiTableCell-root': {
									backgroundColor: 'lightGrey'
								}
							}}>
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											key={'tableRow' + column.field}
											align={'center'}
											style={{ minWidth: column.width, fontWeight: 'bold' }}>
											<TableSortLabel
												active={orderBy === column.field}
												direction={orderBy === column.field ? order : 'asc'}
												style={{ fontSize: 14 }}
												onClick={() => {
													if (column.field !== 'Action') {
														handleSort(column.field);
													}
												}}
												sx={{
													'& .css-1oqyonh-MuiSvgIcon-root-MuiTableSortLabel-icon': {
														opacity: '100 !important'
													}
												}}>
												{t(`${formType}.${column.field}`)}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{loading ? (
									<>
										<TableRow>
											<TableCell colSpan={10}>
												<Skeleton animation="wave" />
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={10}>
												<Skeleton animation="wave" />
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={10}>
												<Skeleton animation="wave" />
											</TableCell>
										</TableRow>
									</>
								) : filteredItems.length > 0 ? (
									filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
										return (
											<TableRow hover role="checkbox" tabIndex={-1} key={'tabIndex' + index + row.Final}>
												{columns.map((column) => {
													let value = row[column.field];
													if (value === null || value === 'n/a') {
														value = 'N/A';
													}
													if (column.field === 'Action') {
														return (
															<TableCell key={'Action' + index + row.Final + row.Store}>
																<Tooltip title={t('online_form.view')}>
																	<IconButton
																		aria-label="view"
																		color="error"
																		onClick={() => {
																			changePage({ route: row, action: 'view' });
																		}}>
																		<RemoveRedEyeIcon />
																	</IconButton>
																</Tooltip>
																<IconButton
																	aria-label="edit"
																	sx={{ color: row.Status === 'submitted' ? 'disabled' : '#039c19' }}
																	onClick={() => {
																		changePage({ route: row, action: 'edit' });
																	}}
																	disabled={row.Status === 'submitted' ? true : false}>
																	<Tooltip title={t('online_form.edit')}>
																		<EditIcon />
																	</Tooltip>
																</IconButton>
															</TableCell>
														);
													} else if (column.field === 'Status') {
														return (
															<TableCell
																key={'tableCell' + index + Math.random()}
																align={'center'}
																sx={{ fontSize: 14 }}>
																{t('audit.' + value)}
															</TableCell>
														);
													} else if (column.field === 'Type' || column.field === 'Model') {
														return (
															<TableCell
																key={'tableCell' + index + Math.random()}
																align={'center'}
																sx={{ fontSize: 14 }}>
																{value ? t('auditForm.' + value) : null}
															</TableCell>
														);
													} else {
														return (
															<TableCell
																key={'tableCell' + index + Math.random()}
																align={'center'}
																sx={{ fontSize: 14 }}>
																{value}
															</TableCell>
														);
													}
												})}
											</TableRow>
										);
									})
								) : (
									<TableRow sx={{ height: '30vh' }}>
										<TableCell colSpan={10} align={'center'} sx={{ fontSize: 14 }}>
											{t('audit.No result')}
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						// rowsPerPageOptions={[10]}
						component="div"
						count={filteredItems.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						sx={{
							'& .css-1ui3wbn-MuiInputBase-root-MuiTablePagination-select': {
								width: 'fit-content !important'
							}
						}}
					/>
				</Paper>
			</SoftBox>
		</>
	);
}
