import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SoftButton from '../../components/SoftButton';
import routes from '../../routes';
import { dashboard, tworder, usorder, inventory, onlineForm, account } from '../../images/home/homeImages';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Home({ userSettings, userType, userName }) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const images = {
		dashboard: dashboard,
		tworder: tworder,
		usorder: usorder,
		inventory: inventory,
		online_form: onlineForm,
		account: account
	};
	const types = JSON.parse(JSON.stringify(userType));
	types.splice(types.indexOf('Y_TW'), 1);

	return (
		<Box>
			<Grid container>
				{routes.map((r, index) => {
					const listOnNav = r.route !== '/home' ? true : false;
					let show = true;
					const curLvl = userSettings.filter((item) => item.route === r.route);
					let checkKeys = userType.includes('Y_TW') && curLvl[0].TW_View === false ? types : userType;
					checkKeys.map((type) => {
						if (!curLvl[0].permission[type].includes(userName)) {
							show = false;
						}
					});
					if (r.route && show && listOnNav) {
						return (
							<Grid item md={4} key={r.name + index}>
								<Card sx={{ width: 300, height: 200, padding: 2, margin: 2 }}>
									<CardContent>
										<SoftButton
											onClick={() => navigate(r.route)}
											variant={'text'}
											style={{ position: 'relative', overflow: 'hidden', height: 150, width: 200, color: '#ce3719' }}>
											<Stack
												direction={'column'}
												justifyContent={'center'}
												alignItems={'center'}
												style={{ position: 'relative', zIndex: 1 }}>
												<Typography fontWeight={'bold'} color="#181818" bgcolor={'#ffffffbd'} fontSize={16}>
													{t(r.key + '.' + r.name)}
												</Typography>
											</Stack>
											<img
												src={images[r.key]}
												alt={r.key}
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													objectFit: 'cover',
													zIndex: 0,
													opacity: 0.5
												}}
											/>
										</SoftButton>
									</CardContent>
								</Card>
							</Grid>
						);
					}
				})}
			</Grid>
		</Box>
	);
}
