import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Icon from '@mui/material/Icon';
import SoftBox from '../../components/SoftBox';
import SoftTypography from '../../components/SoftTypography';

function Breadcrumbs({ icon, title, label, route, light }) {
	const routes = label.slice(0, -1);
	const { t } = useTranslation();

	return (
		<SoftBox mr={{ xs: 0, xl: 8 }}>
			<MuiBreadcrumbs
				sx={{
					'& .MuiBreadcrumbs-separator': {
						color: ({ palette: { white, grey } }) => (light ? white.main : grey[600])
					}
				}}>
				<Link to="/">
					<SoftTypography
						component="span"
						variant="body2"
						color={light ? 'white' : 'dark'}
						opacity={light ? 0.8 : 0.5}
						sx={{ lineHeight: 0 }}>
						<Icon>{icon}</Icon>
					</SoftTypography>
				</Link>
				{routes.map((el, index) => (
					<Link to={route} label={el} key={index}>
						<SoftTypography
							component="span"
							variant="button"
							fontWeight="regular"
							// textTransform="capitalize"
							color={light ? 'white' : 'dark'}
							opacity={light ? 0.8 : 0.5}
							sx={{ lineHeight: 0 }}>
							{t(el + '.' + el.charAt(0).toUpperCase() + el.toLowerCase().slice(1))}
						</SoftTypography>
					</Link>
				))}
				<SoftTypography
					variant="button"
					fontWeight="regular"
					// textTransform="capitalize"
					color={light ? 'white' : 'dark'}
					sx={{ lineHeight: 0, display: title === '' ? 'none' : undefined }}>
					{t(title + '.' + title.charAt(0).toUpperCase() + title.toLowerCase().slice(1))}
				</SoftTypography>
			</MuiBreadcrumbs>
			{/* <SoftTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </SoftTypography> */}
		</SoftBox>
	);
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
	light: false
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
	icon: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
	route: PropTypes.string.isRequired,
	light: PropTypes.bool
};

export default Breadcrumbs;
