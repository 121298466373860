const listItem = {
	defaultProps: {
		disableGutters: true
	},

	styleOverrides: {
		root: {
			paddingTop: 0,
			paddingBottom: 0
		}
	}
};

export default listItem;
