import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getAllPrepLists } from '../../../scripts';
import Viewtable from '../viewtable';
import SoftButton from '../../../components/SoftButton';
import Box from '@mui/material/Box';

export default function Prepcheck() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const userStore = useSelector((state) => state.user.userStore);
	const [sortedRows, setSortedRows] = useState([]);
	// const [storeVal, setStoreVal] = useState([]);
	const [loading, setLoading] = useState(true);
	const formRoute = '/online_form/prep/edit';
	const columns = [
		{ field: 'Action', headerName: '操作', width: 120 },
		{ field: 'Status', headerName: '狀態', width: 90 },
		{ field: 'Store', headerName: '門市', width: 120 },
		{ field: 'S_Date', headerName: '開始日期', width: 120 },
		{ field: 'E_Date', headerName: '結束日期', width: 120 },
		{ field: 'Suggestion', headerName: '本次建議事項', width: 150 },
		// { field: 'Improvement', headerName: '待改善事項', width: 150 },
		{ field: 'Supervisor', headerName: '填表人', width: 100 }
	];

	const getForms = async (from, to) => {
		setLoading(true);
		getAllPrepLists(userStore, moment(from).format('MM/DD/YYYY'), moment(to).format('MM/DD/YYYY')).then((res) => {
			let form = res;
			form.map((f) => (f.Status = f.Status[f.Status.length - 1].split('@')[0]));
			form.sort((a, b) => {
				let store = a['Store'].localeCompare(b['Store']);
				return store !== 0 ? store : a['S_Date'].localeCompare(b['S_Date']);
			});
			setSortedRows(form);
			setLoading(false);
		});
	};

	return (
		<Box>
			<Box pb={2}>
				<SoftButton variant="contained" color="light" size="small" onClick={() => navigate('/online_form')}>
					{t('Back')}
				</SoftButton>
			</Box>
			<Viewtable
				getForms={getForms}
				sortedRows={sortedRows}
				// storeVal={storeVal}
				columns={columns}
				hasDialog={false}
				formRoute={formRoute}
				loading={loading}
			/>
		</Box>
	);
}
