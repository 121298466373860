import colors from '../base/colors';
import borders from '../base/borders';
import rgba from '../functions/rgba';
import pxToRem from '../functions/pxToRem';

const { white } = colors;
const { borderRadius } = borders;

const sidenav = {
	styleOverrides: {
		root: {
			width: pxToRem(250),
			whiteSpace: 'nowrap',
			border: 'none'
		},

		paper: {
			width: pxToRem(250),
			backgroundColor: rgba(white.main, 0.8),
			backdropFilter: `saturate(200%) blur(${pxToRem(30)})`,
			height: `calc(100vh - ${pxToRem(32)})`,
			margin: pxToRem(16),
			borderRadius: borderRadius.xl,
			border: 'none'
		},

		paperAnchorDockedLeft: {
			borderRight: 'none'
		}
	}
};

export default sidenav;
