import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { downloadWithUrl, getAllFiles, uploadFile, deleteFile } from '../../scripts/utils';
import Dialog from '../../components/Dialog';
import Audit from './audit/auditview';
import AuditForm from './audit/auditform';
import Prepcheck from './prepcheck/prepcheckview';
import PrepCheckForm from './prepcheck/prepcheckfom';
import SoftButton from '../../components/SoftButton';
import { audit, store, report } from '../../images/onlineForm/onlineFormImages';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';

function Onlineform() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { userSettings: permission, userZone, userName } = useSelector((state) => state.user);
	const [open, setOpen] = useState(false);
	const [selectedFile, setSelectedFile] = useState();
	const [abnormalKey, setAbnormalKey] = useState();
	const forms = [
		{ title: 'abnormal report', image: report, route: 'quantity' },
		{ title: 'Audit', image: audit, route: '/online_form/audit' },
		{ title: 'PrepCheck', image: store, route: '/online_form/prep' }
	];

	const VisuallyHiddenInput = styled('input')({
		clip: 'rect(0 0 0 0)',
		clipPath: 'inset(50%)',
		height: 1,
		overflow: 'hidden',
		position: 'absolute',
		bottom: 0,
		left: 0,
		whiteSpace: 'nowrap',
		width: 1
	});

	useEffect(() => {
		getKey();
	}, []);

	const getKey = async () => {
		const key = await getAllFiles('abnormal_quantity_report/');
		setAbnormalKey(key);
	};

	const downloadReport = async () => {
		const name = abnormalKey.replaceAll(' ', '+');
		downloadWithUrl(
			`https://macuglobal82751-staging.s3.ap-southeast-1.amazonaws.com/public/${encodeURI(decodeURI(name))}`,
			`${abnormalKey.split('/')[1].replaceAll('_', ' ')}`
		);
	};

	const selectUpload = (file) => {
		const files = Array.from(file);
		setSelectedFile(files[0]);
		setOpen(true);
	};

	const handleUpload = async () => {
		deleteFile(abnormalKey);
		const newKey = await uploadFile(selectedFile);
		setAbnormalKey(newKey);
		setOpen(false);
	};

	return (
		<Box>
			<Grid container>
				{forms.map((val, index) => {
					let curRoute = permission.filter((f) => f.route === val.route)[0];
					let zone = userZone.filter((i) => i !== 'Y_TW')[0]; //remove 'Y_TW'
					let visible = true;
					if (curRoute && !curRoute.permission[zone].includes(userName)) {
						visible = false;
					}
					return (
						visible && (
							<Grid item md={4} xs={12} key={val.title + index}>
								<Card
									sx={{
										width: '80%',
										height: 165,
										pb: val.route === 'quantity' ? 0.8 : 3,
										pt: val.route === 'quantity' ? 1 : 3,
										margin: 2
									}}>
									<CardContent>
										<Stack direction={'column'}>
											{val.route === 'quantity' && <DownloadIcon style={{ size: 'small', color: '#55c924' }} />}
											<SoftButton
												onClick={() => (val.route === 'quantity' ? downloadReport() : navigate(val.route))}
												variant={'text'}
												style={{
													position: 'relative',
													overflow: 'hidden',
													height: 78,
													width: '100%',
													color: '#ce3719'
												}}
												disabled={val.title === 'pending'}>
												<Stack
													direction={'column'}
													justifyContent={'center'}
													alignItems={'center'}
													style={{ position: 'relative', zIndex: 1 }}>
													<Typography fontWeight={'bold'} color="#181818" bgcolor={'#ffffffbd'} fontSize={16}>
														{val.title === 'pending' ? '' : t('online_form.' + val.title)}
													</Typography>
												</Stack>
												<img
													src={val.image}
													alt={val.title}
													style={{
														position: 'absolute',
														top: 0,
														left: 0,
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														zIndex: 0,
														opacity: 0.65
													}}
												/>
											</SoftButton>
											{val.route === 'quantity' && userZone.includes('Z_Manager') && (
												<Box py={1} textAlign={'center'}>
													<SoftButton component="label" size="small" color="light">
														<UploadIcon />
														&nbsp;{t('online_form.replace')}
														<VisuallyHiddenInput type="file" onChange={(event) => selectUpload(event.target.files)} />
													</SoftButton>
												</Box>
											)}
										</Stack>
									</CardContent>
								</Card>
							</Grid>
						)
					);
				})}
			</Grid>
			<Dialog
				dialog={open}
				title={t(`online_form.going to upload`)}
				content={`${selectedFile && selectedFile.name}?`}
				cancel={t('Discard')}
				submit={t('Yes')}
				handleClose={() => setOpen(false)}
				handleSubmit={() => handleUpload()}
				showBtn={true}
				disable={false}
				fullWidth={true}
				showSub={true}
			/>
		</Box>
	);
}

export { Onlineform, Audit, AuditForm, Prepcheck, PrepCheckForm };
