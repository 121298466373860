import { View, Image, useTheme } from '@aws-amplify/ui-react';
import logo from '../../../assets/images/Logo_text.svg';

export default function Header() {
	const { tokens } = useTheme();

	return (
		<View textAlign="center" padding={tokens.space.large}>
			<Image
				alt="MACU TEA"
				src={logo}
				// width={tokens.fontSizes.lg}
			/>
		</View>
	);
}
