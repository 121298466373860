import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import SoftButton from '../../components/SoftButton/index';
import './comp.css';

export default function Signature({ disable, signURL, handleSignatureDone }) {
	const { t } = useTranslation();
	const signRef = useRef([]);
	signRef.current = [];
	const [signature, setSignature] = useState(null);

	const handleSignatureEnd = () => setSignature(signRef.current[0].toDataURL());

	const clearSignature = () => {
		if (signRef.current[0]) signRef.current[0].clear();
		setSignature(null);
		handleSignatureDone(null);
	};

	const addToRefs = (el) => {
		if (el && !signRef.current.includes(el)) signRef.current.push(el);
	};

	return (
		<>
			{signURL ? (
				<img src={signURL} alt="signature" className="signImg" />
			) : (
				<SignatureCanvas
					penColor="black"
					canvasProps={{ className: 'signature' }}
					ref={addToRefs}
					onEnd={() => handleSignatureEnd()}
				/>
			)}
			<div style={{ marginBottom: 2, display: disable ? 'none' : 'flex' }}>
				<SoftButton
					variant="outlined"
					color="dark"
					onClick={clearSignature}
					disabled={signature === null && signURL === null}>
					{t('prep.Clear Signature')}
				</SoftButton>
				<SoftButton
					variant="contained"
					color="primary"
					sx={{ marginLeft: 2, display: signURL ? 'none' : undefined }}
					onClick={() => handleSignatureDone(signature)}
					disabled={signature === null}>
					{t('prep.Confirm Signature')}
				</SoftButton>
			</div>
		</>
	);
}
