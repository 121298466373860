import pxToRem from '../functions/pxToRem';

const icon = {
	defaultProps: {
		baseClassName: 'material-icons-round',
		fontSize: 'inherit'
	},

	styleOverrides: {
		fontSizeInherit: {
			fontSize: 'inherit !important'
		},

		fontSizeSmall: {
			fontSize: `${pxToRem(20)} !important`
		},

		fontSizeLarge: {
			fontSize: `${pxToRem(36)} !important`
		}
	}
};

export default icon;
