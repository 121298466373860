import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SoftButton from '../../components/SoftButton';
import { CreateUser } from './create';
import Permission from './permission';
import create from '../../images/account/createUser.svg';
import settings from '../../images/account/settings.svg';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Account() {
	const { t } = useTranslation();
	const [type, setType] = useState();
	const action = [
		{ title: 'Permission Settings', image: settings, route: <Permission setType={setType} /> },
		{ title: 'Create/ Edit Account', image: create, route: <CreateUser setType={setType} /> }
	];

	return (
		<Box m={2}>
			{type ? (
				type
			) : (
				<Grid container>
					{action.map((val, index) => (
						<Grid item md={4} xs={12} key={val.title + index} textAlign={'center'} p={2}>
							<Card style={{ width: '100%', height: '80%', padding: 1, margin: 2 }}>
								<CardContent>
									<SoftButton
										onClick={() => setType(val.route)}
										variant={'text'}
										style={{ position: 'relative', overflow: 'hidden', height: 250, width: '100%', color: '#ce3719' }}
										disabled={val.title === 'pending'}>
										<Stack
											direction={'column'}
											justifyContent={'center'}
											alignItems={'center'}
											style={{ position: 'relative', zIndex: 1 }}>
											<Typography fontWeight={'bold'} color="#181818" bgcolor={'#ffffffbd'} fontSize={16}>
												{t('online_form.' + val.title)}
											</Typography>
										</Stack>
										<img
											src={val.image}
											alt={val.title}
											style={{
												position: 'absolute',
												top: 5,
												alignContent: 'center',
												width: '80%',
												height: '80%',
												objectFit: 'cover',
												zIndex: 0,
												opacity: 0.7
											}}
										/>
									</SoftButton>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			)}
		</Box>
	);
}
