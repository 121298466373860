import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	userArea: [],
	userName: '',
	userType: '',
	userRole: '',
	userEmail: '',
	userStore: [],
	userZone: [],
	userSettings: []
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		getArea: (state, action) => {
			state.userArea = [...action.payload].sort();
		},
		getName: (state, action) => {
			state.userName = action.payload;
		},
		getType: (state, action) => {
			state.userType = action.payload;
		},
		getRole: (state, action) => {
			state.userRole = action.payload;
		},
		getEmail: (state, action) => {
			state.userEmail = action.payload;
		},
		getStore: (state, action) => {
			state.userStore = action.payload;
		},
		getZone: (state, action) => {
			state.userZone = action.payload;
		},
		getUserSettings: (state, action) => {
			state.userSettings = action.payload;
		}
	}
});

export const { getArea, getName, getType, getRole, getEmail, getStore, getZone, getUserSettings } = userSlice.actions;

export default userSlice.reducer;
