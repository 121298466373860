import { API } from 'aws-amplify';
import { Storage } from '@aws-amplify/storage';
import * as queries from '../graphql/queries';

export const sleep = (i, ms) => new Promise((r) => setTimeout(r, i * ms));

export const getCompany = async () => {
	let company = {};
	let list;
	try {
		let hasNextPage = true;
		let variables = {
			limit: 500
		};
		while (hasNextPage) {
			const graph = await API.graphql({
				query: queries.listOverseasStores,
				variables
			});
			const { items: posts, nextToken } = graph.data.listOverseasStores;
			variables.nextToken = nextToken;
			for (let i in posts) {
				if (posts[i] !== undefined) {
					const countrycode = posts[i].Country;
					const areacode = posts[i].Company.replaceAll('& ', '').replaceAll(' ', '-');
					company[countrycode + '_' + areacode] = posts[i].Company;
					company[countrycode + '_' + areacode + '/Contract'] = posts[i].ContractNo;
					company[countrycode + '_' + areacode + '/Email'] = posts[i].Email;
					company[countrycode + '_' + areacode + '/Address'] = posts[i].Address;
					company[countrycode + '_' + areacode + '/Phone'] = posts[i].Phone;
					company[countrycode + '_' + areacode + '/Currency'] = posts[i].Currency;
				}
			}
			list = Object.assign({}, company);
			hasNextPage = nextToken ? true : false;
		}
		return list;
	} catch (error) {
		console.log('Error retrieving inv', error);
	}
};

export const listAllOrders = async () => {
	let order = [];
	try {
		const variables = {
			limit: 500,
			filter: {
				_deleted: { ne: true }
			}
		};
		let hasNextPage = true;
		while (hasNextPage) {
			const { data: res } = await API.graphql({ query: queries.listOverseasOrders, variables });
			const { items: item, nextToken } = res.listOverseasOrders;
			variables.nextToken = nextToken;
			order = order.concat(item);
			hasNextPage = nextToken ? true : false;
		}
		order = order.filter((f) => f._deleted !== true);
	} catch (error) {
		console.log('lsit all orders error', error);
	}
	return order;
};

export const listArea = async () => {
	let area;
	try {
		const variables = {
			limit: 500
		};
		let hasNextPage = true;
		while (hasNextPage) {
			const { data: res } = await API.graphql({ query: queries.listOverseasStores, variables });
			const { items: item, nextToken } = res.listOverseasStores;
			variables.nextToken = nextToken;
			area = item.map((i) => i.Country + '_' + i.Company.replaceAll('& ', '').replaceAll(' ', '-'));
			hasNextPage = nextToken ? true : false;
		}
	} catch (error) {
		console.log('list area error', error);
	}
	return area;
};

export const fetchPrepCheckContent = async () => {
	let prepcontent = [];
	try {
		const variables = {
			limit: 500
		};
		let hasNextPage = true;
		while (hasNextPage) {
			const { data: res } = await API.graphql({ query: queries.listPrepCheckContents, variables });
			const { items: item, nextToken } = res.listPrepCheckContents;
			variables.nextToken = nextToken;
			prepcontent = prepcontent.concat(item);
			hasNextPage = nextToken ? true : false;
		}
		prepcontent = prepcontent.filter((f) => f._deleted !== true);
	} catch (error) {
		console.log('fetch perp content error', error);
	}
	return prepcontent;
};

export const fetchAllStore = async () => {
	let allstore = [];
	try {
		const variables = {
			limit: 500
		};
		let hasNextPage = true;
		while (hasNextPage) {
			const { data: res } = await API.graphql({ query: queries.listOverseasStores, variables });
			const { items: item, nextToken } = res.listOverseasStores;
			variables.nextToken = nextToken;
			allstore = allstore.concat(item);
			hasNextPage = nextToken ? true : false;
		}
		allstore = allstore.filter((f) => f._deleted !== true);
	} catch (error) {
		console.log('fetch all store error', error);
	}
	return allstore;
};

export const getAllAuditLists = async (store, start, end) => {
	let allItems = [];
	try {
		for (let s in store) {
			let hasNextPage = true;
			let token = null;
			while (hasNextPage) {
				const variables = {
					filter: {
						Store: { eq: store[s] },
						Date: { between: [start, end] },
						_deleted: { ne: true }
					}
				};
				const res = await API.graphql({
					query: queries.listAuditForms,
					variables: variables
				});
				const { items: item, nextToken } = res.data.listAuditForms;
				token = nextToken;
				allItems = allItems.concat(item);
				hasNextPage = token ? true : false;
			}
		}
	} catch (error) {
		console.log('get all audit error', error);
	}
	return allItems;
};

export const getAllPrepLists = async (store, start, end) => {
	let allItems = [];
	try {
		for (let s in store) {
			let hasNextPage = true;
			let token = null;
			while (hasNextPage) {
				const variables = {
					filter: {
						Store: { eq: store[s] },
						S_Date: { between: [start, end] },
						_deleted: { ne: true }
					}
				};
				const res = await API.graphql({
					query: queries.listPrepCheckForms,
					variables: variables
				});
				const { items: item, nextToken } = res.data.listPrepCheckForms;
				token = nextToken;
				allItems = allItems.concat(item);
				hasNextPage = token ? true : false;
			}
		}
	} catch (error) {
		console.log('get all prep list error', error);
	}
	return allItems;
};

export const uploadPrepAttach = async (selectedFile, owner, store, ms) => {
	let attachPath = [];
	try {
		for (const s in selectedFile) {
			const res = await Storage.put(
				`prep/${owner.replaceAll(' ', '_')}/${store}/${ms}_${selectedFile[s].name}`,
				selectedFile[s]
			); //{level: "private"}protected
			attachPath.push({ key: res.key, name: selectedFile[s].name });
		}
		return attachPath;
	} catch (error) {
		console.log('upload error', error);
		return error;
	}
};

export const deletePrepAttach = async (selectedFile) => {
	try {
		for (const s in selectedFile) {
			await Storage.remove(selectedFile[s].key);
		}
	} catch (error) {
		console.log('delete error', error);
		return error;
	}
};

export function downloadBlob(blob, filename) {
	try {
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename.split('/')[3].substring(14) || 'download';
		const clickHandler = () => {
			setTimeout(() => {
				URL.revokeObjectURL(url);
				a.removeEventListener('click', clickHandler);
			}, 150);
		};
		a.addEventListener('click', clickHandler, false);
		a.click();
		return a;
	} catch (error) {
		console.log('download error', error);
	}
}

export const listPermission = async () => {
	try {
		let allItems = [];
		let hasNextPage = true;
		let token = null;
		while (hasNextPage) {
			const res = await API.graphql({
				query: queries.listPermissions
			});
			const { items: item, nextToken } = res.data.listPermissions;
			token = nextToken;
			item.map((i) => (i.permission = JSON.parse(i.permission)));
			allItems = allItems.concat(item);
			hasNextPage = token ? true : false;
		}
		return allItems;
	} catch (error) {
		console.log('list permission error', error);
	}
};

export const listShippings = async (condition) => {
	let allItem = [];
	try {
		const variables = {
			limit: 500,
			filter: {
				_deleted: { ne: true }
			}
		};
		if (condition && condition.length > 0) {
			condition.map((v) => (variables.filter[Object.keys(v)[0]] = Object.values(v)[0])); //add filter condition when exists
		}
		let hasNextPage = true;
		while (hasNextPage) {
			const { data: res } = await API.graphql({ query: queries.listOverseasShippings, variables });
			const { items: item, nextToken } = res.listOverseasShippings;
			variables.nextToken = nextToken;
			allItem = allItem.concat(item);
			hasNextPage = nextToken ? true : false;
		}
		return allItem;
	} catch (error) {
		console.log('list overseasShippings error', error);
	}
};

export const getOrder = async (id) => {
	try {
		const graphql = await API.graphql({
			query: queries.getOverseasOrder,
			variables: { id: id }
		});
		const order = graphql.data.getOverseasOrder;
		return order;
	} catch (error) {
		console.log('get overseasOrder error', error);
	}
};

export const getShipping = async (id) => {
	try {
		const graphql = await API.graphql({
			query: queries.getOverseasShipping,
			variables: { id: id }
		});
		const original = graphql.data.getOverseasShipping;
		return original;
	} catch (error) {
		console.log('get overseasShipping error', error);
	}
};

export const listAuditContentTWS = async () => {
	try {
		let result = await API.graphql({
			query: queries.listAuditContentTWS
		});
		result = result.data.listAuditContentTWS.items;
		return result;
	} catch (error) {
		console.log('list auditContentTW error', error);
	}
};
