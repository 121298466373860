import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import {ThemeProvider} from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
// import { DataStore } from '@aws-amplify/datastore';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
// import studioTheme from './ui-components/studioTheme';
import { BrowserRouter } from 'react-router-dom';
import { SoftUIControllerProvider } from './context';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './Redux/store';

Amplify.configure(awsconfig);
// DataStore.start();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	//   <App />
	// </React.StrictMode>
	<BrowserRouter>
		<SoftUIControllerProvider>
			<Provider store={store}>
				{/* <ThemeProvider theme={studioTheme}> */}
				<Authenticator.Provider>
					<App />
				</Authenticator.Provider>
				{/* </ThemeProvider> */}
			</Provider>
		</SoftUIControllerProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
