import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SoftBadgeRoot from '../../components/SoftBadge/SoftBadgeRoot';

// eslint-disable-next-line no-use-before-define
const SoftBadge = forwardRef(
	({ color, variant, size, circular, indicator, border, container, children, ...rest }, ref) => (
		<SoftBadgeRoot
			{...rest}
			ownerState={{ color, variant, size, circular, indicator, border, container, children }}
			ref={ref}
			color="default">
			{children}
		</SoftBadgeRoot>
	)
);

// Setting default values for the props of SoftBadge
SoftBadge.defaultProps = {
	color: 'info',
	variant: 'gradient',
	size: 'sm',
	circular: false,
	indicator: false,
	border: false,
	children: false,
	container: false
};

// Typechecking props of the SoftBadge
SoftBadge.propTypes = {
	color: PropTypes.oneOf([
		'primary',
		'secondary',
		'info',
		'success',
		'warning',
		'error',
		'light',
		'silver',
		'dark',
		'pending',
		'processing',
		'completed'
	]),
	variant: PropTypes.oneOf(['gradient', 'contained']),
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
	circular: PropTypes.bool,
	indicator: PropTypes.bool,
	border: PropTypes.bool,
	children: PropTypes.node,
	container: PropTypes.bool
};

export default SoftBadge;
