import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

export const createStore = async (variables) => {
	try {
		await API.graphql({
			query: mutations.createOverseasStore,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('create store error', error);
	}
};

export const updateStore = async (variables) => {
	try {
		await API.graphql({
			query: mutations.updateOverseasStore,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('update store error', error);
	}
};

export const updatePermission = async (variables) => {
	try {
		await API.graphql({
			query: mutations.updatePermission,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('update permission error', error);
	}
};

export const createOverseasOrder = async (variables) => {
	try {
		await API.graphql({
			query: mutations.createOverseasOrder,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('create order error', error);
	}
};

export const updateOverseasOrder = async (variables) => {
	try {
		await API.graphql({
			query: mutations.updateOverseasOrder,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('update order error', error);
	}
};

export const deleteOverseasOrder = async (variables) => {
	try {
		await API.graphql({
			query: mutations.deleteOverseasOrder,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('delete order error', error);
	}
};

export const createOverseasShipping = async (variables) => {
	try {
		await API.graphql({
			query: mutations.createOverseasShipping,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('create overseasShipping error', error);
	}
};

export const updateOverseasShipping = async (variables) => {
	try {
		await API.graphql({
			query: mutations.updateOverseasShipping,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('update overseasShipping error', error);
	}
};

export const deleteOverseasShipping = async (variables) => {
	try {
		await API.graphql({
			query: mutations.deleteOverseasShipping,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('delete overseasShipping error', error);
	}
};

export const createAudit = async (variables) => {
	try {
		await API.graphql({
			query: mutations.createAuditForm,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('create audit error', error);
	}
};

export const updateAudit = async (variables) => {
	try {
		await API.graphql({
			query: mutations.updateAuditForm,
			variables: {
				input: variables
			}
		});
	} catch (error) {
		console.log('update audit error', error);
	}
};

export const createPrepCheck = async (check, create) => {
	try {
		if (check) {
			create = Object.assign({ id: check.id }, create);
			create._version = check._version;
			await API.graphql({
				query: mutations.updatePrepCheckForm,
				variables: { input: create }
			});
		} else {
			await API.graphql({
				query: mutations.createPrepCheckForm,
				variables: { input: create }
			});
		}
		return 200;
	} catch (error) {
		console.log('create error', error);
		return 400;
	}
};
