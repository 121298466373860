import PropTypes from 'prop-types';
// import { useAuthenticator } from '@aws-amplify/ui-react';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import SoftBox from '../../components/SoftBox';
import { collapseItem, collapseIconBox, collapseIcon, collapseText } from './styles/sidenavCollapse';
import { useSoftUIController } from '../../context';
import { useSelector } from 'react-redux';

function SidenavCollapse({ color, icon, name, children, active, noCollapse, open, level, keyName, ...rest }) {
	const [controller] = useSoftUIController();
	const { miniSidenav, transparentSidenav } = controller;
	const area = useSelector((state) => state.user.userArea);
	const role = useSelector((state) => state.user.userRole);
	const userType = useSelector((state) => state.user.userType);

	const sideNav = () => {
		return (
			<>
				<ListItem component="li">
					<SoftBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
						<ListItemIcon sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, color })}>
							{typeof icon === 'string' ? <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon> : icon}
						</ListItemIcon>

						<ListItemText
							primary={name}
							sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
						/>
					</SoftBox>
				</ListItem>
				{children && (
					<Collapse in={open} unmountOnExit>
						{children}
					</Collapse>
				)}
			</>
		);
	};

	switch (keyName) {
		case 'tworder':
			if (role === 'Owner' || (role === 'TW_staff' && userType !== 'Z_Viewer')) {
				return sideNav();
			}
			break;
		case 'inventory':
			if (userType === 'Z_Manager') {
				return sideNav();
			}
			break;
		case 'usorder':
			if (
				(role === 'Owner' && area[0] !== 'CA_Van') ||
				(role === 'US_staff' && userType !== 'Z_Viewer') ||
				(role === 'TW_staff' && (userType === 'Z_Manager' || userType === 'Z_Observer'))
			) {
				return sideNav();
			}
			break;
		default:
			noCollapse;
			level;
			return sideNav();
	}

	// if(level.includes(userType)){
	//   return (
	//     <>
	//       <ListItem component="li">
	//         <SoftBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
	//           <ListItemIcon
	//             sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, color })}
	//           >
	//             {typeof icon === "string" ? (
	//               <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
	//             ) : (
	//               icon
	//             )}
	//           </ListItemIcon>

	//           <ListItemText
	//             primary={name}
	//             sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
	//           />
	//         </SoftBox>
	//       </ListItem>
	//       {children && (
	//         <Collapse in={open} unmountOnExit>
	//           {children}
	//         </Collapse>
	//       )}
	//     </>
	//   );
	// }
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
	color: 'info',
	active: false,
	noCollapse: false,
	children: false,
	open: false
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
	color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
	icon: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired,
	children: PropTypes.node,
	active: PropTypes.bool,
	noCollapse: PropTypes.bool,
	open: PropTypes.bool,
	level: PropTypes.array,
	keyName: PropTypes.string
};

export default SidenavCollapse;
