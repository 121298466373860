import Dashboard from './pages/dashboard';
import Order from './pages/osorder';
import Inventory from './pages/inventory';
import UsOrder from './pages/usorder';
import Edit from './pages/inventory/edit';
import Area from './pages/inventory/area';
import Home from './pages/home/home';
import Account from './pages/Account';
import { Onlineform, Audit, AuditForm, Prepcheck, PrepCheckForm } from './pages/onlineform/index';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Document from './template/Icons/Document';
import InventoryIcon from '@mui/icons-material/Inventory';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

const routes = [
	{
		type: 'collapse',
		name: 'Home',
		key: 'home',
		route: '/home',
		icon: <HomeIcon size="14px" />,
		component: <Home />,
		noCollapse: true,
		level: ['CA_Van', 'US_NorCal', 'US_Seattle', 'US_Irvine', 'US_Pasadena', 'Z_Manager', 'Z_Viewer', 'Z_Editor']
	},
	{
		type: 'collapse',
		name: 'Dashboard',
		key: 'dashboard',
		route: '/dashboard',
		icon: <ListAltIcon size="14px" />,
		component: <Dashboard />,
		noCollapse: true,
		level: ['CA_Van', 'US_NorCal', 'US_Seattle', 'US_Irvine', 'US_Pasadena', 'Z_Manager', 'Z_Viewer', 'Z_Editor']
	},
	{
		type: 'collapse',
		name: 'New order',
		key: 'tworder',
		route: '/tworder',
		icon: <Document size="14px" />,
		component: <Order />,
		noCollapse: true,
		level: ['CA_Van', 'US_NorCal', 'US_Seattle', 'US_Irvine', 'US_Pasadena', 'Z_Manager', 'Z_Editor', 'Y_TW']
	},
	{
		type: 'collapse',
		name: 'US order',
		key: 'usorder',
		route: '/usorder',
		icon: <Document size="14px" />,
		component: <UsOrder />,
		noCollapse: true,
		level: ['Z_Manager', 'Z_Editor', 'Y_TW']
	},
	{
		type: 'collapse',
		name: 'Inventory',
		key: 'inventory',
		route: '/inventory',
		icon: <InventoryIcon fontSize="small" />,
		component: <Inventory />,
		collapse: [
			{
				name: 'Inventory',
				key: 'inventory',
				route: '/inventory',
				component: <Inventory />,
				level: ['Z_Manager', 'Z_Editor']
			},
			{
				name: 'Edit',
				key: 'edit',
				route: '/inventory/edit',
				component: <Edit />,
				level: ['Z_Manager', 'Z_Editor']
			},
			{
				name: 'Area',
				key: 'area',
				route: '/inventory/area',
				component: <Area />,
				level: ['Z_Manager', 'Z_Editor']
			}
		],
		level: ['Z_Manager', 'Z_Editor']
	},
	{
		type: 'collapse',
		name: 'OnlineForm',
		key: 'online_form',
		route: '/online_form',
		icon: <ChecklistIcon fontSize="small" />,
		collapse: [
			{
				name: 'OnlineForm',
				key: 'online_form',
				route: '/online_form',
				component: <Onlineform />,
				level: ['Z_Manager', 'Z_Editor', 'Z_Supervisor']
			},
			{
				name: 'Audit',
				key: 'audit',
				route: '/online_form/audit',
				component: <Audit />,
				level: ['Z_Manager', 'Z_Editor', 'Z_Supervisor']
			},
			{
				name: 'AuditForm',
				key: 'auditform',
				route: '/online_form/audit/edit',
				component: <AuditForm />,
				level: ['Z_Manager', 'Z_Editor', 'Z_Supervisor']
			},
			{
				name: 'OnlineForm',
				key: 'online_form',
				route: '/online_form/prep',
				component: <Prepcheck />,
				level: ['Z_Manager', 'Z_Editor', 'Z_Supervisor']
			},
			{
				name: 'Edit',
				key: 'edit',
				route: '/online_form/prep/edit',
				component: <PrepCheckForm />,
				level: ['Z_Manager', 'Z_Editor', 'Z_Supervisor']
			}
		],
		level: ['Z_Manager', 'Z_Editor', 'Z_Supervisor']
	},
	{
		type: 'collapse',
		name: 'Account',
		key: 'account',
		route: '/account',
		icon: <SupervisorAccountIcon size="14px" />,
		component: <Account />,
		noCollapse: true,
		level: ['Z_Manager']
	}
];

export default routes;
