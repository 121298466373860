// import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import Menu from "@mui/material/Menu";
import Icon from '@mui/material/Icon';
import Avatar from '@mui/material/Avatar';
import SoftBox from '../../../components/SoftBox';
import SoftTypography from '../../../components/SoftTypography';
import SoftBadge from '../../../components/SoftBadge';
// import SoftInput from "../../../components/SoftInput";
// import SoftButton from "../../../components/SoftButton/index";
import Breadcrumbs from '../../Breadcrumbs';
// import NotificationItem from "../../Items/NotificationItem";
// import Grid from "@mui/material/Grid";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {
	navbar,
	navbarContainer,
	navbarRow,
	// navbarIconButton,
	navbarMobileMenu
} from './styles';
import {
	useSoftUIController,
	// setTransparentNavbar,
	setMiniSidenav
	// setOpenConfigurator,
} from '../../../context';
import breakpoints from '../../../assets/theme/base/breakpoints';
import { useSelector } from 'react-redux';

// import team2 from "../../../assets/images/team-2.jpg";
// import logoSpotify from "../../../assets/images/small-logos/logo-spotify.svg";

function DashboardNavbar({ absolute, light, isMini, avatarColor }) {
	const getname = useSelector((state) => state.user.userName);
	const userType = useSelector((state) => state.user.userType);
	const uname = getname.charAt(0).toUpperCase();
	const typeBadge = userType === 'Z_Manager' ? 'primary' : 'light';
	const navbarType = 'sticky';
	const [controller, dispatch] = useSoftUIController();
	const { miniSidenav } = controller; //, openConfigurator, transparentNavbar, fixedNavbar
	// const [openMenu, setOpenMenu] = useState(false);
	const key = useLocation().pathname.split('/').slice(1);
	const route = useLocation().pathname.split('/');
	route.splice(-1);
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0
	});

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	// const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
	// const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
	// const handleCloseMenu = () => setOpenMenu(false);

	// Render the notifications menu
	// const renderMenu = () => (
	//   <Menu
	//     anchorEl={openMenu}
	//     anchorReference={null}
	//     anchorOrigin={{
	//       vertical: "bottom",
	//       horizontal: "left",
	//     }}
	//     open={Boolean(openMenu)}
	//     onClose={handleCloseMenu}
	//     sx={{ mt: 2 }}
	//   >
	//     {/* <NotificationItem
	//       // image={<img src={team2} alt="person" />}
	//       title={["New message", "from Laur"]}
	//       date="13 minutes ago"
	//       onClick={handleCloseMenu}
	//     />
	//     <NotificationItem
	//       image={<img src={logoSpotify} alt="person" />}
	//       title={["New album", "by Travis Scott"]}
	//       date="1 day"
	//       onClick={handleCloseMenu}
	//     />
	//     <NotificationItem
	//       color="secondary"
	//       image={
	//         <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
	//           payment
	//         </Icon>
	//       }
	//       title={["", "Payment successfully completed"]}
	//       date="2 days"
	//       onClick={handleCloseMenu}
	//     /> */}
	//   </Menu>
	// );

	return (
		<AppBar
			position={absolute ? 'absolute' : navbarType}
			color={!trigger ? 'transparent' : 'white'}
			sx={(theme) => navbar(theme, { absolute, light })}
			key={Math.random()}>
			<Toolbar style={{ paddingLeft: 0 }} sx={(theme) => navbarContainer(theme)}>
				{window.innerWidth < breakpoints.values.lg ? null : (
					<SoftBox color="inherit" mb={{ xs: 1, md: 0 }} pl={1} sx={(theme) => navbarRow(theme, { isMini })}>
						<Breadcrumbs
							icon="home"
							title={key[key.length - 1]}
							label={key}
							route={route.toString().replaceAll(',', '/')}
							light={light}
						/>
					</SoftBox>
				)}
				{isMini ? null : (
					<SoftBox
						color={light ? 'white' : 'inherit'}
						display="flex"
						justifyContent="space-between"
						sx={(theme) => navbarRow(theme, { isMini })}>
						{/* <SoftBox pr={1}>
              <SoftInput
                placeholder="Type here..."
                icon={{ component: "search", direction: "left" }}
              />
            </SoftBox> */}
						{/* <SoftBox color={light ? "white" : "inherit"} > */}
						{/* <SoftBox display="flex" justifyContent="space-between" > */}
						<IconButton size="large" color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
							<Icon className={light ? 'text-white' : 'text-dark'}>{miniSidenav ? 'menu' : 'menu_open'}</Icon>
						</IconButton>
						<SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
							<SoftBadge variant="gradient" badgeContent={userType} color={typeBadge} size="xs" container /> &nbsp;
							{/* <SoftTypography variant="caption"> - </SoftTypography> */}
							<SoftTypography variant="caption">
								<Avatar sx={{ bgcolor: avatarColor, width: 32, height: 32 }}>{uname}</Avatar>
							</SoftTypography>
						</SoftBox>
						{/* </SoftBox> */}
						{/* <IconButton
                size="large"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu" : "menu_open"}
                </Icon>
              </IconButton> */}

						{/* <IconButton
                size="large"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton> */}
						{/* <IconButton
                size="large"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
              </IconButton> */}
						{/* {renderMenu()} */}
						{/* </SoftBox> */}
					</SoftBox>
				)}
			</Toolbar>
		</AppBar>
	);
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: false,
	isMini: false,
	avatarColor: '#' + Math.floor(Math.random() * 16777215).toString(16)
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
	absolute: PropTypes.bool,
	light: PropTypes.bool,
	isMini: PropTypes.bool,
	avatarColor: PropTypes.string
};

export default DashboardNavbar;
// const avatarColor = '#'+Math.floor(Math.random()*16777215).toString(16);
// export default withAuthenticator(DashboardNavbar);
