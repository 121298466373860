/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOverseasStore = /* GraphQL */ `
  mutation CreateOverseasStore(
    $input: CreateOverseasStoreInput!
    $condition: ModelOverseasStoreConditionInput
  ) {
    createOverseasStore(input: $input, condition: $condition) {
      id
      Name
      Country
      Currency
      Company
      ContractNo
      Email
      Store
      Long_lat
      Address
      Phone
      Status
      Region
      Owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOverseasStore = /* GraphQL */ `
  mutation UpdateOverseasStore(
    $input: UpdateOverseasStoreInput!
    $condition: ModelOverseasStoreConditionInput
  ) {
    updateOverseasStore(input: $input, condition: $condition) {
      id
      Name
      Country
      Currency
      Company
      ContractNo
      Email
      Store
      Long_lat
      Address
      Phone
      Status
      Region
      Owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOverseasStore = /* GraphQL */ `
  mutation DeleteOverseasStore(
    $input: DeleteOverseasStoreInput!
    $condition: ModelOverseasStoreConditionInput
  ) {
    deleteOverseasStore(input: $input, condition: $condition) {
      id
      Name
      Country
      Currency
      Company
      ContractNo
      Email
      Store
      Long_lat
      Address
      Phone
      Status
      Region
      Owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOverseasShipping = /* GraphQL */ `
  mutation CreateOverseasShipping(
    $input: CreateOverseasShippingInput!
    $condition: ModelOverseasShippingConditionInput
  ) {
    createOverseasShipping(input: $input, condition: $condition) {
      id
      Product
      Category
      Code
      ZH
      Size
      Expiry
      Unit
      Note
      HSCode
      Brand
      Material
      ShipFrom
      Area
      Price
      Moq
      NW
      GW
      CartonSize
      Img
      Availability
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOverseasShipping = /* GraphQL */ `
  mutation UpdateOverseasShipping(
    $input: UpdateOverseasShippingInput!
    $condition: ModelOverseasShippingConditionInput
  ) {
    updateOverseasShipping(input: $input, condition: $condition) {
      id
      Product
      Category
      Code
      ZH
      Size
      Expiry
      Unit
      Note
      HSCode
      Brand
      Material
      ShipFrom
      Area
      Price
      Moq
      NW
      GW
      CartonSize
      Img
      Availability
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOverseasShipping = /* GraphQL */ `
  mutation DeleteOverseasShipping(
    $input: DeleteOverseasShippingInput!
    $condition: ModelOverseasShippingConditionInput
  ) {
    deleteOverseasShipping(input: $input, condition: $condition) {
      id
      Product
      Category
      Code
      ZH
      Size
      Expiry
      Unit
      Note
      HSCode
      Brand
      Material
      ShipFrom
      Area
      Price
      Moq
      NW
      GW
      CartonSize
      Img
      Availability
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOverseasOrder = /* GraphQL */ `
  mutation CreateOverseasOrder(
    $input: CreateOverseasOrderInput!
    $condition: ModelOverseasOrderConditionInput
  ) {
    createOverseasOrder(input: $input, condition: $condition) {
      id
      Code
      Name
      Qty
      Amount
      Status
      OrderType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOverseasOrder = /* GraphQL */ `
  mutation UpdateOverseasOrder(
    $input: UpdateOverseasOrderInput!
    $condition: ModelOverseasOrderConditionInput
  ) {
    updateOverseasOrder(input: $input, condition: $condition) {
      id
      Code
      Name
      Qty
      Amount
      Status
      OrderType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOverseasOrder = /* GraphQL */ `
  mutation DeleteOverseasOrder(
    $input: DeleteOverseasOrderInput!
    $condition: ModelOverseasOrderConditionInput
  ) {
    deleteOverseasOrder(input: $input, condition: $condition) {
      id
      Code
      Name
      Qty
      Amount
      Status
      OrderType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAuditForm = /* GraphQL */ `
  mutation CreateAuditForm(
    $input: CreateAuditFormInput!
    $condition: ModelAuditFormConditionInput
  ) {
    createAuditForm(input: $input, condition: $condition) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      ContentFrom
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAuditForm = /* GraphQL */ `
  mutation UpdateAuditForm(
    $input: UpdateAuditFormInput!
    $condition: ModelAuditFormConditionInput
  ) {
    updateAuditForm(input: $input, condition: $condition) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      ContentFrom
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAuditForm = /* GraphQL */ `
  mutation DeleteAuditForm(
    $input: DeleteAuditFormInput!
    $condition: ModelAuditFormConditionInput
  ) {
    deleteAuditForm(input: $input, condition: $condition) {
      id
      Date
      year
      month
      Store
      store_id
      supervisor_id
      Model
      Type
      Content
      Final
      Suggestion
      Improvement
      Status
      Author
      signature
      staffSign
      ContentFrom
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAuditContentTW = /* GraphQL */ `
  mutation CreateAuditContentTW(
    $input: CreateAuditContentTWInput!
    $condition: ModelAuditContentTWConditionInput
  ) {
    createAuditContentTW(input: $input, condition: $condition) {
      id
      Category
      Partition
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAuditContentTW = /* GraphQL */ `
  mutation UpdateAuditContentTW(
    $input: UpdateAuditContentTWInput!
    $condition: ModelAuditContentTWConditionInput
  ) {
    updateAuditContentTW(input: $input, condition: $condition) {
      id
      Category
      Partition
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAuditContentTW = /* GraphQL */ `
  mutation DeleteAuditContentTW(
    $input: DeleteAuditContentTWInput!
    $condition: ModelAuditContentTWConditionInput
  ) {
    deleteAuditContentTW(input: $input, condition: $condition) {
      id
      Category
      Partition
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAuditContentUS = /* GraphQL */ `
  mutation CreateAuditContentUS(
    $input: CreateAuditContentUSInput!
    $condition: ModelAuditContentUSConditionInput
  ) {
    createAuditContentUS(input: $input, condition: $condition) {
      id
      Category
      Partition
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAuditContentUS = /* GraphQL */ `
  mutation UpdateAuditContentUS(
    $input: UpdateAuditContentUSInput!
    $condition: ModelAuditContentUSConditionInput
  ) {
    updateAuditContentUS(input: $input, condition: $condition) {
      id
      Category
      Partition
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAuditContentUS = /* GraphQL */ `
  mutation DeleteAuditContentUS(
    $input: DeleteAuditContentUSInput!
    $condition: ModelAuditContentUSConditionInput
  ) {
    deleteAuditContentUS(input: $input, condition: $condition) {
      id
      Category
      Partition
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      Order_time
      CustomerID
      Order_no
      Firstname
      Lastname
      Store_id
      Final_price
      Email
      Phone
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      Order_time
      CustomerID
      Order_no
      Firstname
      Lastname
      Store_id
      Final_price
      Email
      Phone
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      Order_time
      CustomerID
      Order_no
      Firstname
      Lastname
      Store_id
      Final_price
      Email
      Phone
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      Order_time
      Order_no
      Store_id
      Quantity
      Discount_amount
      Final_price
      Tax_amount
      Tip_amount
      Status
      Order_type
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      Order_time
      Order_no
      Store_id
      Quantity
      Discount_amount
      Final_price
      Tax_amount
      Tip_amount
      Status
      Order_type
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      Order_time
      Order_no
      Store_id
      Quantity
      Discount_amount
      Final_price
      Tax_amount
      Tip_amount
      Status
      Order_type
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPay = /* GraphQL */ `
  mutation CreatePay(
    $input: CreatePayInput!
    $condition: ModelPayConditionInput
  ) {
    createPay(input: $input, condition: $condition) {
      id
      Order_time
      Order_no
      Pay_type
      Card_type
      Store_id
      Final_price
      Tip_amount
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePay = /* GraphQL */ `
  mutation UpdatePay(
    $input: UpdatePayInput!
    $condition: ModelPayConditionInput
  ) {
    updatePay(input: $input, condition: $condition) {
      id
      Order_time
      Order_no
      Pay_type
      Card_type
      Store_id
      Final_price
      Tip_amount
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePay = /* GraphQL */ `
  mutation DeletePay(
    $input: DeletePayInput!
    $condition: ModelPayConditionInput
  ) {
    deletePay(input: $input, condition: $condition) {
      id
      Order_time
      Order_no
      Pay_type
      Card_type
      Store_id
      Final_price
      Tip_amount
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      Order_time
      Order_no
      Store_id
      Product
      Quantity
      Item_price
      Modifier_price
      Final_price
      Ice
      Sugar
      Topping
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      Order_time
      Order_no
      Store_id
      Product
      Quantity
      Item_price
      Modifier_price
      Final_price
      Ice
      Sugar
      Topping
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      Order_time
      Order_no
      Store_id
      Product
      Quantity
      Item_price
      Modifier_price
      Final_price
      Ice
      Sugar
      Topping
      Year
      Month
      Order_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPrepCheckForm = /* GraphQL */ `
  mutation CreatePrepCheckForm(
    $input: CreatePrepCheckFormInput!
    $condition: ModelPrepCheckFormConditionInput
  ) {
    createPrepCheckForm(input: $input, condition: $condition) {
      id
      S_Date
      E_Date
      Year
      Month
      Store
      Supervisor
      Content
      Suggestion
      Status
      Author
      OwnerSign
      StaffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePrepCheckForm = /* GraphQL */ `
  mutation UpdatePrepCheckForm(
    $input: UpdatePrepCheckFormInput!
    $condition: ModelPrepCheckFormConditionInput
  ) {
    updatePrepCheckForm(input: $input, condition: $condition) {
      id
      S_Date
      E_Date
      Year
      Month
      Store
      Supervisor
      Content
      Suggestion
      Status
      Author
      OwnerSign
      StaffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePrepCheckForm = /* GraphQL */ `
  mutation DeletePrepCheckForm(
    $input: DeletePrepCheckFormInput!
    $condition: ModelPrepCheckFormConditionInput
  ) {
    deletePrepCheckForm(input: $input, condition: $condition) {
      id
      S_Date
      E_Date
      Year
      Month
      Store
      Supervisor
      Content
      Suggestion
      Status
      Author
      OwnerSign
      StaffSign
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPrepCheckContent = /* GraphQL */ `
  mutation CreatePrepCheckContent(
    $input: CreatePrepCheckContentInput!
    $condition: ModelPrepCheckContentConditionInput
  ) {
    createPrepCheckContent(input: $input, condition: $condition) {
      id
      Category
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePrepCheckContent = /* GraphQL */ `
  mutation UpdatePrepCheckContent(
    $input: UpdatePrepCheckContentInput!
    $condition: ModelPrepCheckContentConditionInput
  ) {
    updatePrepCheckContent(input: $input, condition: $condition) {
      id
      Category
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePrepCheckContent = /* GraphQL */ `
  mutation DeletePrepCheckContent(
    $input: DeletePrepCheckContentInput!
    $condition: ModelPrepCheckContentConditionInput
  ) {
    deletePrepCheckContent(input: $input, condition: $condition) {
      id
      Category
      Number
      Content
      ZH
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission(
    $input: CreatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    createPermission(input: $input, condition: $condition) {
      id
      manual
      route
      permission
      TW_View
      section
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission(
    $input: UpdatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    updatePermission(input: $input, condition: $condition) {
      id
      manual
      route
      permission
      TW_View
      section
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission(
    $input: DeletePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    deletePermission(input: $input, condition: $condition) {
      id
      manual
      route
      permission
      TW_View
      section
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
