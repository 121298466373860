import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

const ScrollTop = (props) => {
	const { window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 100
	});

	const handleClick = (event) => {
		const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
		if (anchor) {
			anchor.scrollIntoView({
				block: 'center'
			});
		}
	};

	ScrollTop.propTypes = {
		children: PropTypes.element,
		window: PropTypes.func
	};

	return (
		<Fade in={trigger}>
			<Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 99 }}>
				<Fab sx={{ backgroundColor: '#f4f4f4' }} size="small" aria-label="scroll back to top">
					<KeyboardArrowUpIcon />
				</Fab>
			</Box>
		</Fade>
	);
};

export default ScrollTop;
