import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@aws-amplify/ui-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteOverseasShipping } from '../../scripts/mutations';
import { listShippings } from '../../scripts';
import { exportInventory } from '../../components/ExportExcel';
import Dialog from '../../components/Dialog';
import SoftBox from '../../components/SoftBox';
import SoftButton from '../../components/SoftButton/index';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

function Inventory() {
	const [item, setItem] = useState([]);
	const [itemValue, setItemValue] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [category, setCategory] = useState([]);
	const [delVal, setDelVal] = useState({});
	const navigate = useNavigate();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('');
	const [searchItem, setsearchItem] = useState('');
	const { userZone } = useSelector((state) => state.user);
	const role = userZone.includes('Y_TW') ? 'TW' : 'US';
	const [exportArea, setExportArea] = useState('');
	const [exportDialog, setExportDialog] = useState(false);
	const [exportData, setExportData] = useState();
	const [viewPrice, setViewPrice] = useState(false);
	const [priceContent, setPriceContent] = useState('');
	const [loading, setLoading] = useState(true);
	const area = useSelector((state) => state.user.userArea);
	const getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => descendingComparator(a[orderBy], b[orderBy])
			: (a, b) => -descendingComparator(a[orderBy], b[orderBy]);
	};

	const descendingComparator = (a, b) => {
		if (b < a) return -1;
		if (b > a) return 1;
		return 0;
	};

	const sortedItem = item.sort(getComparator(order, orderBy));
	const filteredItems = sortedItem.filter((item) => {
		let ignore = new RegExp(searchItem, 'i'); //ignore case-sensitive
		return ignore.test(item.Product) || item.Code.includes(searchItem.toUpperCase()) || item.ZH.includes(searchItem);
	});

	const handleDialog = () => {
		setExportDialog(!exportDialog);
		setExportArea('');
	};

	const handleViewPrice = () => {
		setViewPrice(!viewPrice);
	};

	const handleArea = (event) => {
		const area = event.target.value;
		setExportArea(area);
		let filtered = filteredItems.filter((i) => i.Area.includes(area));
		setExportData(filtered);
	};

	const handleSort = (columnId) => {
		const isAsc = orderBy === columnId && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(columnId);
	};

	const handleSearchChange = (event) => {
		setsearchItem(event.target.value);
	};

	const handleClickOpen = (val) => {
		setDelVal(val);
		setOpen(true);
	};

	const handleClose = () => {
		setDelVal({});
		setOpen(false);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		setPage(0);
	};

	const columns = [
		{ field: 'Action', headerName: '選項', width: 150 },
		{ field: 'Product', headerName: '商品', width: 160 },
		{ field: 'Category', headerName: '類別', width: 120 },
		{ field: 'Code', headerName: '商品代碼', width: 150 },
		{ field: 'ZH', headerName: '中文', width: 180 },
		{ field: 'Size', headerName: '尺寸', width: 120 },
		{ field: 'Expiry', headerName: '有效期限', width: 120 },
		{ field: 'Unit', headerName: '單位', width: 90 },
		{ field: 'Note', headerName: '註記', width: 150 },
		{ field: 'HSCode', headerName: 'HSCode', width: 160 },
		{ field: 'Brand', headerName: '品牌', width: 120 },
		{ field: 'Material', headerName: '材質', width: 120 },
		{ field: 'ShipFrom', headerName: '出貨地', width: 130 },
		// { field: 'Area', headerName: '地區', width: 120 },
		{ field: 'Area/Price', headerName: '地區', width: 150 },
		// { field: 'Price', headerName: '價格', width: 90 },
		{ field: 'Moq', headerName: '數量限制', width: 120 },
		{ field: 'NW', headerName: '淨重', width: 90 },
		{ field: 'GW', headerName: '毛重', width: 90 },
		{ field: 'CartonSize', headerName: '箱子體積', width: 120 },
		{ field: 'createdAt', headerName: '建立時間', width: 120 }
	];

	useEffect(() => {
		getItem();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [role]);

	async function getItem() {
		let items = [];
		let itemValue = [];
		let category = [];
		let uniCategory = [];
		let filter = [];
		try {
			if (role !== 'TW') {
				filter.push({ ShipFrom: { eq: role } });
			}
			let posts = await listShippings(filter);
			for (let i in posts) {
				if (posts[i] !== undefined) {
					items.push(posts[i]);
					category.push(posts[i].Category);
					uniCategory = [...new Set(category)];
				}
			}
			setItem(items);
			setCategory(uniCategory);
			const column = Object.keys(items[0]);
			for (let i in column) {
				itemValue.push({ id: column[i], label: column[i] });
				if (itemValue[0]['label'] === 'id') {
					itemValue[0]['label'] = 'Action';
				}
			}
			setItemValue(itemValue);
			setLoading(false);
		} catch (error) {
			console.log('Error retrieving inv', error);
		}
	}

	async function deleteItem() {
		try {
			const variables = {
				id: delVal.id,
				_version: delVal._version
			};
			await deleteOverseasShipping(variables);
		} catch (error) {
			console.log('error', error);
		}
		setsearchItem('');
		setOpen(false);
		setPage(0);
		getItem();
	}

	const DelContent = () => (
		<>
			<Typography fontSize={16}>{t('Are you sure to delete')}</Typography>
			{delVal.id ? <Typography fontWeight={'bold'} fontSize={16}>{`${delVal.Product}?`}</Typography> : null}
		</>
	);

	const priceDetails = (item) => {
		handleViewPrice();
		setPriceContent(item);
	};

	const priceDialog = () => {
		if (priceContent) {
			const price = JSON.parse(priceContent.Price);
			return (
				<>
					<Grid container pb={1} textAlign={'left'}>
						<Grid item md={10} xs={10} fontWeight={'bold'} fontSize={16}>
							{t('inventory.Area')}
						</Grid>

						<Grid item md={2} xs={2} fontWeight={'bold'} fontSize={16}>
							{t('inventory.Price')}
						</Grid>
					</Grid>
					{Object.keys(price).length === 0 ? (
						<Typography fontSize={16} textAlign={'center'}>
							{t('inventory.No pricing data currently')}
						</Typography>
					) : (
						<Grid container key={area} pb={1} textAlign={'left'}>
							{Object.keys(price).map((area) => (
								<>
									<Grid item md={10} xs={10} fontSize={16} pb={1}>
										{area}
										{priceContent.Area.includes(area) ? null : (
											<Chip label={t('inventory.off')} size="small" sx={{ marginLeft: 0.5 }} />
										)}
									</Grid>
									<Grid item md={2} xs={2} fontSize={16}>
										${price[area].toLocaleString(undefined, { minimumFractionDigits: 2 })}
									</Grid>
								</>
							))}
						</Grid>
					)}
				</>
			);
		}
	};

	const ExportContent = () => (
		<>
			<FormControl fullWidth>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={exportArea || ''}
					label="area"
					onChange={handleArea}>
					{area.map((a, index) => (
						<MenuItem value={a} key={index}>
							{a}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<Typography fontSize={14} textAlign={'center'} pt={2} display={exportArea ? undefined : 'none'}>
				{t('inventory.Export_msg', { name: exportArea })}
			</Typography>
		</>
	);

	const SkeletonView = () => (
		<TableRow>
			<TableCell colSpan={10}>
				<Skeleton animation="wave" />
			</TableCell>
		</TableRow>
	);

	return (
		<>
			<Grid container>
				<Grid item xs={12} md={7} pr={3}>
					<TextField
						variant="outlined"
						value={searchItem}
						onChange={handleSearchChange}
						placeholder={t('edit.Search product, code, ZH')}
					/>
				</Grid>
				<Grid item xs={12} md={5} pt={2}>
					<SoftButton
						sx={{ marginRight: { xs: 0.2, md: 2 } }}
						color="primary"
						size="small"
						variant="outlined"
						onClick={() => {
							navigate('/inventory/edit', { state: { item: itemValue, category: category } });
						}}>
						{t('edit.Add+')}
					</SoftButton>
					<SoftButton
						sx={{ marginRight: { xs: 0.2, md: 2 } }}
						color="dark"
						size="small"
						variant="outlined"
						onClick={() => {
							navigate('/inventory/area', { state: { item: item, category: category } });
						}}>
						{t('area.Manage')}
					</SoftButton>
					<SoftButton color="dark" size="small" variant="outlined" onClick={handleDialog}>
						{t('inventory.Export')}
					</SoftButton>
				</Grid>
			</Grid>
			<SoftBox pt={2} pb={6}>
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<TableContainer sx={{ maxHeight: 540 }}>
						<Table
							stickyHeader
							aria-label="sticky table"
							sx={{
								'& .MuiTableHead-root': {
									display: 'table-header-group'
								},
								'& .css-jr1qcz-MuiTableCell-root': {
									backgroundColor: 'lightGrey'
								}
							}}>
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											key={'tableRow' + column.field}
											align={'center'}
											style={{ minWidth: column.width, fontWeight: 'bold', fontSize: 14, lineHeight: 1.5 }}>
											<TableSortLabel
												active={orderBy === column.field}
												direction={orderBy === column.field ? order : 'asc'}
												onClick={() => {
													if (column.field !== 'Action') {
														handleSort(column.field);
													}
												}}
												sx={{
													'& .css-1oqyonh-MuiSvgIcon-root-MuiTableSortLabel-icon': {
														opacity: '100 !important'
													}
												}}>
												{t('inventory.' + column.field)}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading ? (
									filteredItems.length > 0 ? (
										filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
											<TableRow hover role="checkbox" tabIndex={-1} key={'tabIndex' + index + row.code}>
												{columns.map((column) => {
													let value = row[column.field];
													let curItem = filteredItems[filteredItems.indexOf(row)];
													if (value === null || value === 'n/a') {
														value = 'N/A';
													}
													switch (column.field) {
														case 'Moq':
															return (
																<TableCell key={'tableCell' + index + column.field} align={'center'}>
																	{Object.values(value)
																		.sort()
																		.map((val, index) => (
																			<div key={'cells' + index}>{val}</div>
																		))}
																</TableCell>
															);
														case 'Action':
															const priceLen = row.Area.length;
															const areaLen = area.length;
															const dotColor = priceLen === areaLen ? 'success' : priceLen === 0 ? 'light' : 'warning';
															return (
																<TableCell key={'tableCell2' + column.field} align={'center'}>
																	<Tooltip title={t(`inventory.status_${dotColor}`)}>
																		<IconButton
																			aria-label={`inventory.status_${dotColor}`}
																			color={dotColor}
																			onClick={() => priceDetails(curItem)}
																			size="small">
																			<FiberManualRecordIcon />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title={t('inventory.Edit')}>
																		<IconButton
																			aria-label="edit"
																			color="default"
																			onClick={() => {
																				navigate('/inventory/edit', {
																					state: {
																						item: itemValue,
																						category: category,
																						val: curItem
																					}
																				});
																			}}>
																			<EditIcon />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title={t('inventory.Delete')}>
																		<IconButton
																			aria-label="delete"
																			color="error"
																			onClick={() => handleClickOpen(curItem)}>
																			<DeleteForeverRoundedIcon />
																		</IconButton>
																	</Tooltip>
																</TableCell>
															);
														case 'createdAt':
															return (
																<TableCell key={'tableCell2' + column.field} align={'center'}>
																	<div>{value && value.substring(0, 10)}</div>
																</TableCell>
															);
														case 'Area/Price':
															return (
																<TableCell key={'tableCell2' + column.field} align={'center'}>
																	<SoftButton
																		aria-label="show"
																		onClick={() => priceDetails(curItem)}
																		endIcon={<VisibilityIcon />}>
																		view
																	</SoftButton>
																</TableCell>
															);
														default:
															return (
																<TableCell key={'tableCell2' + column.field} align={'center'}>
																	<div>{value}</div>
																</TableCell>
															);
													}
												})}
											</TableRow>
										))
									) : (
										<TableCell align={'center'}>
											<div>{t('audit.No result')}</div>
										</TableCell>
									)
								) : (
									<>
										<SkeletonView />
										<SkeletonView />
										<SkeletonView />
									</>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[25, 50, 75]}
						component="div"
						count={filteredItems.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						sx={{
							'& .css-1ui3wbn-MuiInputBase-root-MuiTablePagination-select': {
								width: 'fit-content !important'
							}
						}}
					/>
				</Paper>
			</SoftBox>
			<Dialog
				dialog={open}
				title={t('inventory.This is going to delete the item.')}
				content={DelContent()}
				cancel={t('Discard')}
				submit={t('Yes')}
				handleClose={handleClose}
				handleSubmit={deleteItem}
				showBtn={true}
				disable={false}
				fullWidth={true}
				showSub={true}
			/>
			<Dialog
				dialog={exportDialog}
				title={t('area.Please select the area')}
				content={ExportContent()}
				cancel={t('Back')}
				submit={t('Yes')}
				handleClose={handleDialog}
				handleSubmit={() => {
					exportInventory(exportData, exportArea);
				}}
				showBtn={true}
				disable={exportArea ? false : true}
				fullWidth={true}
				showSub={true}
			/>
			<Dialog
				dialog={viewPrice}
				title={
					<>
						<div>{priceContent.Product}</div>
						<div>{priceContent.ZH}</div>
					</>
				}
				content={priceDialog()}
				cancel={t('Back')}
				handleClose={handleViewPrice}
				showBtn={true}
				disable={false}
				fullWidth={true}
				showSub={false}
			/>
		</>
	);
}

export default Inventory;
