import { LambdaClient } from '@aws-sdk/client-lambda';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';

// An Amazon Cognito Identity Pool ID. Replace with the identity pool ID created
// for this example.
const IDENTITY_POOL_ID = 'ap-southeast-1:2d172d52-c13a-427d-a37f-5920e0fa13df';
// An Amazon Cognito Region. Replace with the region that is hosting the resources for this example.
const REGION = 'ap-southeast-1';

// Create an Amazon DynamoDB client service object that initializes the Amazon Cognito credentials provider.
const lambdaClient = new LambdaClient({
	region: REGION,
	credentials: fromCognitoIdentityPool({
		client: new CognitoIdentityClient({ region: REGION }),
		identityPoolId: IDENTITY_POOL_ID
	})
});

export default lambdaClient;
// snippet-end:[cross-service.lambda-from-browser.JavaScript.lambdaClient]
