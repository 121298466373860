import colors from '../../base/colors';
import boxShadows from '../../base/boxShadows';
import borders from '../../base/borders';

const { white } = colors;
const { xxl } = boxShadows;
const { borderRadius } = borders;

const tableContainer = {
	styleOverrides: {
		root: {
			backgroundColor: white.main,
			boxShadow: xxl,
			borderRadius: borderRadius.xl
		}
	}
};

export default tableContainer;
