import React, { useEffect, useState } from 'react'; //useCallback
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { listShippings, getOrder } from '../../scripts';
import { createOverseasOrder, updateOverseasOrder } from '../../scripts/mutations';
import SoftButton from '../../components/SoftButton/index';
import SoftBox from '../../components/SoftBox';
import SoftTypography from '../../components/SoftTypography';
import SoftInput from '../../components/SoftInput';
import SoftBadge from '../../components/SoftBadge';
import { getCompany } from '../../scripts';
import { exportToExcel } from '../../components/ExportExcel';
import { sendMail } from '../../scripts/lambda';
// import { useAuthenticator } from '@aws-amplify/ui-react'; //, Flex, Heading,Text,StepperField
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ErrorIcon from '@mui/icons-material/Error';

const initialState = { code: [], name: '', qty: 0, amount: 0, status: [], orderType: 'US' };
const steps = ['Create', 'Review', 'Done'];
Modal.setAppElement('#root');

const UsOrder = () => {
	const area = useSelector((state) => state.user.userArea);
	const getname = useSelector((state) => state.user.userName);
	// const email = useSelector((state) => state.user.userEmail);
	const role = useSelector((state) => state.user.userRole);
	// const userType = useSelector((state) => state.user.userType);
	const [inv, updateItem] = useState([]);
	const [value, updateValue] = useState([]);
	const [formState, updateFormState] = useState(initialState);
	const [tabValue, setTabValue] = useState(0);
	const [activeStep, setActiveStep] = useState(0);
	const [curQty, setCurQty] = useState(0);
	const [curArea, setCurArea] = useState(area[0]);
	const [curCount, setCurCount] = useState(0);
	const [catCount, setCatCount] = useState({});
	const [catMoq, setCatMoq] = useState({});
	const [catQty, setCatQty] = useState({});
	const [catAmount, setCatAmount] = useState({});
	const [currentCat, setCurrentCat] = useState('Frozen');
	const [currency, setCurrency] = useState('');
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [company, setCompany] = useState([]);
	const [companyEmail, setCompanyEmail] = useState([]);

	useEffect(() => {
		if (location.state) {
			let stateArea = location.state[0].orderarea;
			setCurArea(stateArea);
			getItem(stateArea);
			// if(stateArea==='CA_Van') {setCurrency('TWD')}
			// else {setCurrency(stateArea.split('_')[0]+'D')}
			setCurrency('USD');
		} else if (area.length > 0) {
			setCurArea(area[0]);
			getItem(area[0]);
			if (area[0].startsWith('CA')) {
				setCurrency('CAD');
			} else {
				setCurrency('USD');
			}
		}
		getCompany().then((i) => {
			let company = {};
			let email = {};
			Object.keys(i).map((key) =>
				!key.includes('/') ? (company[key] = i[key]) : key.includes('Email') ? (email[key.split('/')[0]] = i[key]) : ''
			);
			setCompany(company);
			setCompanyEmail(email);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [area]);

	const handleChange = (event, newArea) => {
		if (newArea !== null) {
			setCurArea(newArea);
			// setCurrency('USD');
			if (newArea.startsWith('CA')) {
				setCurrency('CAD');
			} else {
				setCurrency('USD');
			}
			getItem(newArea);
			if (value.length > 0) {
				setTabValue(0);
				setCurrentCat('Frozen');
			}
		}
	};

	const handleSetTabValue = (event, newValue) => {
		let curCategory = '';
		[...new Set(value.map((item) => item.Category))].map((item, index) =>
			index === newValue ? (curCategory = item) : ''
		);
		setTabValue(newValue);
		setCurrentCat(curCategory);
	};

	const handleNext = () => {
		if (activeStep === 0) {
			submit();
		}
		if (activeStep === 1) {
			createOrder();
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

	const handleReset = () => navigate('/dashboard');

	async function getItem(areaVal) {
		try {
			let val = [];
			let oval = location.state;
			let userInv = [];
			let index = -1;
			const original = await listShippings([{ ShipFrom: { eq: 'US' } }, { Area: { contains: areaVal } }]);
			original.sort(function (a, b) {
				let category = a.Category.localeCompare(b.Category);
				return category !== 0 ? category : a.Code.localeCompare(b.Code);
			}); //sort by category then code
			for (let i in original) {
				val.push({
					id: original[i].id,
					Category: original[i].Category,
					Qty: 0,
					Moq: original[i].Moq,
					Code: original[i].Code,
					Unit: original[i].Unit,
					Size: original[i].Size,
					Product: original[i].Product,
					ZH: original[i].ZH,
					Price: JSON.parse(original[i].Price)[areaVal],
					index: (index += 1)
				});
				userInv.push(original[i]);
			}
			let newQt = 0;
			let newCount = 0;
			for (let o in oval) {
				for (let v in val) {
					if (val[v].Code === oval[o].Code) {
						val[v].Qty = parseInt(oval[o].OrderingQty);
						newQt += parseInt(val[v].Qty);
						newCount += 1;
						val[v].Nw = oval[o].Nw * parseInt(val[v].Qty);
						val[v].Price = val[v].Qty * val[v].Price; // for edit order
					}
				}
			}
			updateItem(userInv);
			updateValue(val);
			setCurQty(newQt);
			setCurCount(newCount);
			let cat = {};
			val.map((item) => item.Category).map((item) => (cat[item] = 0));
			val.map((item) => (item.Qty > 0 ? (cat[item.Category] += 1) : ''));
			setCatCount(cat);

			let catQuantity = {};
			val.map((item) => item.Category).map((item) => (catQuantity[item] = 0));
			val.map((item) => (item.Qty > 0 ? (catQuantity[item.Category] += parseInt(item.Qty)) : ''));
			setCatQty(catQuantity);

			let categoryMoq = [];
			val.forEach((item) => (categoryMoq[item.Category] = item.Moq));
			setCatMoq(categoryMoq);

			let catFa = {};
			val.map((item) => item.Category).map((item) => (catFa[item] = 0));
			val.map((item) => (item.Qty > 0 ? (catFa[item.Category] += parseInt(item.Price)) : ''));
			setCatAmount(catFa);
		} catch (error) {
			console.log('Error retrieving inv', error);
		}
	}

	function onChange(e, index) {
		let val = parseInt(e.target.value);
		let newVal = [...value];
		newVal[index].Price = val * JSON.parse(inv[index].Price)[curArea] || JSON.parse(inv[index].Price)[curArea];
		newVal[index].Qty = val || 0;
	}

	function onBlur(e, index, moq) {
		let newCount = 0;
		let catQuantity = {};
		let newQty = 0;
		let val = parseInt(e.target.value) || 0;
		let newVal = [...value];
		let newPrice = JSON.parse(inv[index].Price)[curArea];
		let cat = {};
		let catFa = {};
		newVal
			.map((item) => item.Category)
			.map((item) => {
				return (cat[item] = 0);
			});
		newVal
			.map((item) => item.Category)
			.map((item) => {
				return (catQuantity[item] = 0);
			});
		newVal
			.map((item) => item.Category)
			.map((item) => {
				return (catFa[item] = 0);
			});
		newVal[index].Price = val * JSON.parse(inv[index].Price)[curArea] || newPrice;
		newVal[index].Qty = val;

		value.map((item) => {
			if (item.Qty > 0) {
				newQty += parseInt(item.Qty);
				newCount += 1;
				catQuantity[item.Category] += parseInt(item.Qty);
				cat[item.Category] += 1;
				catFa[item.Category] += item.Price;
			}
			return newQty;
		});
		console.log(moq);
		setCatCount(cat);
		setCatQty(catQuantity);
		setCurQty(newQty);
		setCatAmount(catFa);
		setCurCount(newCount);
		updateValue(newVal);
	}

	async function createOrder() {
		let created;
		if (location.state) {
			if (location.state.length > 0) {
				updateOrder(formState.code, formState.name, formState.qty, formState.amount, 'US');
			}
		} else {
			try {
				const variables = {
					Code: formState.code,
					Name: formState.name,
					Qty: formState.qty,
					Amount: formState.amount,
					Status: formState.status,
					OrderType: 'US'
				};
				created = await createOverseasOrder(variables);
				created = created.data.createOverseasOrder;
			} catch (error) {
				console.log('error', error);
			}
			let data = [];
			let shipFrom = '';
			for (let i in inv) {
				let code = inv[i].Code;
				shipFrom = inv[i].ShipFrom;
				let iqty = value[i].Qty;
				let iprice = JSON.parse(inv[i].Price)[curArea];
				if (code === value[i].Code) {
					if (value[i].Qty > 0) {
						let cSize = inv[i].CartonSize ? inv[i].CartonSize.split('*') : 0;
						data.push({
							Category: inv[i].Category,
							ProductName: inv[i].Product,
							Code: inv[i].Code,
							UnitPrice: iprice,
							OrderingQty: iqty,
							Amount: Math.round(iprice * iqty * 100) / 100,
							ProductZH: inv[i].ZH,
							PackagingSize: inv[i].Size,
							ExpiryDate: inv[i].Expiry !== null ? inv[i].Expiry + 'months' : 'N/A',
							Unit: inv[i].Unit,
							NW: inv[i].NW,
							TotalNetWeight: inv[i].NW * parseInt(iqty) || '',
							GW: inv[i].GW,
							TotalGrossWeight: inv[i].GW * parseInt(iqty) || '',
							CBM: cSize === 0 ? '' : Math.round(((cSize[0] * cSize[1] * cSize[2] * iqty) / 1000000) * 100) / 100,
							CartonSize: inv[i].CartonSize,
							Note: inv[i].Note,
							OrderArea: curArea
						});
					}
				}
			}
			const fileType = 'application/vnd.openxmIformats-officedocument .spreadsheetml. sheet; charset-UTF-8';
			const fileExtension = '.xlsx';
			const borderStyle = { border: { bottom: { style: 'thick', color: '000000' } } };
			let sum = 0;
			value.map((item) => (item.Qty > 0 ? (sum += item.Price) : 0));
			exportToExcel(
				data,
				new Date().toISOString(),
				curQty,
				sum,
				company[curArea],
				fileType,
				fileExtension,
				borderStyle,
				'fileName',
				'buffer'
			).then(async (res) => {
				sendMail(
					getname,
					companyEmail[curArea],
					company[curArea],
					curQty,
					curCount,
					sum,
					curArea,
					value,
					shipFrom,
					res,
					created.id.split('-')[0],
					'create'
				);
			});
		}
		updateFormState(initialState);
	}

	function submit() {
		let val = [];
		let userArea = getname + '@' + curArea;
		let qty = 0;
		let amount = 0;
		for (let q in value) {
			if (value[q].Qty > 0) {
				val.push(value[q].Code + '@' + value[q].Qty + '@' + parseFloat(value[q].Price.toFixed(2)));
				qty += parseInt(value[q].Qty);
				amount += value[q].Price; //parseFloat(qtyVal[q].Price.toFixed(2));
			}
		}
		updateFormState({
			code: val,
			name: userArea,
			qty: qty,
			amount: parseFloat(amount),
			status: ['pending@' + new Date().toISOString()]
		});
	}

	async function updateOrder(val, name, qty, amount) {
		try {
			const original = await getOrder(location.state[0].orderid);
			const variables = {
				id: original.id,
				_version: original._version,
				Code: val,
				Name: name,
				Qty: qty,
				Amount: amount,
				Status: [...original.Status, 'pending@' + new Date().toISOString()]
			};
			await updateOverseasOrder(variables);
		} catch (error) {
			console.log('error', error);
		}
		let shipFrom = '';
		let data = [];
		for (let i in inv) {
			let code = inv[i].Code;
			shipFrom = inv[i].ShipFrom;
			let iqty = value[i].Qty;
			let iprice = JSON.parse(inv[i].Price)[curArea];
			if (code === value[i].Code) {
				if (value[i].Qty > 0) {
					let cSize = inv[i].CartonSize ? inv[i].CartonSize.split('*') : 0;
					data.push({
						Category: inv[i].Category,
						ProductName: inv[i].Product,
						Code: inv[i].Code,
						UnitPrice: iprice,
						OrderingQty: iqty,
						Amount: Math.round(iprice * iqty * 100) / 100,
						ProductZH: inv[i].ZH,
						PackagingSize: inv[i].Size,
						ExpiryDate: inv[i].Expiry !== null ? inv[i].Expiry + 'months' : 'N/A',
						Unit: inv[i].Unit,
						NW: inv[i].NW,
						TotalNetWeight: inv[i].NW * parseInt(iqty) || '',
						GW: inv[i].GW,
						TotalGrossWeight: inv[i].GW * parseInt(iqty) || '',
						CBM: cSize === 0 ? '' : Math.round(((cSize[0] * cSize[1] * cSize[2] * iqty) / 1000000) * 100) / 100,
						CartonSize: inv[i].CartonSize,
						Note: inv[i].Note,
						OrderArea: curArea
					});
				}
			}
		}
		const fileType = 'application/vnd.openxmIformats-officedocument .spreadsheetml. sheet; charset-UTF-8';
		const fileExtension = '.xlsx';
		const borderStyle = { border: { bottom: { style: 'thick', color: '000000' } } };
		let sum = 0;
		value.map((item) => (item.Qty > 0 ? (sum += item.Price) : 0));
		exportToExcel(
			data,
			new Date().toISOString(),
			curQty,
			sum,
			company[curArea],
			fileType,
			fileExtension,
			borderStyle,
			'fileName',
			'buffer'
		).then(async (res) => {
			sendMail(
				getname,
				companyEmail[curArea],
				company[curArea],
				curQty,
				curCount,
				sum,
				curArea,
				value,
				shipFrom,
				res,
				location.state[0].orderid.split('-')[0],
				'update'
			);
		});
	}

	function CustomTabPanel(props) {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}>
				{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
			</div>
		);
	}

	CustomTabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	function OrderCard(ival) {
		let val = [ival.val];
		return val.map((item, index) => (
			<Card sx={{ border: '#eaeaea 0.1px solid' }} key={item.Product + index}>
				<CardContent sx={{ height: 200 }}>
					<Tooltip title={t('order.Code')}>
						<SoftTypography variant="caption" fontWeight="regular" color="text">
							<SoftBadge variant="gradient" badgeContent={item.Code} color="light" size="xs" container />
							&nbsp;
						</SoftTypography>
					</Tooltip>
					{item.Unit ? (
						<Tooltip title={t('order.Unit')}>
							<SoftTypography variant="caption" fontWeight="regular" color="text">
								<SoftBadge variant="gradient" badgeContent={item.Unit} color="dark" size="xs" container />
							</SoftTypography>
						</Tooltip>
					) : null}
					<SoftTypography pt={2} variant="h6" fontWeight="medium">
						{item.Product}
						<br />
						{item.ZH}
					</SoftTypography>
					{item.Size ? (
						<SoftTypography variant="h6" fontWeight="regular" color="text">
							{t('order.Size')}:&nbsp;{item.Size}
						</SoftTypography>
					) : null}
					{item.Price !== 0 ? (
						<SoftTypography pt={2} variant="h6" fontWeight="regular" color="text">
							${item.Price && item.Price.toLocaleString(undefined, { minimumFractionDigits: 2 })}
						</SoftTypography>
					) : (
						<SoftTypography pt={2} variant="h6" fontWeight="regular" color="text">
							$ TBC
						</SoftTypography>
					)}
				</CardContent>
				<CardActions>
					<FormControl fullWidth variant="outlined" sx={{ paddingLeft: 2, paddingRight: 2 }}>
						<SoftInput
							variant="standard"
							error={item.Error}
							type="number"
							defaultValue={item.Qty}
							id={item.id}
							onChange={(e) => onChange(e, ival.catindex)}
							onFocus={(e) => e.target.select()}
							onBlur={(e) => onBlur(e, ival.catindex, item.Moq)}
						/>
						<FormHelperText
							sx={{ marginLeft: 0 }}
							error={item.Error}
							id="outlined-weight-helper-msg"
							hidden={!item.Error}>
							{' <= ' + t('Quantity') + ' <= '}
						</FormHelperText>
						{/* <FormHelperText sx={{marginLeft:0}} id="outlined-weight-helper-text">{t('order.Moq')} {item.Moq}</FormHelperText> */}
					</FormControl>
				</CardActions>
			</Card>
		));
	}

	function ReviewCard() {
		let added = [];
		value.map((item) => {
			if (item.Qty > 0) {
				added.push(item);
			}
			return '';
		});
		return [...new Set(added.map((item) => item.Category))].map((category, catIndex) => (
			<div key={category + catIndex}>
				<SoftBox pt={2} px={2}>
					<Grid container spacing={3}>
						<Grid item xs>
							<SoftTypography variant="h6" fontWeight="bold">
								{category}
							</SoftTypography>
						</Grid>
						<Grid item xs>
							<SoftTypography variant="h6" fontWeight="bold">
								{catQty[category]}
							</SoftTypography>
						</Grid>
						<Grid item xs>
							{catAmount[category] !== 0 ? (
								<SoftTypography variant="button" fontWeight="bold" color="text">
									${catAmount[category].toLocaleString(undefined, { minimumFractionDigits: 2 })}
								</SoftTypography>
							) : (
								<SoftTypography variant="button" fontWeight="bold" color="text">
									TBC
								</SoftTypography>
							)}
						</Grid>
					</Grid>
				</SoftBox>
				<Divider />
				{added
					.filter((item) => item.Category === category)
					.map((item, index) => (
						<SoftBox
							component="li"
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							key={item.Product + index}
							mb={false ? 0 : 1}
							pt={2}
							px={2}
							pb={2}>
							<Grid container spacing={3}>
								<Grid item xs>
									<SoftTypography display="block" variant="h6" fontWeight="regular">
										{item.Product}
										<br />
										{item.ZH}
									</SoftTypography>
								</Grid>
								<Grid item xs>
									<SoftTypography variant="h6" fontWeight="regular">
										{item.Qty}
									</SoftTypography>
								</Grid>
								<Grid item xs>
									{item.Price !== 0 && item.Price ? (
										<SoftTypography variant="button" fontWeight="regular" color="text">
											${item.Price.toLocaleString(undefined, { minimumFractionDigits: 2 })}
										</SoftTypography>
									) : (
										<SoftTypography variant="button" fontWeight="regular" color="text">
											TBC
										</SoftTypography>
									)}
								</Grid>
							</Grid>
						</SoftBox>
					))}
			</div>
		));
	}

	function sumCard() {
		let sum = 0;
		value.map((item) => (item.Qty > 0 ? (sum += item.Price) : 0));
		sum =
			sum === 0
				? '$' + sum.toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' - TBC'
				: '$' + sum.toLocaleString(undefined, { minimumFractionDigits: 2 });
		return sum;
	}

	return (
		<SoftBox py={3}>
			<SoftBox mb={3}>
				<Card>
					<SoftBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<SoftTypography variant="h5" fontWeight="bold">
							{t('usorder.US order')}
						</SoftTypography>
						{location.state ? null : role !== 'Owner' && area.length > 1 ? (
							<Autocomplete
								disablePortal
								id="select-area"
								value={curArea}
								options={area.map((a) => a)}
								sx={{ width: 300 }}
								onChange={handleChange}
								renderInput={(params) => <TextField {...params} placeholder={t('area.Please select the area')} />}
							/>
						) : null}
					</SoftBox>
					<SoftBox
						sx={{
							'& .MuiTableRow-root:not(:last-child)': {
								'& td': {
									borderBottom: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`
								}
							}
						}}>
						<Stepper activeStep={activeStep}>
							{steps.map((label) => {
								const stepProps = {};
								const labelProps = {};
								return (
									<Step key={label} {...stepProps}>
										<StepLabel {...labelProps}>{t('order.' + label)}</StepLabel>
									</Step>
								);
							})}
						</Stepper>
						<SoftTypography px={2} variant="h6" fontWeight="bold">
							{' '}
							{t('order.Currency')}: {currency}
						</SoftTypography>
						{(() => {
							if (activeStep === steps.length - 1) {
								return (
									<div>
										<SoftBox p={2} mx={3} display="flex" justifyContent="center">
											<SoftBox
												display="grid"
												justifyContent="center"
												alignItems="center"
												color="green"
												width="4rem"
												height="4rem">
												<Icon fontSize="large">check_circle</Icon>
											</SoftBox>
										</SoftBox>
										<SoftBox pb={6} px={2} pt={6} textAlign="center" lineHeight={1.25}>
											<SoftTypography variant="h6" fontWeight="bold">
												{t('order.Done')}
											</SoftTypography>
											<br />
											<SoftTypography variant="h6" color="text" fontWeight="bold">
												{t('order.Order created')}
											</SoftTypography>
											<SoftTypography variant="caption" color="text" fontWeight="regular">
												{t('Order Created')}
											</SoftTypography>
											<Divider />
											<SoftButton onClick={handleReset}>{t('order.View order')}</SoftButton>
										</SoftBox>
									</div>
								);
							} else if (activeStep === 0) {
								let disable = [];
								[...new Set(value.map((item) => item.Category))].map((item) => {
									let a = '';
									if (curQty > 0) {
										a = catQty[item] > 0 && catQty[item] < catMoq[item];
									} else {
										a = true;
									}
									disable.push(a);
									return '';
								});
								return (
									<div>
										<SoftBox pt={2} px={2}>
											<Grid container spacing={3}>
												<Grid item xs={12} md={10}>
													<SoftTypography variant="h6" color="text" fontWeight="bold">
														{t('Item')}: {curCount} , {t('order.Total quantity')}: {curQty.toLocaleString()}
													</SoftTypography>
												</Grid>
												<Grid item xs={12} md={2} display="flex" justifyContent="flex-end">
													<SoftButton
														variant="contained"
														color="secondary"
														onClick={handleNext}
														disabled={disable.includes(true)}>
														{t('order.Next')}
													</SoftButton>
												</Grid>
											</Grid>
											{disable.includes(true) ? (
												<SoftBox display="flex" justifyContent="flex-end">
													<SoftTypography fontSize="small" variant="h6" color="error">
														<ErrorIcon /> {t('usorder.Need to meet Moq')}
													</SoftTypography>
												</SoftBox>
											) : null}
										</SoftBox>
										<SoftBox>
											<Tabs
												value={tabValue}
												onChange={handleSetTabValue}
												variant="scrollable"
												scrollButtons
												allowScrollButtonsMobile>
												{[...new Set(value.map((item) => item.Category))].map((item, index) => (
													<Tab
														label={
															<span>
																{item}&nbsp;
																{catCount[item] > 0 ? (
																	<SoftBadge
																		variant="gradient"
																		circular
																		badgeContent={catCount[item]}
																		color="light"
																		size="xs"
																		container
																	/>
																) : null}
															</span>
														}
														key={item + 'fbtn'}
														{...a11yProps(index)}
													/>
												))}
											</Tabs>
										</SoftBox>
										{catQty[currentCat] >= catMoq[currentCat] ? (
											<SoftTypography variant="h6" ml={3} mt={2} fontWeight="bold">
												{t('usorder.Moq')}: {catMoq[currentCat]}, {t('usorder.Meet Moq')}
											</SoftTypography>
										) : (
											<SoftTypography variant="h6" ml={3} mt={2} fontWeight="bold">
												{t('usorder.Moq')}: {catMoq[currentCat]}, {t('usorder.Need to add')}:
												{catMoq[currentCat] - catQty[currentCat]}
											</SoftTypography>
										)}
										<Grid container>
											{value.map((cat) =>
												[cat]
													.filter(
														(item) => item.Category === [...new Set(value.map((item) => item.Category))][tabValue]
													)
													.map((item, index) => (
														<Grid item xs={12} md={4} key={cat + 'cat' + cat.index}>
															<CustomTabPanel value={tabValue} index={tabValue} key={item + 'panel' + index}>
																<OrderCard val={cat} catindex={cat.index} />
															</CustomTabPanel>
														</Grid>
													))
											)}
										</Grid>
									</div>
								);
							} else {
								return (
									<div>
										<Box
											component="li"
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											mb={false ? 0 : 1}
											pt={2}
											px={2}>
											<SoftButton onClick={handleBack} sx={{ mr: 1 }}>
												{t('order.Back')}
											</SoftButton>
											<Box sx={{ flex: '1 1 auto' }} />
											<SoftButton variant="contained" color="success" onClick={handleNext}>
												{location.state ? t('order.Update') : t('order.Create')}
											</SoftButton>
										</Box>
										<SoftBox
											component="li"
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											mb={false ? 0 : 1}
											pt={2}
											px={2}>
											<Grid container spacing={3}>
												<Grid item xs>
													<SoftTypography display="block" variant="h6" fontWeight="bold">
														{t('Item')} ({curCount})
													</SoftTypography>
												</Grid>
												<Grid item xs>
													<SoftTypography variant="h6" fontWeight="bold">
														{t('Quantity')} ({curQty})
													</SoftTypography>
												</Grid>
												<Grid item xs>
													<SoftTypography variant="h6" fontWeight="bold">
														{t('Price')} ({sumCard()})
													</SoftTypography>
												</Grid>
											</Grid>
										</SoftBox>
										<ReviewCard />
									</div>
								);
							}
						})()}
					</SoftBox>
				</Card>
			</SoftBox>
		</SoftBox>
	);
};
export default UsOrder;
