import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import SoftButton from '../../components/SoftButton';
import '../../App.css';
import { getUserSettings } from '../../Redux/userSlice';
import { listUsers } from '../../scripts/lambda';
import { listPermission } from '../../scripts';
import { updatePermission } from '../../scripts/mutations';
import { DataGrid, useGridApiContext, GridCellModes, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';

export default function Permission({ setType }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const settings = useSelector((state) => state.user.userSettings);
	const [col, setCol] = useState([]);
	const [cellModesModel, setCellModesModel] = useState({});
	const [val, setVal] = useState(JSON.parse(JSON.stringify(settings)));
	const [ogRow, setOgRow] = useState([]);
	const [updateRoute, setUpdateRoute] = useState([]);
	const [loading, setLoading] = useState(false);
	const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });

	useEffect(() => {
		renderCol();
	}, []);

	useEffect(() => {
		getUsers();
	}, [col]);

	const firstCol = [
		{
			field: 'account',
			headerName: 'User',
			type: 'string',
			width: 150,
			editable: false,
			cellClassName: 'sticky-cell'
		}
	];

	const handlePaginationModelChange = (newPaginationModel) => setPaginationModel(newPaginationModel);

	const handleCellClick = useCallback((params, event) => {
		if (!params.isEditable) {
			return;
		}
		if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
			return;
		}
		setCellModesModel((prevModel) => {
			return {
				// Revert the mode of the other cells from other rows
				...Object.keys(prevModel).reduce(
					(acc, id) => ({
						...acc,
						[id]: Object.keys(prevModel[id]).reduce(
							(acc2, field) => ({
								...acc2,
								[field]: { mode: GridCellModes.View }
							}),
							{}
						)
					}),
					{}
				),
				[params.id]: {
					// Revert the mode of other cells in the same row
					...Object.keys(prevModel[params.id] || {}).reduce(
						(acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
						{}
					),
					[params.field]: { mode: GridCellModes.Edit }
				}
			};
		});
	}, []);

	const handleCellModesModelChange = useCallback((newModel) => {
		setCellModesModel(newModel);
	}, []);

	const QuickSearchToolbar = () => (
		<Box
			sx={{
				p: 0.8,
				pb: 0
			}}>
			<GridToolbarQuickFilter />
		</Box>
	);

	const renderPermission = (params) => <Checkbox value={params.value} checked={params.value} />;

	function PermissionEditInputCell(props) {
		const { id, value, field, hasFocus } = props;
		const apiRef = useGridApiContext();
		const ref = useRef(null);
		const handleChange = async (event, newValue) => {
			setUpdateRoute((prev) => {
				if (!prev.includes(field)) {
					prev.push(field);
				}
				return prev;
			});
			let res = await listUsers();
			const curUser = res.filter((f) => f.sub === id)[0];
			const curZoneInfo = curUser.zoneinfo.split(',');
			setVal((prev) => {
				let curList = prev.filter((v) => v.route === field)[0].permission;
				let index = prev.findIndex((p) => p.route === field);
				curZoneInfo.map((zone) => {
					if (newValue && !curList[zone].includes(curUser.name)) {
						curList[zone].push(curUser.name);
					}
					if (!newValue && curList[zone].includes(curUser.name)) {
						curList[zone].splice(curList[zone].indexOf(curUser.name), 1);
					}
				});
				prev[index].permission = curList;
				return prev;
			});
			apiRef.current.setEditCellValue({ id, field, value: newValue });
			setOgRow((prev) => {
				let i = prev.findIndex((f) => f.id === props.id);
				prev[i][field] = newValue;
				return prev;
			});
			apiRef.current.stopCellEditMode({ id: props.id, field: field });
		};

		useEnhancedEffect(() => {
			if (hasFocus && ref.current) {
				const input = ref.current.querySelector(`input[value="${value}"]`);
				input?.focus();
			}
		}, [hasFocus, value]);

		return (
			<Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
				<Checkbox
					ref={ref}
					name="permissions"
					precision={1}
					value={value}
					onChange={handleChange}
					checked={props.value}
				/>
			</Box>
		);
	}

	const renderPermissionEditInputCell = (params) => <PermissionEditInputCell {...params} />;

	const renderCol = () => {
		let column = [...firstCol];
		settings.map((r) => {
			if (r.route.split('/').length < 3) {
				column.push({
					field: r.route,
					headerName: r.section,
					type: 'boolean',
					width: 150,
					editable: true,
					renderCell: renderPermission,
					renderEditCell: renderPermissionEditInputCell
				});
			}
			return column;
		});
		setCol(column);
	};

	const getUsers = async () => {
		let res = await listUsers();
		getRow(res);
	};

	const getRow = async (list) => {
		const routes = settings.filter((s) => s.route.split('/').length < 3);
		let rows = [];
		let routeKey = {};
		list.map((i) => {
			routes.map((route) => {
				let view = true;
				i.zoneinfo.split(',').map((zone) => {
					if (!route.permission[zone].includes(i.name)) {
						view = false;
					}
				});
				routeKey[route.route] = view;
			});
			rows.push({
				id: i.sub,
				account: i.name,
				...routeKey
			});
			return rows;
		});
		setOgRow(rows);
	};

	const submit = () => {
		setLoading(true);
		let update = [];
		updateRoute.map((u) => {
			let route = val.filter((v) => v.route.startsWith(u));
			if (route.length > 1) {
				// for routes with subpage
				let newPermission = route.filter((f) => f.route.split('/').length === 2)[0].permission;
				route.map((r) => {
					if (r.route.split('/').length > 2) {
						r.permission = newPermission;
					}
					return '';
				});
			}
			route.map((r) => update.push(r));
			return update;
		});

		update.map(async (item) => {
			const variables = {
				id: item.id,
				_version: item._version,
				permission: JSON.stringify(item.permission)
			};
			return await updatePermission(variables);
		});

		setTimeout(async () => {
			setLoading(false);
			let response = await listPermission();
			dispatch(getUserSettings(response));
			setType();
		}, 3000);
	};

	return (
		<>
			<Box justifyContent={'space-between'} display={'flex'}>
				<SoftButton onClick={() => setType()}>{t('Back')}</SoftButton>
				<SoftButton onClick={() => submit()} color="success">
					{loading ? <CircularProgress size={12} color="white" /> : t('Save')}
				</SoftButton>
			</Box>
			<Box sx={{ display: 'flex', pt: 2, '& > :not(style)': { width: '100%', height: '65vh' } }}>
				<Paper elevation={3}>
					<Box m={1}>
						<DataGrid
							rows={ogRow}
							columns={col}
							rowHeight={45}
							rowWidth={10}
							disableColumnMenu
							disableColumnFilter
							sx={{
								height: '60vh'
							}}
							paginationModel={paginationModel}
							onPaginationModelChange={handlePaginationModelChange}
							cellModesModel={cellModesModel}
							onCellModesModelChange={handleCellModesModelChange}
							onCellClick={handleCellClick}
							pageSizeOptions={[10, 20]}
							slotProps={{
								pagination: { labelRowsPerPage: t('account.per page') }
							}}
							slots={{ toolbar: QuickSearchToolbar }}
							localeText={{
								toolbarQuickFilterPlaceholder: t('account.Search user')
							}}
						/>
					</Box>
				</Paper>
			</Box>
		</>
	);
}
